import DateTimeDisplay from "./DateTimeDisplay";

const TimerCounter = ({ days, hours, minutes, seconds,type='d-h-m-s' }) => {
	const getCounter = (counterTypes) => {
		switch(counterTypes){
			case 'days':
				return <DateTimeDisplay value={days} type={'Days'} isDanger={false} />
			case 'hours':
				return <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
			case 'm-s':
				return (
					<>
						<DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
						:
						<DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
					</>
				)
			case 'seconds':
				return <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
			default:
				return <></>
		}
	}
	return (
	  <div className="timer__counter">
		{
			getCounter('m-s')
		}
	  </div>
	);
  };
export default TimerCounter;