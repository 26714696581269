import { addManyTasksAction,rmTaskAction,rmAllTasksAction,asyncRmTaskAction, addTaskAction,asyncAddTaskAction, asyncAddManyTasksAction} from "./tasksReducer";
import { 
	addFieldAction, 
	rmFieldAction, 
	rmAllFieldsAction, 
	addFieldManyAction 
} from "./fieldValue";
import{
	setFormAction,
	refreshFormAction,
	addFormBlocksAction,
	updFormBlocksAction,
	rmFormBlocksAction,
	addFormFieldsAction,
	updFormFieldsAction,
	rmFormFieldsAction
} from './FormReducer'
import {
	setWindow,
	resetWindow
} from "./windowReducer"
import { 
	addDataAction,
	addDataItemAction, 
	rmDataItemAction, 
	updateDataItemAction,
	rmAllDataAction,
	asyncAddDataAction, 
	asyncAddDataItemAction, 
	asyncRmDataItemAction, 
	asyncUpdateDataItemAction
} from "./dataReducer";
import{
	setDataNameAction,
} from './dataNameReducer'
import {
	addOrderAction,
	addOrderItemAction, 
	rmOrderItemAction, 
	updateOrderItemAction,
	rmAllOrderAction,
	clearOrdersAction,
	asyncAddOrderAction, 
	asyncAddOrderItemAction, 
	asyncRmOrderItemAction, 
	asyncUpdateOrderItemAction
} from './ordersReducer'
import { addInfoItemAction, rmInfoItemAction, updateInfoItemAction,rmAllInfoItemAction } from "./addedFields";
import { addContentItemAction, addManyContentItemAction,updateContentItemAction, rmContentItemAction,rmAllContentAction} from "./dataItemContentReducer";
import { 
	setUserIsAuthAction, 
	setUserAction, 
	cleanUserAction,
	updateUserAction
} from "./userReducer";
import{
	//files
	addFileAction,
	rmFileAction,
	rmAllAction,
} from './uploadedFiles'
export default {
	setWindow,
	resetWindow,
	//orders
	addOrderAction,
	addOrderItemAction, 
	rmOrderItemAction, 
	updateOrderItemAction,
	rmAllOrderAction,
	clearOrdersAction,
	asyncAddOrderAction, 
	asyncAddOrderItemAction, 
	asyncRmOrderItemAction, 
	asyncUpdateOrderItemAction,
	addInfoItemAction,
	rmInfoItemAction,
	rmAllInfoItemAction,
	updateInfoItemAction,
	//fieldActions
	addFieldManyAction,
	addFieldAction,
	rmFieldAction,
	rmAllFieldsAction,
	//userActions
	setUserIsAuthAction,
	setUserAction,
	cleanUserAction,
	updateUserAction,
	//dataItemContent
	addContentItemAction, addManyContentItemAction, rmContentItemAction,
	rmAllContentAction,
	updateContentItemAction,
	//data
	setDataNameAction,
	addDataAction,
	addDataItemAction, 
	rmDataItemAction, 
	updateDataItemAction,
	rmAllDataAction,
	asyncAddDataAction, 
	asyncAddDataItemAction, 
	asyncRmDataItemAction, 
	asyncUpdateDataItemAction,
	addFileAction,
	rmFileAction,
	rmAllAction,
	/**FormReducer */
	setFormAction,
	refreshFormAction,
	addFormBlocksAction,
	updFormBlocksAction,
	rmFormBlocksAction,
	addFormFieldsAction,
	updFormFieldsAction,
	rmFormFieldsAction
}