import { ADMIN_ROLE, MANAGER_DIGITAL_ROLE, MANAGER_MODELLING_ROLE, MANAGER_ROLE, STATUS_AT_WORK_DIGITIZATION, STATUS_AT_WORK_MODEL, STATUS_COMPLETED, STATUS_COMPLETED_DIGITIZATION, STATUS_COMPLETED_MODEL, STATUS_NEW, STATUS_PREPAID, USER_ROLE, STATUS_AWAITING_PAYMENT } from "../consts"
import { getManyStatusObj } from "../getStatusId"
/**
 * get Statuses that are in Roles
 * 
 */
export const getStatusObjByRole = (role) => {
	switch(role){
		case ADMIN_ROLE:
		case MANAGER_ROLE: 
		case USER_ROLE:
			return getManyStatusObj({
					ids:[0,1,2,3,4,5,6,7,8,9]
				})
		case MANAGER_DIGITAL_ROLE:
			return getManyStatusObj({
				statusNames:[
					STATUS_NEW.statusName,
					STATUS_AWAITING_PAYMENT.statusName,
					STATUS_PREPAID.statusName,
					STATUS_AT_WORK_DIGITIZATION.statusName,
					STATUS_COMPLETED_DIGITIZATION.statusName,
					STATUS_COMPLETED.statusName
				]
			})
		case MANAGER_MODELLING_ROLE:
			return getManyStatusObj({
				statusNames:[
					STATUS_NEW.statusName,
					STATUS_AWAITING_PAYMENT.statusName,
					STATUS_PREPAID.statusName,
					STATUS_COMPLETED_DIGITIZATION.statusName,
					STATUS_AT_WORK_MODEL.statusName,
					STATUS_COMPLETED_MODEL.statusName,
					STATUS_COMPLETED.statusName
				]
			})
		default:
			console.log(role)
			return []
	}
}