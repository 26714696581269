import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../components/UI/loader/Loader";
import OrderItemList from "../components/UI/OrderItem/OrderItemList/OrderItemList";
import User from "../utils/classes/User";
import { useFetching } from "../hooks/useFetching";
import { fetchDeals } from "../http/dealAPI";
import actions from "../store/reducers/actions";
import { getFieldValueById } from "../utils/checkers/getFieldValue";
import { ADMIN_ROLE, DATA_NAMES, USER_ROLE } from "../utils/consts";
import { formatOrders } from "../utils/formatOrders";
import { getById } from "../utils/getById";
const DataItemPage = (props) => {
	let {dataName, itemId, role='' ,dataItem} = props;
	const {id} = useParams();
	const dispatch = useDispatch();
	if(!itemId){
		itemId=id;
	}
	const dataItems = useSelector(state => state.dataReducer[dataName])

	if(!dataItem){
		dataItem = getById(dataItems.list, `${itemId}`);
	}
	let dataItemContent=[]
	if(dataItem.content){
		dataItemContent = Object.entries(dataItem.content);
	}
	const getDataItemInfo = () => {
		switch(dataName){
			case 'orders':
				return(
					<>
						<hr/>
						<div>
							<h4>No: {dataItem.id}</h4>
							<h4>{dataItem.status.name}:{dataItem.status.description[0]} </h4>
						</div>
						<hr/>
						<ul>
							{
								dataItemContent.map(item => {
									if(!item.description){
										return 'неизвестно'
									}
									return(
										<li key={item[0]}>{item[1].name}: {item[1].description[0]}</li>
									)
								})
							}
						</ul>
						<hr/>
						{
							dataItem['projectLink'].description
							?<a href={dataItem['projectLink'].description[0]} className='project_ref'>Ссылка на проект</a>
							:<span className='project_ref project_ref--disabled'>Ссылка на проект</span>
						}
						<hr/>
						<span>{dataItem['cost'].name}: {dataItem['cost'].description[0]} </span>
						<span>{dataItem['advancePayment'].name}: {dataItem['advancePayment'].description[0]} </span>
					</>

				)
			case DATA_NAMES.users.id:
				return(
					<>
						<ul className='userInfo__list'>
							{
									dataItemContent.map(item => (
										<li key={item[0]}>
											{
												dataItem.getFieldText(item[0])
											}
										</li>
									)
									)
								}
						</ul>
						<div className = 'userOrders__container'>
							{
								!dataItem.orders
								? ''
								:(
									dataItem.orders.map(order =>{
										//console.log(order)
										if(!order){
											return ''
										}
										return(
											<OrderItemList key={order.id} order={order}/>
										)
									})
								)
							}
						</div>
					</>
				)
			default:
				return
		}
	}
	const [fetchDataItemContent, isLoading, error] = useFetching(async () => {
		if(dataName === DATA_NAMES.users.id){
			const response = await fetchDeals({userId: dataItem.id});
			const orders = formatOrders(response);
			const userObj = new User({...dataItem.convertedServer, orders:[...orders],currentOrder:(orders[orders.length-1].id)})
			dispatch(actions.updateDataItemAction({
				dataName:'users',
				item:userObj
			}))
		}
	})
	useEffect(()=>{
		fetchDataItemContent()
		return ()=> {
			dispatch(actions.updateDataItemAction({
				dataName:'users',
				item:{...dataItem, orders:[]}
			}))
			//const userObj = new User({...user.convertedServer, orders:[]})
			//dispatch(actions.setUserAction(userObj))
		}
	},[])
	return(
		<div className="dataItem__container">
			{
				isLoading
				?<div style={{position:'fixed', display: 'flex', justifyContent: 'center',height:'100%', width:'100%'}}><Loader/></div> 
				:getDataItemInfo()
			}
		</div>
	)
}
export default DataItemPage;