
import { useEffect, useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import { useFetching } from "../../../../../hooks/useFetching";
import { fetchDealUserInfo } from "../../../../../http/dealAPI";
import actions from "../../../../../store/reducers/actions";
import Order from "../../../../../utils/classes/Order";
import { MANAGER_DIGITAL_ROLE, MANAGER_MODELLING_ROLE, MANAGER_ROLE, USER_ROLE } from "../../../../../utils/consts";
import { USER_INFO } from "../../../../../utils/consts/OBJECT_FIELDS";
import { roleNames } from "../../../../../utils/consts/ROLES";
import { getById } from "../../../../../utils/getById";
import { getSortedList } from "../../../../../utils/getSortedList";
import Loader from "../../../loader/Loader";

const OrderUserInfo = ({orderId, orderType}) => {
	const dispatch = useDispatch();
 	const orders = useSelector(state => state.ordersReducer[orderType].list)
	const order = getById(orders, orderId)
	let userInfo =null
	if(order && order.getValue('users')){
		userInfo = order.getValue('users')
	}
	const [fetchUserInfo, isLoading, error] = useFetching(async () => {
		//console.log(order)
		const response = await fetchDealUserInfo({orderId});
		const sortedItems = sortItems(response)
		const updatedOrder = new Order({
			...order.convertedServer,
			users:sortedItems
		}) 
		console.log(updatedOrder)
		dispatch(actions.updateOrderItemAction(
			{
				orderType: updatedOrder.status, 
				item:updatedOrder
			}
		))
	})
	const getUserInfoItem= (item) =>{
		return(<>
			<ul key={item.id} className='content-list'>
				<li className='content-list__item'>имя: {item.firstname}</li>
				<li className='content-list__item'> фамилия: {item.lastname}</li>
				<li className='content-list__item'> email: {item.email}</li>
				<li className='content-list__item'>телефон: {item.phone}</li>
			</ul>
			<br/>
			</>
		)
	}
	const sortItems = (items) => {
		const roles = [
			MANAGER_DIGITAL_ROLE, 
			MANAGER_MODELLING_ROLE, 
			USER_ROLE, 
			MANAGER_ROLE
		];
		const sortedItems = {}
		for(let i =0; i<roles.length;i++){
			sortedItems[roles[i]] = items.filter(item => item.role === roles[i])
		}
		return {...sortedItems};
	}
	const getManyUserInfo = (role) => {
		
		return(
			<div className='orderItem__list-container userInfo__container'>
				<h4 className='orderItem__list-title'>{roleNames[role]}</h4>
				{
					!userInfo[role]
					?'неизвестно'
					:userInfo[role].map(item => getUserInfoItem(item))
				}
			</div>
		)
	}
	useEffect(()=>{
		fetchUserInfo()
		console.log(userInfo)
	},[])
	return(
		<>
			{
				userInfo 
				?(<>
					{
						getManyUserInfo(USER_ROLE)
					}
					{
						getManyUserInfo(MANAGER_ROLE)
					}
					{
						getManyUserInfo(MANAGER_DIGITAL_ROLE)
					}
					{
						getManyUserInfo(MANAGER_MODELLING_ROLE)
					}
				</>)
				:<div style={{position:'fixed', display: 'flex', justifyContent: 'center',height:'100%', width:'100%'}}><Loader/></div>
			}
		</>
	)
}
export default OrderUserInfo;