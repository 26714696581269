import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate, useParams } from "react-router-dom";
import { useFetching } from "../../../../hooks/useFetching";
import { getPaymentInfo, getPaymentLink } from "../../../../http/dealAPI";
import actions from "../../../../store/reducers/actions";
import Order from "../../../../utils/classes/Order";
import { LOGIN_ROUTE, SUCCESS_CODE } from "../../../../utils/consts";
import { BONUS_POINTS, PAYMENT_LINK, USE_BONUS } from "../../../../utils/consts/OBJECT_FIELDS";
import sleep from "../../../../utils/sleep";
import tempAlert from "../../../../utils/tempAlert";
import FormBtn from "../../formBtn/FormBtn";
import ClickBoxes from "../../FormField/FormFieldSpecs/ClickBoxes/ClickBoxes";
import Loader from "../../loader/Loader";
import OrderLoyality from "../../OrderItem/OrderItemInfo/OrderLoyality/OrderLoyality";
const SBP = 'СБП';
const CARD = 'Картой';
const PaymentForm = () =>{
	const params = useParams();
	const navigate = useNavigate();
	const [isLegal, setIsLegal] = useState(false);
	const [payMethod, setPayMethod] = useState(['']);
	const [order, setOrder] = useState(new Order({}));
	const [link, setLink] = useState('');
	const dispatch = useDispatch();
	const fieldValue = useSelector(state => state.fieldValueReducer.fields)
	const {role} = useSelector( state => state.userReducer.user)
	const sendData = async () => {
		let response = null
		try{
			response = await getPaymentInfo({
				id:params.id
			});
		}catch(e){
			console.log(e)
			tempAlert(response.message, 3000,['--invalid']);
		}
		if(response.success === SUCCESS_CODE){
			//setLink(response.data[PAYMENT_LINK]);
			setOrder(new Order({...response?.data?.order}))
		} else {
			tempAlert(response.message, 3000,['--invalid']);
		}
	}
	const [redirectToPayment, isLoading, error] = useFetching(async () => {
		let response = null
		try{
			//await sleep(3000)
			response = await getPaymentLink({
				useBonus: fieldValue[USE_BONUS],
				payMethod,
				id:params.id
			});
		}catch(e){
			console.log(e)
			tempAlert(response?.message, 3000,['--invalid']);
		}
		if(response.success === SUCCESS_CODE){
			/**
			 * id
			 * 
			 */
			console.log(response)
			window.location.href = response?.data?.link;
		} else {
			tempAlert(response?.message, 3000,['--invalid']);
		}
	})
	const getBtn = () => {
		// if statusNew && dateSending -> canPay
		if(isLoading){
			return <span className='btn project_ref--disabled'>
					<div style={{display: 'flex', justifyContent: 'center',height:'100%', width:'100%'}}>
						<Loader width={20} height={20}/>
					</div> 
				</span>
		}
		if(payMethod[0]){
			return <FormBtn handleClick={redirectToPayment}> Оплатить </FormBtn>
		} else{
			return <span className='btn project_ref--disabled'> Оплатить </span>
		}
	}
	const handleBtnClick = () => {
		if(isLegal){
			setIsLegal(false)
		}else{
			setIsLegal(true)
		}
		//setPayMethod(['']);
	}
	const getOptionsByLegal =()=> {
		if(isLegal){
			return ['Картой']
		} else {
			return ['СБП','Картой']
		}
	}
	useEffect(()=>{
		dispatch(actions.addFieldAction({id:'payMethod', value:payMethod[0]}))
		// if(payMethod[0]){
		// 	if(payMethod[0] === SBP){
		// 		setLink(possibleLinks.sbp)
		// 	} else if(payMethod[0] === CARD){
		// 		setLink(possibleLinks.card)
		// 	}
		// }
	},[payMethod])
	useEffect(()=>{
		setLink('')
	},[isLegal])
	useEffect(()=>{
		sendData();
		dispatch(actions.addFieldAction({id:'payMethod', value:[CARD]}))
		setPayMethod([CARD]);
	},[])
	return(
		<div className='form__payment-container'>

				{
					isLegal
					?<h4 style={{textAlign:'center', fontWeight:'500', fontSize:'1.5rem', backgroundColor:'#fff',borderRadius:'5px', padding:'5rem 0', margin:'0', border:'1px solid #EDEDED'}}>Оплата на Юр.лицо в разработке</h4>
					:(
						<>
							{/* <ClickBoxes 
								requiredProps={{
									id:'payMethod',
									value:payMethod,
									setValue:setPayMethod,
									//defaultValue:payMethod[0]
								}}
								options={getOptionsByLegal()} 
							/> */}
							<OrderLoyality orderItem={order} role={role}/>
							<div className='btn-container'>
								{/* <FormBtn handleClick={pay}> Подтвердить способ</FormBtn> */}
								{
									getBtn()
								}
							</div>
						</>
					)
				}
			<div className="btn-container">
				<FormBtn disabled={true}>
					{
						!isLegal
							?'Хочу оплатить как Юр.лицо'
							:'Хочу оплатить как Физ.лицо'
					}
				</FormBtn>
			</div>
		</div>
	)
}
export default PaymentForm