const defaultState ={
	files: [],
}
export const ADD_FILE ='ADD_FILE';
export const RM_FILE = 'RM_FILE';
export const RM_ALL = 'RM_ALL';
export const fileReducer = (state = defaultState,  action) => {
	switch(action.type) {
		case ADD_FILE :
			return {
				...state, 
				files: [...state.files, ...action.payload]
			}
		case RM_FILE :
			return {
				...state, 
				files: [
					...state.files.filter(file => file.id !== action.payload)
				]
			}
		case RM_ALL:
				return {
					...state,
					files:[]
				} 
		default:
			return state
	}
}
export const addFileAction = (payload) => ({type:ADD_FILE, payload})
export const rmFileAction = (payload) => ({type:RM_FILE, payload})
export const rmAllAction = () => ({type:RM_ALL})