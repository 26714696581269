import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getRoutes, publicRoutes} from "../router/index";
import { LOGIN_ROUTE } from "../utils/consts";
const AppRouter = () => {
	// const navigate = useNavigate();
	const isAuth = 	useSelector( state => state.userReducer.isAuth);
	const {role} = 	useSelector( state => state.userReducer.user);
	const switcherFunction = () => {
		const routes = getRoutes(role);
		return (
			routes.map((route) =>
				<Route
					path = {route.path}
					element = {<route.component/>}
					exact = {route.exact}
					key = {route.path}
				/>)
		)
		/*switch(role){
			case 'ADMIN':
				return (
					adminRoutes.map((route) =>
						<Route
							path = {route.path}
							element = {<route.component/>}
							exact = {route.exact}
							key = {route.path}
						/>)
				)
			case 'USER':
				return (
					userRoutes.map((route) =>
						<Route
							path = {route.path}
							element = {<route.component/>}
							exact = {route.exact}
							key = {route.path}
						/>)
				)
			case 'MANAGER':
				return (
					managerRoutes.map((route) =>
						<Route
							path = {route.path}
							element = {<route.component/>}
							exact = {route.exact}
							key = {route.path}
						/>)
				)
			case 'SECONDARY_MANAGER':
				return (
					managerSecondaryRoutes.map((route) =>
						<Route
							path = {route.path}
							element = {<route.component/>}
							exact = {route.exact}
							key = {route.path}
						/>)
				)	
			default:
				return(
					publicRoutes.map((route) =>
						<Route
							path = {route.path}
							element = {<route.component/>}
							exact = {route.exact}
							key = {route.path}
						/>)
				)
		}*/
	}
	return (
		<Routes>
			{
				isAuth
				? switcherFunction()
				: publicRoutes.map((route) =>
					<Route
						path = {route.path}
						element = {<route.component/>}
						exact = {route.exact}
						key = {route.path}
					/>)
			}
		</Routes>
	);
};
export default AppRouter;
