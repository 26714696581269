import { useEffect } from "react";
import RegisterForm from "../components/UI/Forms/RegisterForm";
import ModalWindow from "../components/UI/modalWindow/ModalWindow";
const RegisterPage = () => {
	return (
		<ModalWindow canClose={false} option='modal__register--default'>
			<RegisterForm/>
		</ModalWindow>
	)
}
export default RegisterPage;