import React, { useEffect, useState } from "react";
import { BrowserRouter, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import AppRouter from './components/AppRouter.jsx';
import actions from "./store/reducers/actions.js";
import Loader from "./components/UI/loader/Loader.jsx";
import AppRouter from "./components/AppRouter.jsx";
import './css/styles.min.css';
import { ADMIN_ROLE, DEALS_DONE_ROUTE, LOGIN_ROUTE, MANAGER_DIGITAL_ROLE, MANAGER_MODELLING_ROLE, MODELS, REACT_APP_API_URL, SERVICE_TYPES, STATUS_ID_FIELD, STATUS_ID_NAME, STATUS_ID_SERVER_NAME, USER_ROLE,MANAGER_ROLE } from "./utils/consts.js";
import User from "./utils/classes/User.js";
import useWindowDimensions from "./hooks/useWindowDimensions.js";
import SubAlert from "./components/UI/Alerts/SubAlert/SubAlert.jsx";
import { checkUser } from "./http/userAPI.js";
import { CAD_2D, CAD_BIM_3D, calculateCost, SCAN_SHOOTING_3D } from "./utils/consts/PRICE.js";
import { isManager } from "./utils/checkers/isManager.js";
import { isLink } from "./utils/isLink.js";
import { FORM_BLOCKS, FORM_FIELDS } from "./utils/consts/FORM_FIELDS.js";
import { DOCUMENTS } from "./utils/consts/OBJECT_FIELDS.js";
import FormTest from "./components/FormComponent.jsx";
import sleep from "./utils/sleep.js";

/**
 * TODO
 * 1. add const dataNames (orders, users)
 * 2. add const routeNames in 'Manager'  and 'User' components
 * 3. delete user/ userAdmin from Admin
 * 4. check 'getStatusId' and fix it !!!
 */

export default function App() {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [alertVisible, setAlertVisible] = useState(false);
	const [msg, setMsg] = useState('');
	const user = useSelector(state => state.userReducer.user)
	const {isAuth} = useSelector(state => state.userReducer)
	const {height, width} = useWindowDimensions();

	useEffect(()=>{
		const delayDebounceFn = setTimeout(() => {
			dispatch(actions.setWindow({width,height}))
		}, 100)
		return () => clearTimeout(delayDebounceFn)
	},[width])
	useEffect(()=>{
		if(localStorage.getItem('token')){
			checkUser()
				.then(data => {
					const userModel = new User({
						...data
					})
					dispatch(actions.setUserAction(userModel))
					dispatch(actions.setUserIsAuthAction(true))
				})
				.catch((e) => {
					dispatch(actions.setUserIsAuthAction(false))
					console.log(e)
				})
				.finally(() => {
					setIsLoading(false)
				})

		}
		else{
			setIsLoading(false)
		}
	},[])
	useEffect(()=>{
		if(msg !== ''){
			setAlertVisible(true);
		}
	},[msg])
	if(isLoading){
		return <div style={{position:'fixed', display: 'flex', justifyContent: 'center',height:'100%', width:'100%'}}><Loader/></div>
	}
	return (
		<BrowserRouter basename="/">
			{
				alertVisible
				?(
					<SubAlert msg={msg} setVisible={setAlertVisible}/>
				)
				:<></>
			}
			<AppRouter/>
		</BrowserRouter>
	);
}

