const ADMIN_ROLE = 'ADMIN';
const USER_ROLE = 'USER';
const MANAGER_ROLE = 'MANAGER';
const MANAGER_SECONDARY_ROLE = 'MANAGER_SECONDARY';
const MANAGER_DIGITAL_ROLE = 'MANAGER_DIGITAL';
const MANAGER_MODELLING_ROLE = 'MANAGER_MODELLING';
/* 
toDo : use Manager_secondary object
const MANAGER_SECONDARY_ROLE = {
	digital:'MANAGER_DIGITAL',
	modelling:'MANAGER_MODELLING'
} */
export const roleNames = {
	[USER_ROLE]:'Заказчик',
	[ADMIN_ROLE]:'Админ',
	[MANAGER_DIGITAL_ROLE]:'Оцифровка',
	[MANAGER_MODELLING_ROLE]:'Моделировка',
	[MANAGER_ROLE]:'Менеджер'
}
export const ROLES = [
	USER_ROLE,
	ADMIN_ROLE,
	MANAGER_DIGITAL_ROLE,
	MANAGER_ROLE,
	MANAGER_MODELLING_ROLE
]
export const MANAGER_ROLES = [
	ADMIN_ROLE,
	MANAGER_DIGITAL_ROLE,
	MANAGER_ROLE,
	MANAGER_MODELLING_ROLE
]
export{
	USER_ROLE,
	ADMIN_ROLE,
	MANAGER_DIGITAL_ROLE,
	MANAGER_ROLE,
	MANAGER_MODELLING_ROLE
}