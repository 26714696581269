import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getRoutes } from "../../../../router";
import { REGISTER_ADMIN_ROUTE, REGISTER_CLIENT_ROUTE, REGISTER_MANAGER_ROUTE } from "../../../../utils/consts";
import { BTN_REGISTER_ADMIN, BTN_REGISTER_CLIENT, BTN_REGISTER_DEFAULT, BTN_REGISTER_MANAGER } from "../../../../utils/consts/BTNS";
import { PAYMENT_FAILURE_ROUTE, PAYMENT_SUCCESS_ROUTE } from "../../../../utils/consts/ROUTES";
import { getRouteByPath } from "../../../../utils/getRouteVar";
import ModalBtn from "../../formBtn/ModalBtn/ModalBtn";
import RegisterForm from "../../Forms/RegisterForm";
import ModalLink from "../ModalLink/ModalLink";
import classes from '../NavBar.module.css'
const NavbarList = ({children,setIsVisible}) => {
	const user = useSelector(state => state.userReducer.user);
	const location = useLocation();
	//const [activePath, setActivePath] = useState(location.path);
	const getLinks = () => {
		const links=[]
		const routes = getRoutes(user.role);
		for(let i = 0; i< routes.length; i++){
			if(
				routes[i].path!=='*' 
				&& routes[i].path !==PAYMENT_FAILURE_ROUTE.path
				&& routes[i].path !==PAYMENT_SUCCESS_ROUTE.path 
				&& routes[i].path !==REGISTER_CLIENT_ROUTE.path ){
				links.push(routes[i]);
			}
		}
		return links
	} 
	const [links, setLinks] = useState([...getLinks()])
	const getNavbarBtns = (path) => {
		switch(path){
			case REGISTER_ADMIN_ROUTE.path:{
				return BTN_REGISTER_ADMIN
			}
			case REGISTER_MANAGER_ROUTE.path:{
				return BTN_REGISTER_MANAGER
			}
			default:{
				return BTN_REGISTER_CLIENT
			}
		}
	}
	const onClickHandler = () => {
		if(setIsVisible){
			setIsVisible(false)
		}
	}
	return(
		<div className={`${classes['navbar__list-wrap']} navbar__list-wrap`}>
			<ul className={classes['navbar__list']}>
				{
					links.map((item) => {
						if(
							item.path === REGISTER_ADMIN_ROUTE.path
								||
							item.path === REGISTER_CLIENT_ROUTE.path
								||
							item.path === REGISTER_MANAGER_ROUTE.path
						)
						{
							const btn = getNavbarBtns(item.path);
							const linkClasses=[classes['navbar__link'], 'link']
							if(location.pathname === item.path){
								linkClasses.push('--active')
							}
							return( 
								<li className={classes['navbar__list-item']} key={item.path}>
									<ModalBtn 
										modalComponent={{
											item:btn.item,
											option:btn.option
										}}
										btnProps={
											{
												...btn.props,
												classNames:[...linkClasses]
										}}
									/>
								</li>
							)
						}
						// removing all routes with :id from navbar
						const path = item.path.split('/');
						if(path[path.length-1] === ':id'){
								return'';
						}
						const linkClasses=[classes['navbar__link'], 'link']
						if(location.pathname === item.path){
							linkClasses.push('--active')
						}
						return(
							<li className={classes['navbar__list-item']} key={item.path}>		
								<Link to={item.path} className={linkClasses.join(' ')} onClick={onClickHandler}>
									<strong>{item.name}</strong>
								</Link>
							</li>
						)
					})
				}	
			</ul>
			{children}
		</div>
	)
}
export default NavbarList;