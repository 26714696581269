import React, { useEffect } from "react";
import FormBtn from '../formBtn/FormBtn.jsx';
import classes from './FileItem.module.css'

const FileItem = ({file, setValue, value, setFileSize}) => {
	const removeElement = (id) => {
		if (!id) {
			return;
		}
		setFileSize(prev => {
			let res =prev - file?.size;
			return Number(res.toFixed(1))
		})
		setValue([...value.filter(item => item.id !== id)])
	}

	return (
		<div className={classes['file-loader__file']}>
			<span>{file.name.concat('.'+file.format)}<br/><span style={{fontSize:'0.8rem'}}>{file?.size}мб</span></span>
			<FormBtn spec = {['btn--close']} handleClick = {() => removeElement(file.id)}/>
		</div>
	)
}
export default FileItem;