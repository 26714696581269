import { useEffect,useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import actions from "../../../store/reducers/actions"
import FormBtn from "../formBtn/FormBtn"
import classes from './NavBar.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTelegram} from '@fortawesome/free-brands-svg-icons'
import {
	ADMIN_ROLE,
	MANAGER_ROLE,
	REGISTER_ADMIN_ROUTE,
	REGISTER_CLIENT_ROUTE,
	REGISTER_MANAGER_ROUTE,
	USER_ROLE,
} from '../../../utils/consts'
import ModalLink from "./ModalLink/ModalLink"
import { logoutUser } from "../../../http/userAPI"
import NavbarList from "./NavbarList/NavbarList"
import { roleNames } from "../../../utils/consts/ROLES"
const MODILE_NAVBAR = 'mobileNavbar';
const NavBar = (props) => {
	const { children, modification='', classNames=[]} = props;
	const {user, isAuth} = useSelector(state => state.userReducer)
	const dispatch = useDispatch();
	const rootClasses = [classes['navbar'], 'navbar',...classNames]
	/*const checkRole = () => {
		switch (role) {
			case ADMIN_ROLE:
			case MANAGER_ROLE:
			case MANAGER_SECONDARY_ROLE:
				return true
			default:
				return false
		}
	}*/

	return(
		<>
			<div className={rootClasses.join(' ')}>
				<Link to={'/api/deals'} className='app__link' style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
					{
						user.role === USER_ROLE
						?	<>
								<span>ЛИЧНЫЙ КАБИНЕТ</span>
								<span style={{fontSize:'1rem', fontWeight:'300'}}>{user.getValue('firstname') +' '+user.getValue('lastname')}</span>
							</>
						:(
							isAuth
							?`${roleNames[user.role]}`
							:<></>
						)

					}
				</Link>
				<NavbarList>
					{children}
				</NavbarList>
				{
					user.role === USER_ROLE
					?(
						<div className="logo__decor">
							<div className="square-o">
								<span className="square-o-t bg-accent"></span>
							</div>
							<span className="angle45"></span>
						</div>
					)
					:''
				}
			</div>
		</>
	)
}
export default NavBar;
