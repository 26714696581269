import { useSelector } from "react-redux";
import OrderItemList from "../../../OrderItem/OrderItemList/OrderItemList";
const UserProfileInfo = ({modification}) => {
	const {user} = useSelector(state => state.userReducer)
	return (
		<>
			{
				<OrderItemList order={user} listType='allContentFields' modification={modification}/>
			}
		</>
	)
}
export default UserProfileInfo;