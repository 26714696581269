import { useEffect } from "react";
import { useCountdown } from "../../../hooks/useCountdown";
import TimerCounter from "./TimerCounter/TimerCounter";

const Timer = ({ targetDate, timerEndCall=()=>{}, checker=()=>{} }) => {
 	const [days, hours, minutes, seconds] = useCountdown(targetDate);
	 useEffect(()=>{
		checker();
		if (days + hours + minutes + seconds <= 0) {
			timerEndCall();
		}
	 },[days, hours, minutes, seconds])
	if (days + hours + minutes + seconds <= 0) {
		return <></>;
	} else {
		return (
			<TimerCounter
				days={days}
				hours={hours}
				minutes={minutes}
				seconds={seconds}
			/>
		);
	}
};
export default Timer;