export const isEmptyCheck = ({requiredFields, fieldValue, data = null}) => {
	let isEmpty = true;
	if(!requiredFields || !fieldValue){
		return;
	}
	for(let i=0; i < requiredFields.length; i++){
		if(fieldValue[requiredFields[i].id] === undefined || fieldValue[requiredFields[i].id] === ''){
			return {isEmpty, msg:`Заполните поле '${requiredFields[i].innerText}'`}
		}
		if (fieldValue[requiredFields[i].id] === false){
			return {isEmpty, msg:requiredFields[i].errorMessage}

		}
	}
	if(data !== null){
		if(data.length === 0){
			return {isEmpty, msg:'Дополните форму'}
		}
	}
	return !isEmpty;
}
