import { faK } from "@fortawesome/free-solid-svg-icons";
import { COMMENTS } from "./consts/OBJECT_FIELDS";
import { isEmptyCheck } from "./formValidation";
import { getSortedList } from "./getSortedList";
import { getStatusObject } from "./getStatusId";
import sleep from "./sleep";
import tempAlert from "./tempAlert";

export const sendData = async (send, fieldObj) => {
	const requiredFields = [...getSortedList(true, 'required', fieldObj.formFields)]
	//check only if required
	const checkForm = isEmptyCheck({requiredFields:requiredFields, fieldValue:fieldObj.fieldValue});

	if(checkForm.isEmpty){
		tempAlert(checkForm.msg, 3000,['--invalid']);
		return;
	}
	let data = send({...fieldObj.fieldValue});
	return data;
	// let status = fieldObj.fieldValue['status'];
	// if(!status){
	// 	status = 'STATUS_NEW'
	// }
	// let id = fieldObj.fieldValue['id'];
	// if(!id){
	// 	id = Math.floor(Math.random()*400);
	// }
	// //fake response time
	// await sleep(3000);
	// const fakeResponse =  {
	// 	data:{
	// 		...fieldObj.fieldValue,
	// 		status,
	// 		id,
	// 		//role:'USER',
	// 		comments:[{
	// 			id:Math.floor(Math.random()*500),
	// 			comment:'aaaa',
	// 			dealId: id,
	// 			createdAt:Date.now(),
	// 			userInfo:{
	// 				id:1,
	// 				role:'ADMIN',
	// 				firstname:'Иван',
	// 				lastname:'Иванович',
	// 				phone:'+799999999'
	// 			}
	// 		}],
	// 		//bonusPoints:
	// 	},
	// 	success:0
	// }
	// console.log(fakeResponse)
	// return fakeResponse
}
