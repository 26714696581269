import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import fileExists from "../../../../../utils/fileExists";
import tempAlert from "../../../../../utils/tempAlert";
import FileItem from '../../../FileItem/FileItem.jsx';
const MAX_SIZE = 20;
const FileLoader = (props) => {
	const { 
		fileAccept, 
		multiple, 
		inclDrop, 
		requiredProps
	} = props
	const {
		id, 
		inputType, 
		value, 
		setValue, 
		required, 
		disabled, 
		innerText,
	} = requiredProps;
	const [fileSize, setFileSize] = useState(0);
	const fieldValue = useSelector(state => state.fieldValueReducer.fields)
	const dropHandler = (files) => {
		let addedSize = 0;
		for (let i = 0; i<files.length;i++){
			let fileName = files[i].name.split('.').slice(0, -1).join('.');
			const flag = fileExists(value, fileName);
			if (flag) {
				tempAlert(`Вы уже загрузили : "${files[i].name}"`, 3500, ['--invalid']);
				return;
			}
			let currSize = Number((files[i].size /(1024*1024)).toFixed(2));
			if(0<currSize && currSize <0.1){
				currSize = 0.1;
			}
			const fileToPush = {
				name: fileName,// id сделки
				format: `${files[i].name.split('.').pop().toLowerCase()}`,
				base64: '',
				size:Number(currSize.toFixed(1)),
				id: `${files[i].name.split(' ')[0]}-${Date.now()* Math.floor(Math.random()*200)}`,
			}
			addedSize+=currSize;
			addedSize = Number((addedSize).toFixed(1));
			if(Number((addedSize + fileSize).toFixed(1)) >= MAX_SIZE){
				tempAlert(`Размер загружаемых файлов превышает ${MAX_SIZE}мб`, 3000, ['--invalid'])
				return;
			}
			const reader = new FileReader();
			reader.onload = (ev) => {
				fileToPush.base64 = ev.target.result;
			}
			reader.readAsDataURL(files[i]);
			files[i] = fileToPush;
		}
		setFileSize(prev => Number((prev + addedSize).toFixed(1)));
		setValue([...value, ...files])
		//setTimeout(() => {dispatch(actions.addFileAction([...files]));},100)
	};
	const onChangeHandler = (event) =>{
		event.preventDefault();
		const files = Array.from(event.target.files);
		event.target.value = '';
		dropHandler(files)
	}
	useEffect(()=>{
		if(fieldValue?.documents?.length > 0){
			let size=0;
			for(let i=0;i<fieldValue?.documents.length; i++){
				let fileSize = fieldValue?.documents[i].size;
				if(0 < fileSize && fileSize < 0.1){
					fileSize = 0.1;
				}
				size+= Number(fileSize.toFixed(1))
			}
			setFileSize(prev => prev + Number(size.toFixed(1)))
		}
	},[])
	return(
		<div className="form__file-loader">
			<input 
			className="file-loader__input visually-hidden" 
				onChange = {onChangeHandler} 
				type={inputType} 
				id={id}
				name={id}
				accept={fileAccept}
				multiple={multiple}
			/>
			<label 
				htmlFor={id} 
				className="file-loader__label btn"
			>
				<FontAwesomeIcon icon="fa-solid fa-download" />
				{/* {innerText} */}
			</label>
			<span style={{fontWeight:'bold'}}>Общий размер файлов: {fileSize} мб</span>
			<div className="file-loader__container">
				{
					value
					?value.map(
						(file) => <FileItem key={file.id} file={file} setValue={setValue} value={value} setFileSize={setFileSize}/>)
					
					:''
				}
			</div>
			{
				inclDrop
				?(<div className="file-loader__inv" 
					onDrop={(e) => {
						e.preventDefault();
						const files = Array.from(e.dataTransfer.files);
						e.target.value = '';
						dropHandler(files)
					}
					}
					onDragEnter={(e) => e.preventDefault()}
					onDragOver={(e) => e.preventDefault()}
					onDragEnd={(e) => e.preventDefault()}
					onDragLeave={(e) => e.preventDefault()}
				>
					<span className="file-loader__drop-zone">
						Или перетяните файл сюда
					</span>
					<input 
						type={inputType} 
						className="file-loader__inv-input visually-hidden" 
						accept={fileAccept} 
						multiple/>
				</div>)
				:''
			}
		</div>
	)
}

export default FileLoader;