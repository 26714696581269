import {$api,$host} from './index';
import { REACT_APP_API_URL, SERVER_ROUTES, USER_ROUTE } from '../utils/consts';

import { getByLimitAndPage, getUsers } from '../utils/getMock';
//const userArray = [...getUsers()];
export const fetchUsers = async (props) => {
	const {limit, page, sort_by , filter} = props
	//console.log('limit' + limit, 'page' +page, sort_by , filter)
	//return getByLimitAndPage({limit,page, sort_by,filter, array:userArray})
	const { data } = await $api.get(SERVER_ROUTES.usersGet, {
		params:{
		limit,
		page,
		sort_by,
		filter
	}
	});
	return data.data;
}
export const fetchManagers = async (props) => {
	const {limit, page, sort_by , filter} = props
	//return getByLimitAndPage({limit,page, sort_by,filter, array:userArray})
	const { data } = await $api.get(SERVER_ROUTES.managersGet, {
		params:{
		limit,
		page,
		sort_by,
		filter
	}
	});
	return data.data;
}