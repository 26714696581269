import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AlertPage from "../../AlertPage/AlertPage"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

const Success = (props) => {
	const {title, text} = props

	return(
		<AlertPage
			title={title}
			classNames={['success']}
		>
			<FontAwesomeIcon className='image-success alertPage__image' icon={faCheckCircle}/>
			<p className="alertPage__text">
				{text}
			</p>
		</AlertPage>
	)
}
export default Success
