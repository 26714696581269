import {useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form, { BLOCK_TYPE, CREATE_DEAL_FORM, CREATE_DEAL_FORM_MODEL, CREATE_DEAL_FORM_MODEL_TEST, CREATE_DEAL_FORM_MODEL_VISIBLE, CREATE_DEAL_FORM_VISIBLE, RAW_FIELDS_TYPE } from "../../../../utils/classes/Form";
import { REGISTER_CLIENT_ROUTE } from "../../../../utils/consts";
import RegisterForm from "../RegisterForm";
import CreateDealForm from "./CreateDealForm";
const CreateDealFormSwitch = ({isModal, setModalVisible}) =>{
	const dispatch = useDispatch();
	const form = useSelector(state => state.formReducer);
	const fieldValue = useSelector(state => state.fieldValueReducer.fields)
	const [formPath, setFormPath] = useState(CREATE_DEAL_FORM);
	console.log(isModal, setModalVisible)
	return (
		formPath === REGISTER_CLIENT_ROUTE.path
		?<RegisterForm formPath={REGISTER_CLIENT_ROUTE.path} isModal={isModal} setModalVisible={setModalVisible}/>
		:<CreateDealForm setFormPath={setFormPath} isModal={isModal} setModalVisible={setModalVisible} formType='blocks'/>
		
	)
}
export default CreateDealFormSwitch;