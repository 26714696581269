import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { REACT_APP_API_URL } from "../../../../utils/consts";
import FormBtn from "../../formBtn/FormBtn";
import TempAlert from "../../TempAlert/TempAlert";

const SubAlert = ({msg, setVisible}) => {
	console.log(msg, setVisible)
	return(
		<TempAlert
			msg={msg}
			targetDate={new Date().getTime() + 5000}
			setVisible={setVisible}
			classNames={['--subscribe']}
			content={
				<FormBtn handleClick={() => window.location.reload()}>
					<FontAwesomeIcon className={'image-reload'} icon="fa-solid fa-arrow-rotate-right" />
				</FormBtn>
			}
		/>
	)
}
export default SubAlert;
