import {  STATUS_PAIRS } from "./consts"
/*export const getStatusFieldIdByName = (statusName) => {
	let statusId = 0;
	for(let i=0; i < STATUS_ID_NAME.length; i++){
		if(statusName===STATUS_ID_NAME[i] ){
			statusId = i;
		}
	}
	return STATUS_ID_FIELD[statusId]
}*/
/*export const getStatusIdByName = (statusName) => {
	let statusId = 0;
	for(let i=0; i < STATUS_ID_NAME.length; i++){
		if(statusName===STATUS_ID_NAME[i] ){
			statusId = i;
		}
	}
	return statusId
}*/
/*export const getStatusIdByField = (fieldName) => {
	let statusId = 0;
	for(let i=0; i < STATUS_ID_FIELD.length; i++){
		if(fieldName===STATUS_ID_FIELD[i] ){
			statusId = i;
		}
	}
	return statusId
}*/
/*export const getStatusIdByServerStatusName = (statusName) => {
	let statusId = 0;
	for(let i=0; i < STATUS_PAIRS.length; i++){
		if(statusName===STATUS_PAIRS[i].name){
			statusId = STATUS_PAIRS[i].id;
		}
	}
	console.log(statusId)
	return statusId;
}*/
/**
 * (can get by any known parameter)
 * returns a statusObj that have:
 * id: num
 * statusName: serverName
 * description: description on client 
 */
export const getStatusObject=({statusName, id, description})=>{
	for(let i=0; i < STATUS_PAIRS.length; i++){
		if(
			STATUS_PAIRS[i].statusName === statusName 
			|| 
			STATUS_PAIRS[i].id === id
			||
			STATUS_PAIRS[i].description === description
		){
			return STATUS_PAIRS[i]
		}
	}
	return null;
}
export const getManyStatusObj = ({statusNames, ids, descriptions}) => {
	const statusObjects = []
	if(statusNames){
		for(let i=0; i< statusNames.length; i++){
			statusObjects.push(getStatusObject({statusName:statusNames[i]}))
		}
	} else if(ids){
		for(let i=0; i< ids.length; i++){
			statusObjects.push(getStatusObject({id:ids[i]}))
		}
	} else if(descriptions){
		for(let i=0; i< descriptions.length; i++){
			statusObjects.push(getStatusObject({description:descriptions[i]}))
		}
	}
	return statusObjects;
}