const ContentFields = ({order}) => {
	const orderContent = Object.entries(order.content);
	return(
		<ul className = 'content-list'>
			{
				orderContent.map(item => {
					return(
						<li 
							className='content-list__item' 
							key={item[0]}
						>
							{
								order.getFieldText(item[0])
							}
						</li>
					)
				})
			}
		</ul>
	)
}
export default ContentFields