import { useSelector } from 'react-redux';
import { BONUS_POINTS, TOTAL_PAID } from '../../../../utils/consts/OBJECT_FIELDS';
import classes from './UserLoyality.module.css'
const UserLoyality = () => {
	const {user} = useSelector(state=> state.userReducer)
	return(
		<div className='container loyality'>
			<div className='loyality__info'>
				<p style={{fontSize:'1.2rem'}}>
					<strong>Начинает действовать с первого заказа:</strong><br/>
					при втором заказе можно использовать
					кэш-бэк в размере 10% от стоимости первого
					заказа или использовать кэш-бэк позднее, для
					оплаты последующего из заказов полностью
					или частично
				</p>
				<p style={{fontSize:'1.2rem'}}>
					При использовании кэш-бэка для оплаты заказа/ части 
					заказа кэш-бэк за текущий заказ не начисляется,
					начисление кэш-бэка начинается со следующего заказа
				</p>
				<h3 className="title">{user.getFieldText(TOTAL_PAID)}</h3>
				<h3 className="title">{user.getFieldText(BONUS_POINTS)}</h3>
			</div>
		</div>
	)
}
export default UserLoyality;