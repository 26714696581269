import FormTemplate from "../../../FormTemplate";
import { useEffect, useState } from "react";
import FormBtn from "../../formBtn/FormBtn";
import classes from './UserDataForm.module.css'
import { sendData } from "../../../../utils/sendData";
import { updateUserData,rmUserData } from "../../../../http/userAPI";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../store/reducers/actions";
import { CLIENTS_ROUTE, FORM_FIELDS, SUCCESS_CODE, USER_ROUTE } from "../../../../utils/consts";
import tempAlert from "../../../../utils/tempAlert";
import Form, { BLOCK_TYPE, REGISTER_FORM, USER_DATA_FORM } from "../../../../utils/classes/Form";
import { FORM_BLOCKS, RAW_FIELDS_TYPE } from "../../../../utils/consts/FORM_FIELDS";
import User from "../../../../utils/classes/User";
import { BTN_LOADER, BTN_REMOVE, BTN_RETURN, BTN_SAVE } from "../../../../utils/consts/BTNS";
import BtnContainer from "../../BtnContainer/BtnContainer";
import FormComponent from "../../../FormComponent";
import getFields from "../../../../utils/formUtils/getFields";
import { useFetching } from "../../../../hooks/useFetching";
import Loader from "../../loader/Loader";
import { BRAND, FIRSTNAME, INN, LEGAL_PERSON, PHONE, RS } from "../../../../utils/consts/OBJECT_FIELDS";
import { MANAGERS_ROUTE } from "../../../../utils/consts/ROUTES";
const UserDataFormAdmin = (props) =>{
	const {beforeFields='', setIsOnEdit, userId, modification=''} = props;
	const fieldValue = useSelector(state => state.fieldValueReducer.fields)
	const user = useSelector(state => state.userReducer.user);
	const {list} = useSelector(state => state.dataReducer.users)
	const dispatch = useDispatch();
	const form = useSelector(state => state.formReducer);
	const fieldsArr = getFields({FORM_TYPE:BLOCK_TYPE, FORM_FIELDS:form.blocks});
	const [sendUserData, isLoading, error] = useFetching(async () => {
		let response = null;
		try{
			response = await sendData(updateUserData,{
				formFields: [...fieldsArr],
				fieldValue: {...fieldValue}
			})
		} catch(e){
			console.log(e);
		}
		if(response.success === SUCCESS_CODE){
			const userItem = new User(
				{
					...response.data
				}
			)
			dispatch(actions.updateDataItemAction(
				{
					dataName:'users',
					item:userItem
				}
			))
			setIsOnEdit(false);
			tempAlert('успешное изменение', 3000,['--valid']);
		} else{
			tempAlert(response.message, 3000,['--invalid']);
		}
	})
	const rmUser = async () => {
		let response = null;
		try{
			response = await rmUserData({id:userId})
		} catch(e){
			console.log(e);
		}
		if(response.success === SUCCESS_CODE){
			setIsOnEdit(false);
			dispatch(actions.rmDataItemAction({
				dataName:'users',
				dataItemId:userId
			}))//updating only user.content
			tempAlert('пользователь успешно удален', 3000,['--valid']);
		} else{
			tempAlert(response.message, 3000,['--invalid']);
		}
	}
	const getFormBtns = () => {
		if(isLoading){
			return [BTN_LOADER]
		}
		const btns = [
			{
				...BTN_RETURN,
				props:{
					...BTN_RETURN.props,
					handleClick:() => {
						setIsOnEdit(false);
					}
				}
			},
			{
				...BTN_SAVE,
				props:{
					...BTN_SAVE.props,
					handleClick:sendUserData
				}
			},
			{
				...BTN_REMOVE,
				props:{
					...BTN_REMOVE.props,
					handleClick:rmUser
				}
			},

		]
		return btns;
	}
	const getValues = ()=>{
		const userItem = list.filter(item => item?.id === userId)[0]
		//console.log(Object.entries(userItem.convertedServer))
		return Object.entries(userItem.convertedServer)
	}
	useEffect(()=>{
		dispatch(actions.addFieldAction({id:'userId', value:userId}))
		return () => {
			console.log('onUnmount')
		}
	},[])
	return (
		<FormComponent
			beforeFields={beforeFields}
			formClassNames ={['form__user-profile']}
			formType={USER_DATA_FORM}
			formPath={
				modification === 'managers'
				?MANAGERS_ROUTE.path
				:CLIENTS_ROUTE.path
			}
			existingValues={getValues()}
		>
			<BtnContainer btns={getFormBtns()}/>
		</FormComponent>
	)
}
export default UserDataFormAdmin;