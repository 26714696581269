import FormBtn from "../formBtn/FormBtn";
import ModalBtn from "../formBtn/ModalBtn/ModalBtn";

// returns container of btns(obj type)
/**
 * fields:
 * props - all the props inside FormBtn
 * id - btn id
 * if(btn has ModalWindow)
 * item - item inside modalWindow
 * option - modal window option(actually for styling(look ModalWindow component) 
 * 
 */

const BtnContainer = ({btns, classes=[], onBtnClick}) => {
	return(
		<div className={`btn-container ${classes.join(' ')}`}>
			{
				btns.map(btn => {
					let returnBtn = <></>
					btn?.isModal
					?returnBtn = (
						<ModalBtn 
							key={btn.props.id}
							btnProps={btn.props}
							title={btn.title} 
							onClick={onBtnClick}
							modalComponent={{
								item:btn.item,
								option:btn.option,
								modification:btn?.modification
							}}
						/>
					)
					:returnBtn = (<FormBtn {...btn?.props} key={btn?.props.id}/>)
					return returnBtn
				})
			}
		</div>
	)
}
export default BtnContainer;