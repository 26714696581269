import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { payBonus, updateDealItem } from "../../../../../http/dealAPI";
import actions from "../../../../../store/reducers/actions";
import canPay from "../../../../../utils/canPay";
import Order from "../../../../../utils/classes/Order";
import User from "../../../../../utils/classes/User";
import {
	ADMIN_ROLE,
	STATUS_COMPLETED,
	SUCCESS_CODE,
	USER_ROLE,
	MANAGER_ROLE,
	STATUS_AWAITING_PAYMENT
} from "../../../../../utils/consts";
import { ADVANCE_PAYMENT, BONUS_POINTS, COST, PAYMENT_LINK, PAY_METHOD, REST_PAYMENT, STATUS, USE_BONUS } from "../../../../../utils/consts/OBJECT_FIELDS";

import { STATUS_CANCELED, STATUS_NEW,STATUS_FUNDED,STATUS_PREPAID ,STATUS_COMPLETED_MODEL} from "../../../../../utils/consts/STATUS";
import tempAlert from "../../../../../utils/tempAlert";
import FormBtn from "../../../formBtn/FormBtn";

const PayBtn = ({orderItem}) => {
	const fieldValue = useSelector(state => state.fieldValueReducer.fields)
	const {user} = useSelector(state => state.userReducer)
	const dispatch = useDispatch();
	// pay with bonus;
	const sendOrderData = async () => {
		let response = null;
		let updStatus = '';
		if(orderItem[STATUS] === STATUS_NEW.statusName){
			updStatus = STATUS_PREPAID.statusName;
		} else if(orderItem[STATUS] === STATUS_COMPLETED_MODEL.statusName){
			updStatus = STATUS_FUNDED.statusName;
		}
		try{

			response = await payBonus({
				id:orderItem.id,


			})
		} catch(e){
			tempAlert(e, 3000,['--invalid']);
		}
		if(response.success ===	SUCCESS_CODE){
			const prevOrder = {...orderItem.convertedServer};
			const newOrder = new Order({
				...response.data
			})
			dispatch(actions.rmOrderItemAction(
					{
						orderType: prevOrder.status,
						itemId: orderItem.id
					}
				))
			dispatch(actions.addOrderItemAction(
					{
						orderType: newOrder[STATUS],
						item: newOrder
					}
			))
			const userObj = new User(
				{
					...user.convertedServer,
					orders:[...user.orders.filter(order => order.id !==newOrder.id), newOrder],
					currentOrder:newOrder.id
				}
			)
			dispatch(actions.setUserAction(userObj))
			tempAlert('сделка оплачена успешно', 3000,['--valid']);
		} else{
			tempAlert('ошибка изменения', 3000, ['--invalid']);
		}
	}

	if(
		user.role !== USER_ROLE
		&&
		user.role !== ADMIN_ROLE
		&&
		user.role !== MANAGER_ROLE
	){
		return <></>;
	}
	// if(
	// 	!canPay(orderItem[STATUS])
	// ){
	// 	return <></>;
	// }
	// if(!orderItem.getValue('dateSending'))
	// {
	// 	return <></>;
	// }
	// if statusNew && dateSending -> canPay
	// if isChecked || role === (ADMIN || MANAGER)


	if(orderItem.getValue(PAYMENT_LINK) && canPay(orderItem[STATUS])){
		return(
			<a href={orderItem.getValue(PAYMENT_LINK)} className='btn pay' target='_blank'>
				{
					orderItem.status === STATUS_AWAITING_PAYMENT.statusName
					?'Внесите аванс'
					:'Оплатите остаток'
				}
			</a>
		)
	} else{
		return <span className='btn pay project_ref--disabled'>
				Оплатить
		</span>
	}
}
export default PayBtn;
