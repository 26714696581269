
import moment from "moment";
import { useEffect, useState } from "react";
import { generateDates } from "../../../../utils/Time/time";
import FormBtn from "../../formBtn/FormBtn";
import classes from '../Calendar.module.css'
const CalendarHeader = ({ date, setDate, setDays }) => {
	const [title, setTitle] = useState('');
	const goYearBack = (
		date,
		setDate,
		setDays
	) => {
		const update = moment(date).subtract(1, "years").toDate();
		setDate(update);
		setDays(generateDates(update));
	};
	const goMonthBack = (
		date,
		setDate,
		setDays,
	) => {
		const update = moment(date).subtract(1, "months").toDate();
		setDate(update);
		setDays(generateDates(update));
	};
	const goMonthForward = (
		date,
		setDate,
		setDays,
	) => {
		const update = moment(date).add(1, "months").toDate();
		setDate(update);
		setDays(generateDates(update));
	};
	const goYearForward = (
		date,
		setDate,
		setDays,
	) => {
		const update = moment(date).add(1, "years").toDate();
		setDate(update);
		setDays(generateDates(update));
	};
	//get year + monthName by date
	const monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь','Декабрь']
	const getTitle = (dateObj) => {
		return(`${monthNames[dateObj.getMonth()]} ${dateObj.getFullYear()}`)
	}
	useEffect(()=>{
		setTitle(getTitle(date))
	},[date])
	return (
	  <div className={classes['calendar__header']}>
	  	<FormBtn handleClick={() => {
			goYearBack(date, setDate, setDays);
		}}>
			{'<<'}
		</FormBtn>
		<FormBtn handleClick={() => {
			goMonthBack(date, setDate, setDays);
		}}>
			{'<'}
		</FormBtn>
		<h3 className={classes['calendar__header-title']}>{title}</h3>
		<FormBtn handleClick={() => {
			goMonthForward(date, setDate, setDays);
		}}>
			{'>'}
		</FormBtn>
		<FormBtn handleClick={() => {
			goYearForward(date, setDate, setDays);
		}}>
			{'>>'}
		</FormBtn>
	  </div>
	);
  };
  export default CalendarHeader