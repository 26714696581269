import FormField from "../../components/UI/FormField/FormField";
import CreateDeal from "../../components/UI/Forms/AddedFields/AddedFieldsSpec/CreateDeal/CreateDeal";
import CreateDealCost from "../../components/UI/Forms/CreateDealForm/CreateDealCost/CreateDealCost";
import ModalWindow from "../../components/UI/modalWindow/ModalWindow";
import { fetchAddress, fetchAdress } from "../../http/daData";
import {
	ADDRESS,
	BIK,
	BRAND,
	CITY,
	COMMENT,
	COMMENTS,
	COST,
	DEMONSTRATION_LINK,
	DEMO_LINK,
	DESIRED_DATE,
	DESIRED_TIME,
	DOCUMENTS,
	EMAIL,
	FIRSTNAME,
	FLAT,
	FLOORS,
	FLOORS_SELECT,
	HOUSE,
	INN,
	IS_LEGAL,
	KPP,
	LASTNAME,
	LEGAL_PERSON,
	LINKS,
	PACKAGE_DEAL,
	PHONE,
	POSITION,
	PROJECT_DIGITALIZATION_LINK,
	PROJECT_LINK,
	REPRESENTATIVE_NAME,
	REPRESENTATIVE_PHONE,
	RS,
	SENDING_DATE,
	SEND_DEAL_INFO,
	SEND_LINK,
	SMS_PASSWORD,
	SOFTWARE,
	SQUARE,
	SQUARE_SELECT,
	STATUS,
	STREET,
	TG,
	WHATSAPP,
	PRIVACY_POLICY,
	PRIVACY_POLICY_IS_LEGAL,
	I_WILL_BE,
} from "./OBJECT_FIELDS";
import { ALL_SQUARE_OBJ, CAD_2D, CAD_BIM_3D, SCAN_SHOOTING_3D, SQUARE_50 } from "./PRICE";
import { STATUS_ID_NAME, STATUS_NEW } from "./STATUS"
export const FIELD_TYPE_INPUT = 'INPUT';
export const BLOCK_TYPE = 'blocks';
export const RAW_FIELDS_TYPE = 'raw_fields'
export const SERVICE_TYPES = {
	cad_bim_3d:'Обмерная 3-D модель',
	scan_shooting_3d:'Цифровой 3-D двойник',
	cad_2d:'Обмерный 2-D план',
}
const FORM_FIELDS = {
	[CITY]: {
		id: CITY,
		fieldType:FIELD_TYPE_INPUT,
		options:[
			'СПБ',
			'МСК',
			'ЛО',
			'МО'
		],
		errorMessage:'выберите один из городов',
		spec:'selector'	,
		innerText:'Выбрать город*',
		currValue:'СПБ',
		defaultValue:'СПБ',
		required: true
	},
	[STREET]: {
		id: STREET,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Улица',
		type:'text',
		required: true
	},
	[HOUSE]:{
		id: HOUSE,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Номер дома',
		type:'text',
		required: true,
		errorMessage:'введите цифры',
		//pattern:'[0-9]{1,}',
	},
	[FLAT]:{
		id: FLAT,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Номер Кв',
		type:'text',
		required: false,
		errorMessage:'введите цифры',
		//pattern:'[0-9]{1,}',
	},
	[SQUARE]:{
		id: SQUARE,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Введите площадь*',
		type:'text',
		required: true,
		errorMessage:'введите цифры',
		pattern:'[0-9]{1,}',
		//forAllClasses:['visually-hidden']
	},
	[ADDRESS]:{
		id: ADDRESS,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Введите адрес*',
		type:'text',
		required: true,
		spec:'query',
		fetchFunction: fetchAddress,
		fetchDeps:[], // for useEffect in form(arr of deps, when fetch will work)
		queryParams:{query:'', locations:[]},
	},
	[SQUARE_SELECT]:{
		id: 'squareSelect',
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Выберите площадь*',
		type:'text',
		required: true,
		errorMessage:'введите цифры',
		//pattern:'[0-9]{1,}',
		options:[
			...ALL_SQUARE_OBJ.map(obj => obj.name),
			'Другая площадь'
		],
		spec:'selector'	,
		defaultValue:'до 50м²',
		currValue:'до 50м²',
	},
	[FLOORS]:{
		id: FLOORS,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Введите этажность*',
		type:'text',
		required: true,
		errorMessage:'введите цифры',
		currValue:'',
		defaultValue:'',
		spec:'',
		pattern:'[0-9]{1,}'
	},
	[FLOORS_SELECT]:{
		id: FLOORS_SELECT,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Введите этажность*',
		options:['1','2','3','4','5','6','Другая этажность'],
		required: true,
		spec:'selector'	,
		defaultValue:'1',
		currValue:'1',
	},
	[COST]:{
		id: COST,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Введите стоимость*',
		type:'text',
		required: true,
		errorMessage:'введите цифры',
		pattern:'[0-9]{1,}',
		currValue:'',
		defaultValue:'',
		spec:''
	},
	// [FLOORS_ANY]:{
	// 	id: FLOORS,
	// 	fieldType:FIELD_TYPE_INPUT,
	// 	innerText: 'Введите этажность',
	// 	type:'text',
	// 	required: true,
	// 	errorMessage:'введите цифры',
	// 	pattern:'[0-9]{1,}',
	// },
	[DESIRED_DATE]:{
		id: DESIRED_DATE,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Желаемая дата сканирования',
		type:'date',
		spec:'date',
		required: false,
		//errorMessage:'введите в формате xx/xx/xxxx',
		//pattern:'[0-9]{2}/[0-9]{2}/[0-9]{4}',
	},
	[DESIRED_TIME]:{
		id: DESIRED_TIME,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Желаемое время сканирования',
		type:'time',
		spec:'time',
		required: false,
	},
	[REPRESENTATIVE_NAME]:{
		id: REPRESENTATIVE_NAME,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Имя представителя',
		type:'text',
		required: false,
		// value: 'dddddd',
		// currValue: 'cdcsd',
		updateDefaultValue: (value) => value
	},
	[REPRESENTATIVE_PHONE]:{
		id: REPRESENTATIVE_PHONE,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Телефон представителя',
		type:'text',
		mask:'+7(999)-999-99-99',
		required: false
	},
	//usage of optionsWithChildren
	// [PACKAGE_DEAL]:{
	// 	id: PACKAGE_DEAL,
	// fieldType:FIELD_TYPE_INPUT,//
	// options:[
	// 		SERVICE_TYPES.cad_2d,
	// 		SERVICE_TYPES.cad_bim_3d,
	// 		SERVICE_TYPES.scan_shooting_3d,
	// 	],
	// 	errorMessage:'выберите один из пакетов',
	// 	spec:'selector'	,
	// 	required: true,
	// 	innerText:'Тип пакета',
	// 	defaultValue: SERVICE_TYPES.scan_shooting_3d,
	// 	currValue: SERVICE_TYPES.scan_shooting_3d,
	// 	optionsWithChildren:[
	// 		{
	// 			option: SERVICE_TYPES.cad_bim_3d,
	// 			children: [SERVICE_TYPES.cad_2d]
	// 		}
	// 	]
	// },
	[PACKAGE_DEAL]:{
		id: PACKAGE_DEAL,
		fieldType:FIELD_TYPE_INPUT,
		options:[
			CAD_BIM_3D.name,
			CAD_2D.name,
			SCAN_SHOOTING_3D.name,
		],
		errorMessage:'выберите один из пакетов',
		spec:'selector'	,
		required: true,
		innerText:'Тип пакета*',
		defaultValue: SERVICE_TYPES.cad_bim_3d,
		currValue: SERVICE_TYPES.cad_bim_3d,
	},
	[STATUS]:{
		id: STATUS,
		fieldType:FIELD_TYPE_INPUT,
		options:[...STATUS_ID_NAME],
		errorMessage:'выберите один из статусов',
		spec:'selector'	,
		required: true,
		defaultValue:STATUS_NEW.description,
		currValue: STATUS_NEW.description,
		innerText:'Статус сделки',
	},
	[SOFTWARE]:{
		id: SOFTWARE,
		fieldType:FIELD_TYPE_INPUT,
		options:[
			'Архикад 21',
			'Ревит 20',
			'Автокад 14'
		],
		errorMessage:'выберите один из версий ПО',
		spec:'selector'	,
		required: true,
		defaultValue:'Архикад 21',
		currValue:'Архикад 21',
		innerText:'Выбрать ПО*'
	},
	[FIRSTNAME]:{
		id: FIRSTNAME,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Имя*',
		type:'text',
		errorMessage:'поле должно содержать от 3 букв без пробелов',
		pattern:'[A-Za-zА-Яа-яЁё]{3,}',
		required: true,
	},
	[LASTNAME]:{
		id: LASTNAME,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Фамилия',
		type:'text',
		errorMessage:'поле должно содержать от 3 букв без пробелов',
		pattern:'[A-Za-zА-Яа-яЁё]{3,}',
		required: false,
	},
	[PHONE]:{
		id: PHONE,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Введите номер телефона*',
		type:'tel',
		mask:'+7(999)-999-99-99',
		required:true,
		errorMessage:'введите номер телефона',
	},
	[EMAIL]:{
		id: EMAIL,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Email',
		type:'email',
		required: false,
	},
	[BRAND]:{
		id: BRAND,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'BRANDNAME*',
		type:'text',
		errorMessage:'заполните поле',
		required: true,
		hint: '(Наименование компании, торговая марка)'
	},
	[INN]: {
		id: INN,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'ИНН*',
		type:'text',
		errorMessage:'ИНН содержит от 10 до 12 цифр',
		pattern:'[0-9]{0,}',
		required: true,
	},
	[BIK]:{
		id: BIK,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'БИК*',
		type:'text',
		errorMessage:'БИК состоит из 9 цифр',
		pattern:'[0-9]{0,}',
		required: true,
	},
	[KPP]:{
		id: KPP,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'КПП*',
		type:'text',
		errorMessage:'КПП состоит из 9 цифр',
		pattern:'[0-9]{0,}',
		required: true,
	},
	[RS]: {
		id: RS,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Р/С*',
		type:'text',
		errorMessage:'Р/С состоит из 20 цифр',
		pattern:'[0-9]{0,}',
		required: true,
	},
	[DOCUMENTS]:{
		id: DOCUMENTS,
		fieldType:FIELD_TYPE_INPUT,
		innerText:'Загрузите файлы',
		type:'file',
		spec:'fileLoader',
		inclDrop:false,
		multiple:true,
		currValue:[],
		fileAccept:`
			.doc,.docx,application/pdf, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*
		`,
		required:false
	},
	position: {
		id: 'position',
		fieldType:FIELD_TYPE_INPUT,
		options:[
			'Менеджер (Сервиса)',
			'Менеджер (Оцифровка)',
			'Менеджер (Моделирования)',
		],
		errorMessage:'выберите один из вариантов',
		innerText:'Выберите должность*',
		currValue:'Менеджер (Сервиса)',
		defaultValue:'Менеджер (Сервиса)',
		spec:'selector'	,
		required: true
	},
	[SMS_PASSWORD]:{
		id: SMS_PASSWORD,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Введите полученный код',
		type:'password',
		required:true,
	},
	tg: {
		id:TG,
		fieldType:FIELD_TYPE_INPUT,
		innerText:'telegram',
		type:'text',
		required: false
	},
	whatsapp: {
		id:WHATSAPP,
		fieldType:FIELD_TYPE_INPUT,
		innerText:'whatsapp',
		type:'text',
		required: false
	},
	[PROJECT_LINK]:{
		id: PROJECT_LINK,
		fieldType:FIELD_TYPE_INPUT,
		required: false,
		innerText:'Ссылка на проект',
	},
	[DEMO_LINK]:{
		id: DEMO_LINK,
		fieldType:FIELD_TYPE_INPUT,
		required: false,
		innerText:'Демо ссылка на проект',
	},
	[PROJECT_DIGITALIZATION_LINK]:{
		id: PROJECT_DIGITALIZATION_LINK,
		fieldType:FIELD_TYPE_INPUT,
		required: false,
		innerText:'Ссылка на проект оцифровки',
	},
	dateSending: {
		id: SENDING_DATE,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Дата сканирования',
		type:'date',
		spec:'date',
		required: false,
	},
	sendLink: {
		id: SEND_LINK,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Отправить ссылку',
		type:'checkbox',
		spec:'checkbox',
		required: false,
	},
	iWillBe: {
		id: I_WILL_BE,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Буду сам/а',
		type:'checkbox',
		spec:'checkbox',
		required: false,

	},
	privacyPolicy:{
		id: PRIVACY_POLICY,
		fieldType:FIELD_TYPE_INPUT,
		// innerText: 'Политика конфиденциальности',
		innerText: <p>Заполняя форму на сайте, вы соглашаетесь с нашей <a href='#' style={{color: '#593794'}}>Политикой конфиденциальности</a></p>,
		errorMessage:'Вы не согласились с условиями конфиденциальности',
		// pattern:true,
		type:'checkbox',
		spec:'checkbox',
		required: true,
	},
	privacyPolicyIsLegal:{
		id: PRIVACY_POLICY_IS_LEGAL,
		fieldType:FIELD_TYPE_INPUT,
		// innerText: 'Политика конфиденциальности',
		innerText: <p>Заполняя форму на сайте, вы соглашаетесь с нашей <a href='#' style={{color: '#593794'}}>Политикой конфиденциальности</a></p>,
		errorMessage:'Вы не согласились с условиями конфиденциальности',
		// pattern:true,
		type:'checkbox',
		spec:'checkbox',
		required: true,
	},
	[SEND_DEAL_INFO]: {
		id: SEND_DEAL_INFO,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Отправить данные о сделке',
		type:'checkbox',
		spec:'checkbox',
		required: false,
	},
	[LEGAL_PERSON]:{
		id:LEGAL_PERSON,
		fieldType:FIELD_TYPE_INPUT,
		innerText: 'Наименование юридического лица*',
		required: true,
	},
	[COMMENTS]:{
		id:COMMENTS,
		fieldType:FIELD_TYPE_INPUT,
		innerText:'Сообщения',
		required:false
	},
	[COMMENT]:{
		id:COMMENT,
		fieldType:FIELD_TYPE_INPUT,
		innerText:'Сообщение',
		required:false
	},
	useBonus:{
		id:'useBonus',
		fieldType:FIELD_TYPE_INPUT,
		innerText:'Оплатить баллами?',
		type:'checkbox',
		spec:'checkbox',
		required:false,
	},
	[IS_LEGAL]:{
		id:IS_LEGAL,
		fieldType:FIELD_TYPE_INPUT,
		innerText:'Я - юридическое лицо',
		type:'checkbox',
		spec:'checkbox',
		required:false,
	}
}
// setFields inside blocks
// !!!!!!!! make form block as a component -> so it has its own state !!!!!!

/**
 * has single field blocks (without title) -> looks like a regular field
 * blockId:{
 * 	blockTitle:null
 * 	fields:[FORM_FIELD[blockId]]
 * }
 * and multiple fields block
 *  blockId:{
 * 	blockTitle:null || smth
 * 	fields:[FORM_FIELD[fieldId_1],FORM_FIELD[fieldId_2],...,FORM_FIELD[fieldId_n]]
 * }
 * all block ids is const type -> written like an object fields(that we use for an app)
 */
const FORM_BLOCKS ={
	[IS_LEGAL]:{
		id: IS_LEGAL,
		blockTitle:'',
		classNames:['isLegal'],
		fields:[
			FORM_FIELDS[IS_LEGAL]
		]
	},
	[COMMENT]:{
		id: COMMENT,
		blockTitle:'Оставьте комментарии к заказу',
		classNames:[],
		fields:[
			FORM_FIELDS.comment
		]
	},
	[ADDRESS]:{
		id:ADDRESS,
		blockTitle:'Введите адрес',
		classNames:['form__block-address'],
		fields:[
			FORM_FIELDS.city,
			// FORM_FIELDS.street,
			// FORM_FIELDS.house,
			// FORM_FIELDS.flat,
			FORM_FIELDS.address
		]
	},
	objectData:{
		id :'objectData',
		blockTitle:'Введите площадь и этажность объекта',
		classNames:[],
		fields:[
			FORM_FIELDS[SQUARE_SELECT],
			FORM_FIELDS[FLOORS_SELECT],
		]
	},
	objectDataVisible:{
		id:'objectDataVisible',
		blockTitle:'Введите данные объекта',
		classNames:[],
		fields:[
			FORM_FIELDS.squareSelect,
			FORM_FIELDS.square,
			FORM_FIELDS.floors
		]
	},
	iWillBe: {
		id:'iWillBe',
		classNames:['form__block-iWillBe'],
		fields:[
			FORM_FIELDS.iWillBe,
		]
	},
	representativeContacts:{
		id:'representativeContacts',
		blockTitle:'Введите контакты на объекте',
		classNames:['form__block-representativeContacts'],
		fields:[
			FORM_FIELDS.representativeName,
			FORM_FIELDS.representativePhone,

		]
	},
	packageDeal:{
		id:'packageDeal',
		blockTitle:'Выберите пакет',
		classNames:[],
		fields:[
			FORM_FIELDS.packageDeal
		]
	},
	[STATUS]:{
		id:STATUS,
		blockTitle:'Выберите статус',
		classNames:[],
		fields:[FORM_FIELDS[STATUS]]
	},
	software:{
		id:'software',
		blockTitle:'Укажите версию ПО',
		classNames:[],
		fields:[
			FORM_FIELDS.software
		]
	},
	desiredDate:{
		id:'desiredDate',
		blockTitle:'Введите желаемую дату и время',
		classNames:[],
		fields:[
			FORM_FIELDS.desiredDate,
			FORM_FIELDS.desiredTime,
		]
	},
	dateSending:{
		id:'dateSending',
		blockTitle:'Введите дату и время выезда',
		classNames:[],
		fields:[
			FORM_FIELDS.dateSending,
			FORM_FIELDS.desiredTime,
		]
	},
	[SEND_LINK]:{
		id:SEND_LINK,
		blockTitle:'Отправить ссылку на оплату клиенту?',
		classNames:['send__link'],
		fields:[
			FORM_FIELDS.sendLink
		]
	},
	[SEND_DEAL_INFO]: {
		id: SEND_DEAL_INFO,
		fieldType:FIELD_TYPE_INPUT,
		blockTitle:'Отправить данные о сделке?',
		classNames:['send__link'],
		fields:[
			FORM_FIELDS[SEND_DEAL_INFO]
		]
	},
	useBonus:{
		id:'useBonus',
		blockTitle:'Оплатить бонусами?',
		classNames:[],
		fields:[
			FORM_FIELDS.useBonus
		]
	},
	finalCost:{
		id:'finalCost',
		blockTitle:'',
		classNames:[],
		fields:[<CreateDealCost key={'finalCost'}/>]
	},
	modalWindowBlock:{
		id:'modalWindowBlock',
		blockTitle:'',
		classNames:[],
		fields:[<ModalWindow/>]
	},
	[DOCUMENTS]:{
		id:DOCUMENTS,
		blockTitle:'Загрузите дополнительные файлы',
		classNames:['form__block-docs'],
		fields:[FORM_FIELDS.documents],
	},
	[LINKS]:{
		id:LINKS,
		blockTitle:'Ссылки на проекты',
		classNames:['form__block-links'],
		fields:[
			FORM_FIELDS[PROJECT_DIGITALIZATION_LINK],
			FORM_FIELDS[DEMO_LINK],
			FORM_FIELDS[PROJECT_LINK],
		],
	},
	[PROJECT_DIGITALIZATION_LINK]:{
		id:PROJECT_DIGITALIZATION_LINK,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[PROJECT_DIGITALIZATION_LINK]],
	},
	[SQUARE]:{
		id:SQUARE,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[SQUARE]],
	},
	[DEMO_LINK]:{
		id:DEMO_LINK,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[DEMO_LINK]],
	},
	[PROJECT_LINK]:{
		id:PROJECT_LINK,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[PROJECT_LINK]],
	},
	[FIRSTNAME]:{
		id:FIRSTNAME,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[FIRSTNAME]],
	},
	[LASTNAME]:{
		id:LASTNAME,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[LASTNAME]],
	},
	[PHONE]:{
		id:PHONE,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[PHONE]],
	},
	[POSITION]:{
		id:POSITION,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[POSITION]],
	},
	[EMAIL]:{
		id:EMAIL,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[EMAIL]],
	},
	[BRAND]:{
		id:BRAND,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[BRAND]],
	},
	[LEGAL_PERSON]:{
		id:LEGAL_PERSON,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[LEGAL_PERSON]],
	},
	[INN]:{
		id:INN,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[INN]],
	},
	[BIK]:{
		id:BIK,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[BIK]],
	},
	[RS]:{
		id:RS,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[RS]],
	},
	[SMS_PASSWORD]:{
		id:SMS_PASSWORD,
		blockTitle:null,
		classNames:[],
		fields:[FORM_FIELDS[SMS_PASSWORD]],
	},
	privacyPolicy:{
		id:PRIVACY_POLICY,
		blockTitle:null,
		classNames:['privacy'],
		fields:[FORM_FIELDS["privacyPolicy"]],
	},
	privacyPolicyIsLegal:{
		id:PRIVACY_POLICY_IS_LEGAL,
		blockTitle:null,
		classNames:['privacy'],
		fields:[FORM_FIELDS["privacyPolicyIsLegal"]],
	},

}

export {
	FORM_FIELDS,
	FORM_BLOCKS
}
