const defaultState ={
	tasks: [],
	totalCount:0,
}
const ADD_TASK = "ADD_TASK";
const RM_TASK = "RM_TASK";
const RM_ALL_TASKS = "RM_ALL_TASKS";
const ADD_MANY_TASKS = 'ADD_MANY_TASKS';
export const ASYNC_ADD_MANY_TASKS = 'ASYNC_ADD_MANY_TASKS';
export const ASYNC_ADD_TASK = "ASYNC_ADD_TASK";
export const ASYNC_RM_TASK = "ASYNC_RM_TASK";
export const tasksReducer = (state = defaultState,  action) => {
	switch(action.type) {
		case ADD_MANY_TASKS:
			return {
				...state, 
				tasks: [...state.tasks, ...action.payload.content], 
				totalCount: action.payload.totalCount
			}
		case ADD_TASK :
			return {
				...state, 
				tasks: [...state.tasks, action.payload.content], 
			}
		case RM_TASK :
			return {
				...state, 
				tasks: state.tasks.filter((c) => c.id !== action.payload),
			}
		case RM_ALL_TASKS:
				return {
					...state,
					tasks:[],
					totalCount:0,
				}
		default:
			return state
	}
}

export const addTaskAction = (payload) => ({type: ADD_TASK, payload}) 
export const asyncAddTaskAction = (payload) => ({type: ASYNC_ADD_TASK, payload}) 
export const addManyTasksAction = (payload) => ({type: ADD_MANY_TASKS, payload}) 
export const asyncAddManyTasksAction = (payload) => ({type: ASYNC_ADD_MANY_TASKS, payload}) 
export const rmTaskAction = (payload) => ({type: RM_TASK, payload}) 
export const asyncRmTaskAction = (payload) => ({type:ASYNC_RM_TASK, payload})
export const rmAllTasksAction = () => ({type: RM_ALL_TASKS}) 