export const LIST_FAQ = [{
	id:'FAQ-1',
	title:'Как вы это делаете?',
	content:`Наземный лазерный сканер снимает 3D фотопанораму, а затем, с помощью импульсного лазерного дальномера производит сканирование (скорость сканирования достигает нескольких миллионов измерений в секунду). Результаты сканирования сохраняются в виде облака точек.
	Фотопанорама и облако точек совмещаются для «окрашивания» модели и придания ей реалистичности.
	В программах обработки облаков точек можно переходить из режима «фото» в режим «облака точек», а также совершать 3D туры и видео облеты.`
},
{
	id:'FAQ-2',
	title:'Сколько это стоит?',
	content:(
	<>
		Рассчитать стоимость решения вашей задачи вы можете, воспользовавшись <a href='https://veso.pro/calculator/' className='project_ref' target='_blank'>калькулятором</a>.
		<br/>
		Наши цены соответствуют уровню цен на традиционные обмерные работы. При этом мы предоставляем на порядок больше информации. Быстрее и точнее.
	</>)
},
{
	id:'FAQ-3',
	title:'Какие объекты вы сканируете?',
	content:(
	<>
		Мы сканируем архитектурные объекты и помещения, любой конфигурации и назначения. 
		<br/>
		Также, мы сканируем конструкции, инженерные сети, производственное оборудование, ландшафт, земляные выработки и многое другое.
	</>)
},
{
	id:'FAQ-4',
	title:'Какова точность измерений?',
	content:<>
		Допустимая погрешность измерений прибора составляет 4 мм на 10 м от точки установки при среднем квадратическом отклонении равном 78% альбедо измеряемой поверхности. Применяемый прибор Leica BLK360 является сертифицированным средством измерения.
	</>
},
{
	id:'FAQ-5',
	title:'Что такое облако точек?',
	content:`Облако точек – это цифровая трехмерная модель объекта, представленная множеством точек с пространственными координатами. Облако точек является результатом лазерного сканирования – измерительной геодезической технологии, измеряющей пространственные координаты точек поверхности объекта.`
},
{
	id:'FAQ-6',
	title:'Как открыть облако точек?',
	content:`Для открытия облака точек существует бесплатное программное обеспечение Autodesk ReCap autodesk.com/products/recap/overview. Большинство продуктов Autodesk: AutoCad, Revit, 3DMax поддерживают загрузку облаков в качестве подложки. Сторонние программы также имеют модули для загрузки облаков точек.`
},
{
	id:'FAQ-7',
	title:'Облако точек не открывается или слишком долго загружается.',
	content:(<ul style={{listStyleType:"decimal", listStylePosition:'inside'}}>
		<li>Закройте посторонние программы. Для комфортной работы с облаками точек требуется большое количество оперативной памяти.</li>
		<li>Обновите все драйвера и проверьте установлена ли у вас последняя версия программного обеспечения.</li>
		<li>Если вопросы остались – пишите/звоните, мы поможем.</li>
	</ul>)
},
{
	id:'FAQ-8',
	title:'В каких случаях сканирование невозможно?',
	content:`Для нормальной работы прибора необходимы:
	- температура воздуха не ниже +5 оC.
	- относительная влажность не выше 90%.`
},
{
	id:'FAQ-9',
	title:'Что влияет на стоимость выполнения работ?',
	content:(<ul style={{listStyleType:"decimal", listStylePosition:'inside'}}>
	<li>Размер объекта: учитывается площадь объекта, его высота.</li>
	<li>Вид работ: в зависимости от поставленной задачи время выполнения работ может меняться.</li>
	<li>Объёмно-планировочные параметры объекта: сканирование пустого небольшого склада потребует меньше времени, чем сканирование такого же по площади многоквартирного дома.</li>
	<li>Заполненность объекта: наличие на объекте строительных материалов, мусора, лесов, оборудования, мебели и т.д.</li>
	<li>Удалённость объекта: если объект находится за пределами КАД.</li>
	<li>Особые условия: необходимость использовать дополнительные машины и механизмы для проведения сканирования.</li>
</ul>)
},
]