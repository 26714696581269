import { STATUS_PAIRS } from "../consts";
import { getSortedOrdersList } from "../ordersUtils/getOrdersList";

export const sortOrders = (orders, userRole) => {
	const sortedOrders = getSortedOrdersList(orders, userRole);
	const sortedArr = []
	for(let i = 0; i < STATUS_PAIRS.length; i++){
		if(!sortedOrders[STATUS_PAIRS[i].statusName]){
			continue;
		}
		let sortedList = [...sortedOrders[STATUS_PAIRS[i].statusName]];
		sortedArr.push({
			orderType: STATUS_PAIRS[i].statusName,
			list: sortedList,
			totalCount:sortedList.length
		})
	}
	return sortedArr
}