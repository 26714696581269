import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormBtn from "../../components/UI/formBtn/FormBtn";
import { API_ROUTE } from "../../utils/consts/ROUTES";


const AlertPage = (props) =>{
	const {title, children, classNames=[], preContent} = props;
	const navigate = useNavigate();
	const {isAuth} = useSelector(state => state.userReducer);
	if(!isAuth){
		classNames.push('notAuth');
	}
	const onClickHandler = () => {
		navigate(`../${API_ROUTE}/orders`)
	}
	return(
		<div className={`container alertPage__wrap ${classNames.join(' ')}`}>
			{preContent}
			<h1 className="title">{title}</h1>
			{children}
			<FormBtn className='alertPage__btn' handleClick={onClickHandler}>Вернуться</FormBtn>
		</div>
	)
}
export default AlertPage;