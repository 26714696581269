import { FORM_FIELDS, MANAGER_DIGITAL_ROLE, MANAGER_MODELLING_ROLE, STATUS_COMPLETED_DIGITIZATION, STATUS_COMPLETED_MODEL } from "../consts";
import { DEMO_LINK, PROJECT_DIGITALIZATION_LINK, PROJECT_LINK } from "../consts/OBJECT_FIELDS";
import { isLink } from "../isLink";
import tempAlert from "../tempAlert";

const canOrderChange = ({role, order}) => {
	switch(role){
		case MANAGER_DIGITAL_ROLE:{
			if(
				order.status === STATUS_COMPLETED_DIGITIZATION.description 
				&& 
				!order[PROJECT_DIGITALIZATION_LINK]
			){
				return {
					flag:false,
					msg:'чтобы изменить статус, введите ссылку на проект'
				}
			}
			break
		}
		case MANAGER_MODELLING_ROLE:{
			if(
				order.status === STATUS_COMPLETED_MODEL.description 
				&& 
				!order[PROJECT_LINK]
			){
				return {
					flag:false,
					msg:'чтобы изменить статус, введите ссылку на проект'
				}
			}
			break
		}
		default:
			return {flag:true}

	}
	console.log(order)
	if(
		order[PROJECT_LINK] && !isLink(order[PROJECT_LINK])
	){
		return {
			flag:false,
			msg:'Ссылка на проект введена в неверном формате'
		}
	}
	if(
		order[DEMO_LINK] && !isLink(order[DEMO_LINK])
	){
		return {
			flag:false,
			msg:'Демо ссылка введена в неверном формате'
		}
	}
	if(
		order[PROJECT_DIGITALIZATION_LINK] && !isLink(order[PROJECT_DIGITALIZATION_LINK])
	){
		return {
			flag:false,
			msg:'Ссылка на проект оцифровки введена в неверном формате'
		}
	}
	
	return {flag:true}
}

export const orderChangeCheck = ({order,role}) =>{
	const canChange = canOrderChange({order,role});
	if(!canChange.flag){
		tempAlert(canChange.msg, 3000)
	} 
	return canChange.flag;
}
