import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fab } from "@fortawesome/free-brands-svg-icons";
import {faR, fas} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(fab, fas, faR)
/**
 * component based on FA icons
 * structure of an imageLink
 * id:link_id,
 * ref:link_reference,
 * icon:fa_icon-name,
 * content:added_content(f.e. image-name),
 */
const ImageLinks = ({imageItems}) => {
	const download = (url, name) => {
		if (!url) {
		  throw new Error("Resource URL not provided! You need to provide one");
		}
		fetch(url)
		  .then(response => response.blob())
		  .then(blob => {
			const blobURL = URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = blobURL;
			a.style = "display: none";

			if (name && name.length) a.download = name;
			document.body.appendChild(a);
			a.click();
		  })
		  .catch(() => {});
	  };
	return(
		<>
			<div className='phone__number'>
				<div style={{fontSize: '20px', padding: '10px'}}>
					<span>СПБ </span><a href="tel:+78126605210"> +7 (812) 660-52-10</a>
				</div>
				<div style={{fontSize: '20px', padding: '10px 10px 20px 10px'}}>
					<span>МСК </span><a href="tel:+74994905210"> +7 (499) 490-52-10</a>
				</div>
			</div>

			<div className='imageLinks__container'>

				{
					imageItems.map(image =>(
						<div className='imageLinks__item-container' key={`${image.id}-${Math.random()*100}`}>
							<a
								download={image?.download}
								className='imageLinks__item-ref'
								href={image?.ref}
								target="_blank"
							>
								<FontAwesomeIcon className={`image-${image.id}`} icon={image.icon}/>
								{
									image?.content
								}
							</a>

						</div>
					))
				}
			</div>
		</>
	)
}
export default ImageLinks;
