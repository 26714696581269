import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertPage from "../../AlertPage/AlertPage";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
const Failure = (props) => {
	const {title, text} = props
	return(
		<AlertPage
			title={title}
			classNames = {['failure']}
		>
			<FontAwesomeIcon className='image-failure alertPage__image' icon={faTimesCircle}/>
			<p className="alertPage__text">
				{text}
			</p>
		</AlertPage>
	)
}
export default Failure;
