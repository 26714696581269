import CreateDeal from "./CreateDeal/CreateDeal"

const AddedFieldsSpec = (props) => {
	const {spec, setModalVisible, setFormPath} = props
	return(
		
		<>
			{
				spec === 'CREATE_DEAL'
				?<CreateDeal 
					setModalVisible={setModalVisible} 
					setFormPath={setFormPath}
				/>
				:''
			}
		</>
	)
}
export default AddedFieldsSpec