import { $api }  from './index';
import { SERVER_ROUTES, STATUS_COMPLETED, STATUS_FUNDED, STATUS_ID_SERVER_NAME, STATUS_PREPAID, USER_ROUTE } from '../utils/consts';
import { getStatusObject } from '../utils/getStatusId';
import randFieldIdGen from '../utils/randFieldIdGen';
import { getByLimitAndPage, getOrders } from '../utils/getMock';
//const orderArray = [...getOrders()];
export const fetchDeals = async (props) => {
	const { limit, page, sort_by, filter, userId } = props
	//return orderArray
	const { data } = await $api.get(SERVER_ROUTES.dealsGet, {
		params:{
			limit,
			page,
			sort_by,
			userId,
			filter
		}
	});
	return data.data
}

export const addDealComment = async (props) => {
	const {dealId, comment} = props;
	// return {
	// 	success:0, 
	// 	data:{
	// 		id:randFieldIdGen(3),
	// 		...props, 
	// 		userInfo:{
	// 			id:1, 
	// 			firstname:'a',
	// 			lastname:'a',
	// 			role:'MANAGER_DIGITAL'
	// }}}
	const { data } = await $api.post(SERVER_ROUTES.commentCreate, {
		dealId,
		comment
	})
	return data
}
export const rmDealComment = async (props) => {
	const {id} = props;
	// return {
	// 	success:0, 
	// 	data:{}
	// }
	const { data } = await $api.delete(SERVER_ROUTES.commentDelete,{
		params:{
			id
		}
	})
	return data
}
export const fetchDealUserInfo = async (props) => {
	const {orderId} = props
	const { data } = await $api.get(SERVER_ROUTES.usersGet, {
		params:{
			deal_id: orderId
		}
	});
	return data.data
	// const data = [
	// 	{
	// 		id:Math.floor(Math.random()*200),
	// 		role:'USER',
	// 		email:'aaaa',
	// 		firstname:'aaaa',
	// 		lastname:'bbbb',
	// 		phone:'+79998291829'
	// 	},
	// 	{
	// 		id:Math.floor(Math.random()*200),
	// 		role:'MANAGER',
	// 		email:'aaaa',
	// 		firstname:'manager',
	// 		lastname:'bbbb',
	// 		phone:'+799'
	// 	},
	// 	{
	// 		id:Math.floor(Math.random()*200),
	// 		role:'MANAGER_DIGITAL',
	// 		email:'aaaa',
	// 		firstname:'digital',
	// 		lastname:'bbbb',
	// 		phone:'+7922222222'
	// 	},
	// 	{
	// 		id:Math.floor(Math.random()*200),
	// 		role:'MANAGER_MODELLING',
	// 		email:'aaaa',
	// 		firstname:'modelling',
	// 		lastname:'bbbb',
	// 		phone:'+7991829'
	// 	},
	// 	{
	// 		id:Math.floor(Math.random()*200),
	// 		role:'MANAGER_MODELLING',
	// 		email:'aaaa',
	// 		firstname:'modelling',
	// 		lastname:'bbbb',
	// 		phone:'+7991829'
	// 	},
	// 	{
	// 		id:Math.floor(Math.random()*200),
	// 		role:'MANAGER_MODELLING',
	// 		email:'aaaa',
	// 		firstname:'modelling',
	// 		lastname:'bbbb',
	// 		phone:'+7991829'
	// 	},
	// 	{
	// 		id:Math.floor(Math.random()*200),
	// 		role:'MANAGER_MODELLING',
	// 		email:'aaaa',
	// 		firstname:'modelling',
	// 		lastname:'bbbb',
	// 		phone:'+7991829'
	// 	},
	// ]
	// console.log(data)
	// return data
}
export const addDealItem = async (props) => {
	const {
		id = null,
		status = null,
		userInfo=null,
		cost=null,
		advancePayment=null,
		restPayment=null,
		projectLink=null,
		demoLink=null,	
		house=null,
		city=null,
		flat=null,
		street=null,
		desiredDate=null,
		representativeName=null,
		representativePhone=null,
		packageDeal=null,
		dateSending=null,
		square = null,
		floors = null,
		software = null,
		paymentLink = null,
		desiredTime=null,
		bonusPoints=null,
		//payMethod=null,
		comments =[],
		documents=[],
		address =null,
		sendLink=null,
		userId=null
	} = props
	// return {data:{
	// 	id,
	// 	status,
	// 	userInfo,
	// 	cost,
	// 	advancePayment,
	// 	restPayment,
	// 	projectLink,
	// 	demoLink,
	// 	city,
	// 	desiredDate,
	// 	representativeName,
	// 	representativePhone,
	// 	packageDeal,
	// 	dateSending,
	// 	square,
	// 	floors,
	// 	software,
	// 	paymentLink,
	// 	desiredTime,
	// 	bonusPoints,
	// 	payMethod,
	// 	comments ,
	// 	documents,
	//
	// 	address,
	// 	sendLink,
	// 	userId
	// }, success:0}
	const { data } = await $api.post(SERVER_ROUTES.dealCreate, {
		id,
		status,
		userInfo,
		cost,
		advancePayment,
		restPayment,
		projectLink,
		demoLink,
		city,
		desiredDate,
		representativeName,
		representativePhone,
		packageDeal,
		dateSending,
		square,
		floors,
		software,
		paymentLink,
		desiredTime,
		bonusPoints,
		//payMethod,
		comments ,
		documents,
		address,
		sendLink,
		userId
	});
	//console.log(data)
	return data;
}
export const updateDealItem = async (props) => {
	const {
		id,
		status,
		userInfo,
		cost,
		advancePayment,
		restPayment,
		projectLink,
		demoLink,
		city,
		desiredDate,
		representativeName,
		representativePhone,
		packageDeal,
		dateSending,
		square,
		floors,
		software,
		paymentLink,
		desiredTime,
		bonusPoints,
		//payMethod,
		comments ,
		documents,
		digitalizationLink,
		address,
		sendLink,
		sendDealInfo
		//userId
	} = props
	//return {success:0, data:{...props}}
	const { data } = await $api.put(SERVER_ROUTES.dealUpdate + id,{ 
		id,
		status,
		userInfo,
		cost,
		advancePayment,
		restPayment,
		projectLink,
		demoLink,
		city,
		desiredDate,
		representativeName,
		representativePhone,
		packageDeal,
		dateSending,
		square,
		floors,
		software,
		paymentLink,
		desiredTime,
		bonusPoints,
		//payMethod,
		comments ,
		documents,
		digitalizationLink,
		address,
		sendLink,
		sendDealInfo
		//userId
	});
	//const { data } = await $api.put(USER_ROUTE.path, {dataItem, userId, dataName});
	return data
}
export const rmDealItem = async (props) => {
	const {id} =props
	const { data } = await $api.delete(SERVER_ROUTES.dealDelete + id,{
		params:{
			id,
		},
	})
	return data
}

export const getPaymentLink = async(props) => {
	const {id, useBonus=false, payMethod} = props;
	// console.log({id, useBonus, payMethod: payMethod[0]})
	// return {success:0, data:{
	// 	id: id,
	// 	link:'https://stackoverflow.com/questions/42914666/react-router-external-link',
	// 	order:{...orderArray[0]},
	// 	...props
	// }}
	const { data } = await $api.get(SERVER_ROUTES.getLink,{
		params: {
			id,
			useBonus,
			payMethod
		},
	})
	return data;
}
export const getPaymentInfo = async (props) => {
	const { id } = props
	// return {success:0, data:{
	// 	id: id,
	// 	//link:'https://stackoverflow.com/questions/42914666/react-router-external-link',
	// 	order:{...orderArray[0]},
	// 	...props
	// }}
	const { data } = await $api.get(SERVER_ROUTES.getPaymentInfo,{
		params: {
			id
		},
	})
	return data;
}
export const payBonus = async (props) => {
	const {id} = props;
	// get updated deal
	//return id
	const { data } = await $api.post(SERVER_ROUTES.payBonus,{
		id
	})
	return data;
}
