import { useEffect } from "react";
import { addZero } from "../../../../../utils/addZero";

const TimeInput = (props) => {
	const {
		requiredProps,
		setInputFilled,
		//input classes
		inputRoot,
	} = props

	const {
		id,
		inputType,
		value,
		setValue,
		required,
		disabled,
		innerText,
	} = requiredProps;

	const getDefaultValue = () => {
		let curr = new Date();
		const res = `${addZero(curr.getHours())}:${addZero(curr.getMinutes())}`
		return res
	}
	// useEffect(()=>{
	// 	console.log(value)
	// 	if(!value){
	// 		setValue(getDefaultValue())
	// 	}
	// },[])
	return(
		<>
			{/*<input */}
			{/*	className={`${inputRoot.join(' ')} form__field--time`}*/}
			{/*	type='time'*/}
			{/*	value={value}*/}
			{/*	//value='01:00'*/}
			{/*	onChange={(e) => setValue(e.target.value)}*/}
			{/*	id={id}*/}
			{/*	disabled={disabled}*/}
			{/*	required={required}*/}
			{/*/>*/}

			<select
				className={`${inputRoot.join(' ')} form__field--time`}
				onChange={(e) => setValue(e.target.value)}
				id={id}
				required
				value={value}
			>
				<option selected disabled>--:--</option>
				<option value="08:00">08:00</option>
				<option value="09:00">09:00</option>
				<option value="10:00">10:00</option>
				<option value="11:00">11:00</option>
				<option value="12:00">12:00</option>
				<option value="13:00">13:00</option>
				<option value="14:00">14:00</option>
				<option value="15:00">15:00</option>
				<option value="16:00">16:00</option>
				<option value="17:00">17:00</option>
				<option value="18:00">18:00</option>
				<option value="19:00">19:00</option>
				<option value="20:00">20:00</option>
			</select>

		</>
	)
}
export default TimeInput
