const defaultState ={
	list:[]
}
const ADD_CONTENT = 'ADD_CONTENT';
const ADD_CONTENT_ITEM = 'ADD_CONTENT_ITEM';
const ADD_MANY_CONTENT_ITEM = 'ADD_MANY_CONTENT_ITEM';
const RM_CONTENT_ITEM = 'RM_CONTENT_ITEM';
const UPDATE_CONTENT_ITEM='UPDATE_CONTENT_ITEM';
const RM_ALL_CONTENT = 'RM_ALL_CONTENT';

export const dataItemContentReducer = (state = defaultState,  action) => {
	switch(action.type) {
		case ADD_CONTENT_ITEM:
			return {
				...state, 
				list:[...state.list, action.payload.listItem],
			
			}
		case ADD_MANY_CONTENT_ITEM:
			return {
				...state, 
				list:[...state.list, ...action.payload.list],				
			}
		case UPDATE_CONTENT_ITEM:
			const id = action.payload.listItem.id;
			let indexOfItem = state.list.map( item => item.id).indexOf(id);
			let updList = [...state.list];
			const item = {
				id,
				title: action.payload.listItem.title,
				description: action.payload.listItem.description,
			}
			updList[indexOfItem] = {...item};
			return {
				...state, 
				list:  [...updList]				
			}
		case RM_CONTENT_ITEM: 
			return {
				...state, 
				list: state.list.filter((c) => c.id !== action.payload),
			}
		case RM_ALL_CONTENT:
				return{
					...state, 
					list:[],
				}
		default: 
			return state;
		
	}
}
//export const addDataAction = (payload) => ({type: ADD_DATA, payload});

export const addContentItemAction = (payload) => ({type:ADD_CONTENT_ITEM, payload});
export const addManyContentItemAction = (payload) => ({type:ADD_MANY_CONTENT_ITEM, payload});
export const updateContentItemAction = (payload) => ({type:UPDATE_CONTENT_ITEM, payload});
export const rmContentItemAction = (payload) => ({type:RM_CONTENT_ITEM, payload});
export const rmAllContentAction = () => ({type:RM_ALL_CONTENT});