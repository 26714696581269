import { DESIRED_TIME, ID, STATUS, ADDRESS} from "../../../../../../utils/consts/OBJECT_FIELDS"

const OrderItemTableCalendar = ({order, classes=[]}) => {
	return(
		<ul className = {`content-list--table content-list--table--calendar ${classes.join(' ')}`}>
			<li className = 'content-list--table__item'>
				№ {order.getValue(ID)}
			</li>
			<li className = 'content-list--table__item'>
				Адрес: {order.getValue(ADDRESS)}
			</li>
		</ul>
)
}
export default OrderItemTableCalendar
