import User from "./classes/User";
export const formatUsers = (users=[]) => {
	const finalArr = []
	for(let i = 0; i<users.length; i++){
		try{
			let userOrder = new User({...users[i]}) //formatOrder({...orders[i]})
			finalArr.push(userOrder)
		}catch(e){
			console.log(e)
		}
	}
	return finalArr;
} 