import { useDispatch, useSelector } from "react-redux";
import actions from "../store/reducers/actions";
import { formatFields } from "../utils/formatFields";
import { FIELD_TYPE_INPUT, FORM_BLOCKS, FORM_FIELDS } from "../utils/consts/FORM_FIELDS";
import FormField from "./UI/FormField/FormField";
import { useEffect } from "react";
import { BLOCK_TYPE, FormModel, FORM_TYPES } from "../utils/classes/Form";
import { PHONE } from "../utils/consts/OBJECT_FIELDS";
const FormComponent = (props) => {
	const {
		formType='CREATE_DEAL_FORM',
		existingValues=[],// values, that are need to be currValues by default(changeInfo form) -> pairs [id, value]
		children,
		className,
		formClassNames=[],
		formPath='',
		blocks=[]
	} = props
	const dispatch = useDispatch();
	const form = useSelector(state => state.formReducer);
	const {user} = useSelector(state => state.userReducer);
	const getContainerClasses = () => {
		const containerClasses = [];
		for(let i=0; i<formClassNames?.length; i++){
			containerClasses.push(formClassNames[i] + '-container')
		}
		return containerClasses;
	}
	/**
	 * form fields formFields
	 * [pos]-{ blockTitle:'', formFields:[]}
	 */
	const dispatchFields = async () => {
		//gettingRawFields
		//dispatch(actions.refreshFormAction())
		//dispatch(actions.rmAllFieldsAction())
		let formModel = {...FORM_TYPES[formType]}
		let formModelBefore = {...formModel}
		if(blocks?.length <= 0){
			//
			if(formPath !== ''){
				formModel.setFieldsByModificator(formPath)
			} else{
				formModel.setFieldsByModificator(user.role)
			}
			//updating each field
			//await formModel?.updateField(fieldId, {currValue})
			let formTest = {...formModel};
			existingValues.map((el) => {
				if (el[0] === FORM_BLOCKS.dateSending.id && !el[1]){
					existingValues.map((element) => {
						if(element[0] === FORM_BLOCKS.desiredDate.id){
							console.log(element[1])
							el[1]  = element[1]
						}
					})
				}

			})
			for(let i=0; i<existingValues?.length; i++){
				let fieldId = existingValues[i][0];
				let currValue = existingValues[i][1];
				await formModel?.updateField(fieldId, {currValue})
			}
		} else{
			formModel.blocks = [...blocks]
		}
		const fieldsObj =  formatFields(
			{
				formType:BLOCK_TYPE, formFields:[...formModel.blocks]
			}
		)

		dispatch(actions.addFieldManyAction({...fieldsObj}));
		dispatch(actions.setFormAction(
			{
				title:formModel?.title,
				blocks:[...formModel?.blocks]
			}
		))
	}

	useEffect(()=>{
		dispatch(actions.rmAllFieldsAction())
		dispatch(actions.refreshFormAction())
		dispatchFields()
	},[formType, formPath])
	useEffect(()=>{
		dispatchFields();
		return(()=>{
			dispatch(actions.refreshFormAction())
			dispatch(actions.rmAllFieldsAction())
		})
	},[])
	return(
		<div className={`${getContainerClasses().join(' ')} form__container`}>
			<div className='form__header'>
				<h3 className = "form__title"> {form.title.name}</h3>
				<br/>
				<p className="form__info">{form.title.info}</p>
			</div>
			<form className={`form ${formClassNames.join(' ')}`} method="post">
				{
					!props.beforeFields
					? <></>
					:props.beforeFields
				}
				{
					form?.blocks.map((block) => {
						if(block){
						return <div className={`form__block ${block?.classNames.join(' ')}`} key={block.id}>
							{
								block?.blockTitle
								?(
									<h4 className="form__block-title">
										{block.blockTitle}
									</h4>

								)
								:<></>
							}
							<div className=' form__block-fields'>
								{
									block?.fields.length > 0
									? block?.fields.map((field) =>{
										if(field.fieldType === FIELD_TYPE_INPUT){
												return <FormField
													id={field.id}
													key={field.id}
													inputType={field.type}
													{...field}
													/>
											}
										return field
									})
									:<></>
								}
							</div>
						</div>
						}
						else return <></>
					})
				}
				{children}
			</form>
		</div>
	)
}
export default FormComponent;
