import { ADMIN_ROLE, MANAGER_ROLE, MANAGER_ROLES } from "../consts/ROLES"

export const isManager = (role) => {
	
	for(let i=0; i< MANAGER_ROLES.length; i++){
		if(role === MANAGER_ROLES[i]){
			return true
		}
	}
	return false
}
export const isAdmin = (role) => {
	if(role === ADMIN_ROLE || role===MANAGER_ROLE){
		return true
	}
	return false
}