import {STATUS_AWAITING_PAYMENT, STATUS_COMPLETED_MODEL} from "./consts";

const canPay = (status) => {
	if(
		status !== STATUS_AWAITING_PAYMENT.statusName
		&&
		status !== STATUS_COMPLETED_MODEL.statusName
	){
		return false
	}
	return true;
}
export default canPay;