import { BLOCK_TYPE, RAW_FIELDS_TYPE } from "../consts/FORM_FIELDS";

const getFields = ({FORM_TYPE, FORM_FIELDS }) => {
	if(FORM_TYPE === BLOCK_TYPE){
		const blockFields = []
		for(let i=0; i<FORM_FIELDS.length;i++){
			for(let j=0; j<FORM_FIELDS[i].fields.length;j++){
				blockFields.push(FORM_FIELDS[i].fields[j])
			}
		}
		return [...blockFields]
	} else if(FORM_TYPE === RAW_FIELDS_TYPE){
		return [...FORM_FIELDS]
	}
}
export default getFields;