const API_ROUTE = ''
const HOME_ROUTE = {
	path:`${API_ROUTE}/home`,
	name: 'Главня страница'
};
const ABOUT_ROUTE = 'api/about';
const USER_ROUTE = {
	path:`${API_ROUTE}/user`,
	name:	'Профиль'
}
const USER_PROFILE_ROUTE = {
	path:`${USER_ROUTE.path}/profile`,
	name: 'Профиль'
}
const REGISTER_ROUTE = {
	path: `${API_ROUTE}/register`,
	name:'Регистрация'
}
const REGISTER_ADMIN_ROUTE = {
	path: `${REGISTER_ROUTE.path}/admin`,
	name:'Регистрация администратора'
}
const REGISTER_MANAGER_ROUTE = {
	path: `${REGISTER_ROUTE.path}/manager`,
	name: 'Регистрация менеджера'
}
const REGISTER_CLIENT_ROUTE = {
	path: `${REGISTER_ROUTE.path}/client`,
	name: 'Регистрация клиента'
}
const REGISTER_CLIENT_DEAL_ROUTE = {
	path: '/deals/create/client',
	name: 'Регистрация клиента'
}
const LOGIN_ROUTE = {
	path: `${API_ROUTE}/login`,
	name:'Войти'
}
const ORDERS_ROUTE = {
	path: `${API_ROUTE}/orders`,
	name:'Мои заказы'
}
const ORDER_CURRENT_ROUTE = {
	path: `${ORDERS_ROUTE.path}/current`,
	name:'Текущий заказ'
}
const ORDER_ROUTE = {
	path: `${ORDERS_ROUTE.path}/:id`,
	name:'Заказ'
}
const ORDER_CREATE_ROUTE = {
	path: `${ORDERS_ROUTE.path}/create`,
	name:'Создать заказ'
}
const QA_ROUTE = {
	path: `${API_ROUTE}/qa`,
	name:'Вопрос-Ответ'
}
const OFFER_ROUTE = {
	path: `${API_ROUTE}/offer`,
	name:'Оферта'
}
const LOYALITY_ROUTE = {
	path: `${API_ROUTE}/loyality`,
	name:'Система лояльности'
}

const DEALS_ROUTE = {
	path: `${API_ROUTE}/deals`,
	name:'Сделки'
}
const DEAL_ROUTE = {
	path: `${DEALS_ROUTE.path}/:id`,
	name:'Сделка'
}
const DEALS_DONE_ROUTE = {
	path: `${DEALS_ROUTE.path}/done`,
	name:'Завершённые сделки'
}
export const DEALS_CANCELED_ROUTE = {
	path: `${DEALS_ROUTE.path}/canceled`,
	name:'Отмененные сделки'
}
const DEAL_DONE_ROUTE = {
	path: `${DEALS_ROUTE.path}/done/:id`,
	name:'Завершённая сделка'
}
export const ORDER_PAYMENT_ROUTE = {
	path: `${ORDERS_ROUTE.path}/payment/:id`,
	name:'Оплата заказа'
}
const CLIENTS_ROUTE = {
	path:`${API_ROUTE}/clients`,
	name:'Список клиентов'
}
const CLIENT_ROUTE = {
	path: `${CLIENTS_ROUTE.path}/:id`,
	name:'Клиент'
}
const MANAGERS_ROUTE = {
	path:`${API_ROUTE}/managers`,
	name:'Список менеджеров'
}
const MANAGER_ROUTE = {
	path: `${MANAGERS_ROUTE.path}/:id`,
	name:'Менеджер'
}
const CALENDAR_ROUTE = {
	path: `${API_ROUTE}/calendar`,
	name:'Календарь'
}
const ANALYTICS_ROUTE = {
	path: `${API_ROUTE}/analytics`,
	name: 'Аналитика'
}
const SMS_DISTRIBUTION_ROUTE = {
	path: `${API_ROUTE}/smsDistribution`,
	name: 'Смс рассылка'
}
const PAYMENT_ROUTE = {
	path: `${API_ROUTE}`,
	name: 'Страница оплаты'
}
const PAYMENT_SUCCESS_ROUTE ={
	path: `${PAYMENT_ROUTE.path}/success`,
	name: 'Успешная оплата'
}
const PAYMENT_FAILURE_ROUTE ={
	path: `${PAYMENT_ROUTE.path}/failure`,
	name: 'Неуспешная оплата'
}
const REACT_APP_API_URL = 'https://api.lk.veso.pro/api'//http://localhost:3001/api'http://5.181.109.98:3001/api';//'http://192.168.1.70:7222';//'http://5.181.109.98:3001/api' 'https://lk.veso.pro/api';

const SERVER_ROUTES = {
	getLink:'/link',
	getPaymentInfo:'/orders/payment',
	payBonus:'/orders/payment/bonus',
	register:'/user/registration',
	checkCode:'/user/checkcode',
	login:'/user/login',
	logout:'/user/logout',
	refresh:'/refresh',
	dealsGet:'/deals',
	dealUserInfoGet:'/users/:id',
	usersGet:'/users',
	managersGet:'/managers',
	dealCreate:'/newdeal',
	dealUpdate:'/deal/update/',
	dealDelete:'/deal/delete/',
	commentDelete:'/comment/delete',
	commentUpdate:'/comment/update',
	commentCreate:'/comment/create',
	userUpdate:'/user/update',
	userDelete:'/user/delete',
	userAdminUpdate:'/useradmin/update/:id',
	userAdminDelete:'/useradmin/delete/:id',

}
const ALL_ROUTES = [
	MANAGERS_ROUTE,
	MANAGER_ROUTE,
	DEALS_CANCELED_ROUTE,
	HOME_ROUTE,
	ABOUT_ROUTE,
	USER_ROUTE,
	REGISTER_ROUTE,
	LOGIN_ROUTE,
	ORDERS_ROUTE,
	ORDER_CURRENT_ROUTE,
	ORDER_CREATE_ROUTE,
	LOYALITY_ROUTE,
	DEAL_ROUTE,
	DEALS_ROUTE,
	DEALS_DONE_ROUTE,
	DEAL_DONE_ROUTE,
	CLIENTS_ROUTE,
	CLIENT_ROUTE,
	CALENDAR_ROUTE,
	REGISTER_ADMIN_ROUTE,
	REGISTER_MANAGER_ROUTE,
	REGISTER_CLIENT_ROUTE,
	ANALYTICS_ROUTE,
	QA_ROUTE,
	OFFER_ROUTE,
	SMS_DISTRIBUTION_ROUTE,
	ORDER_ROUTE,
	REGISTER_CLIENT_DEAL_ROUTE,
	USER_PROFILE_ROUTE,
	ORDER_PAYMENT_ROUTE
]
const ROUTES = {
	REGISTER_CLIENT_DEAL_ROUTE,
	ORDER_ROUTE,
	SERVER_ROUTES,
	HOME_ROUTE,
	ABOUT_ROUTE,
	USER_ROUTE,
	REGISTER_ROUTE,
	LOGIN_ROUTE,
	ORDERS_ROUTE,
	ORDER_CURRENT_ROUTE,
	ORDER_CREATE_ROUTE,
	LOYALITY_ROUTE,
	DEAL_ROUTE,
	DEALS_ROUTE,
	DEALS_DONE_ROUTE,
	DEAL_DONE_ROUTE,
	CLIENTS_ROUTE,
	CLIENT_ROUTE,
	CALENDAR_ROUTE,
	REGISTER_ADMIN_ROUTE,
	REGISTER_MANAGER_ROUTE,
	REGISTER_CLIENT_ROUTE,
	ANALYTICS_ROUTE,
	QA_ROUTE,
	OFFER_ROUTE,
	SMS_DISTRIBUTION_ROUTE,
	REACT_APP_API_URL,
	ALL_ROUTES
}
 export {
	MANAGERS_ROUTE,
	MANAGER_ROUTE,
	API_ROUTE,
	PAYMENT_FAILURE_ROUTE,
	PAYMENT_SUCCESS_ROUTE,
	REGISTER_CLIENT_DEAL_ROUTE,
	ORDER_ROUTE,
	SERVER_ROUTES,
	HOME_ROUTE,
	ABOUT_ROUTE,
	USER_ROUTE,
	REGISTER_ROUTE,
	LOGIN_ROUTE,
	ORDERS_ROUTE,
	ORDER_CURRENT_ROUTE,
	ORDER_CREATE_ROUTE,
	LOYALITY_ROUTE,
	DEAL_ROUTE,
	DEALS_ROUTE,
	DEALS_DONE_ROUTE,
	DEAL_DONE_ROUTE,
	CLIENTS_ROUTE,
	CLIENT_ROUTE,
	CALENDAR_ROUTE,
	REGISTER_ADMIN_ROUTE,
	REGISTER_MANAGER_ROUTE,
	REGISTER_CLIENT_ROUTE,
	ANALYTICS_ROUTE,
	QA_ROUTE,
	OFFER_ROUTE,
	SMS_DISTRIBUTION_ROUTE,
	REACT_APP_API_URL,
	ALL_ROUTES
}
