import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { ADMIN_ROLE, MANAGER_DIGITAL_ROLE, MANAGER_MODELLING_ROLE, MANAGER_ROLE, MANAGER_SECONDARY_ROLE, STATUS_COMPLETED,STATUS_ID_FIELD, STATUS_ID_NAME, STATUS_NAMES, STATUS_PAIRS } from "../../../../utils/consts";
import classes from './ManagerOrders.module.css'
import OrderItem from "../../OrderItem/OrderItem";
import { fetchDeals } from "../../../../http/dealAPI";
import { useFetching } from "../../../../hooks/useFetching";
import { formatOrders } from "../../../../utils/formatOrders";
import actions from "../../../../store/reducers/actions";
import { getSortedList } from "../../../../utils/getSortedList";
import Loader from "../../loader/Loader";
import { DispatchManagerOrders, dispatchManagerOrders } from "../../../../utils/dispatchUtils/dispatchManagerOrders";
import { getOrdersList, getSortedOrdersList } from "../../../../utils/ordersUtils/getOrdersList";
import { getStatusIdByField, getStatusObject } from "../../../../utils/getStatusId";
import { getStatusObjByRole } from "../../../../utils/statusUtils/getStatusByRole";
import { sortOrders } from "../../../../utils/dispatchUtils/sortOrders";
import User from "../../../../utils/classes/User";
import { STATUS_CANCELED } from "../../../../utils/consts/STATUS";
import ManagerOrdersList from "./ManagerOrdersList/ManagerOrdersList";
const ManagerOrders = () => {
	const dispatch = useDispatch();

	//const orders = useSelector(state => state.dataReducer['orders']);
	const ordersReducer =  useSelector(state => state.ordersReducer);
	const user = useSelector(state => state.userReducer.user)
	const role = user.role
	//const ordersLists = orders.list;// orderDone modificator === orders.list.length
	const getOrderComponentByStatusId = (statusId) => {
		const statusObj = getStatusObject({id:statusId})
		if(
			statusObj.statusName === STATUS_COMPLETED.statusName 
			||
			statusObj.statusName === STATUS_CANCELED.statusName 
			//&& (role !== ADMIN_ROLE && role !== MANAGER_ROLE) добавить "выполненные" для админа
		){
			return;
		}
		//add orderComponent to other folder
		const list = ordersReducer[statusObj.statusName].list
		const headerName = statusObj.description;// get name by statusId
		return(
			<div className={classes["managerOrders__list-container"] + ' ' +classes[`managerOrders__list-container--${statusObj.statusName}`] }>
				<div className={classes['managerOrders__list-title-container']}>
					<h3 className={classes['managerOrders__list-title']}>
						{headerName}
					</h3>
				</div>
				<div className={classes[`managerOrders__list--${statusObj.statusName}`] + ' ' + classes['managerOrders__list']}>
					{
						list.map(listItem => {
							return (
								<OrderItem 
									key ={listItem.id} 
									order={listItem} 
									className={classes['orderItem']}
								/>
							)
						})
					}
				</div>
			</div>
		)
	}
	const getManagerOrders = (role) => {
		const statusObjects = getStatusObjByRole(role);
		return(statusObjects.map(statusObj => {
					if(
						statusObj.statusName === STATUS_COMPLETED.statusName 
						||
						statusObj.statusName === STATUS_CANCELED.statusName 
						//&& (role !== ADMIN_ROLE && role !== MANAGER_ROLE) добавить "выполненные" для админа
					){
						return <></>;
					}
					return <ManagerOrdersList 
						key={statusObj?.statusName}
						list={ordersReducer[statusObj.statusName].list} 
						statusObj={statusObj} 
					/>
				}
			)
		)
		/*for(let i=0; i < statusObjects.length; i++){
			ordersToShow.push(
				getOrderComponentByStatusId(statusObjects[i].id)
			);
		}
		return(
			ordersToShow.map(order => order)
		)*/
	}
	const [fetchOrders, isOrdersLoading, error] = useFetching(async () => {
		const response = await fetchDeals({});
		const orders = formatOrders(response);
		const sortedOrders = sortOrders(orders, role);
		for(let i = 0; i<sortedOrders.length; i++){
			dispatch(actions.addOrderAction({...sortedOrders[i]}))
		}
		//const userObj = new User({...user.convertedServer, orders:[...orders],currentOrder:(orders[orders.length-1].id)})
		//dispatch(actions.setUserAction(userObj))
	})
	useEffect(()=>{
		fetchOrders()
		return ()=> {
			dispatch(actions.clearOrdersAction())
			//const userObj = new User({...user.convertedServer, orders:[]})
			//dispatch(actions.setUserAction(userObj))
		}
	},[])
	return(
		<div className={classes['managerOrders__wrap']}>
			<div className={classes["managerOrders__container"]}>
				<div className={classes["managerOrders__content"]}>
					{
						isOrdersLoading
						?<div style={{position:'fixed', display: 'flex', justifyContent: 'center',height:'100%', width:'100%'}}><Loader/></div> 
						:getManagerOrders(role)
					}
				</div>
			</div>
		</div>
	)
}
export default ManagerOrders