import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../store/reducers/actions";
import classes from './DataTemplate.module.css';
import FormBtn from "../formBtn/FormBtn";
import Loader from '../loader/Loader'
import { getPageCount } from "../../../utils/pages";
import DataTemplateList from "./DataTemplateList/DataTemplateList";
import { DATA_NAMES } from "../../../utils/consts";
import { formatUsers } from "../../../utils/formatUsers";
import { useFetching } from "../../../hooks/useFetching";
import { fetchManagers, fetchUsers } from "../../../http/usersAPI";

const DataTemplate = (props) => {
	const {dataName='',queryParams, listType='', modification=''} = props
	// if !queryParams -> no Query
	const dispatch = useDispatch();
	const [filter, setFilter] = useState({query:''});
	//const dataName = useSelector(state => state.dataReducer.currentDataName)
	const data = useSelector(state => state.dataReducer[dataName])// obj
	const totalCount = data?.totalCount;// num
	//const [dataList, setDataList] = useState([]);// arr of obj
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(1);
	const countPages = ()=> {
		const arr = [];
		for(let i = 0 ;i < getPageCount(totalCount, limit);i++){
			arr.push(`${i + 1}`);
		}
		return arr
	}
	const pageCount = countPages();
	/*const addDataItem = () => {
		const dataItem = {
			content: {
				name: {
					title:'Имя',
					description: [fields.name],
				}
				
			},
			id: Date.now().toString(),
		}
		if(dataList.length < limit){
			dispatch(actions.addDataItemAction({dataItem, dataName}))
		}
		//dispatch(actions.asyncAddDataItemAction({dataItem, userId: user.id, dataName}))
		if(dataList.length === limit){
			setTimeout(()=>{
				setPage(getPageCount(totalCount+1, limit))
			},500)
		}
	}*/
	const getBtnPagination = () => {
		return(
			<div className ={classes["dataTemplate__btn-wrap"] +' ' + classes['dataTemplate__nav-btn-wrap']}>
				{
					pageCount.length !== 0
					? pageCount.map((pageBtn) => {
						if(pageBtn == page){
							return(
								<FormBtn 
									handleClick={() => setPage(parseInt(pageBtn))}
									key={pageBtn}
									typename='button'
									spec={['btn--active', 'btn--nav-page']}
								>
									{pageBtn}
								</FormBtn>
							)
						} else{
							return(
								<FormBtn 
									handleClick={() => setPage(parseInt(pageBtn))}
									key={pageBtn}
									typename='button'
									spec={['btn--nav-page']}
								>
									{pageBtn}
								</FormBtn>
							)
						}
					}
					)
					: ''
				}
			</div>
		)
	}
	const [fetchData, isLoading, error] = useFetching(async (type) => {
		let response = null;
		let dataList =[];
		switch(modification){
			case 'clients':{
				response = await fetchUsers({limit, page, sort_by:'phone&brand&firstname', filter: filter.query});
				console.log(response)
				dataList = formatUsers(response);
				console.log(dataList)
				break
			}
			case 'managers':{
				response = await fetchManagers({limit, page, sort_by:'phone&brand&firstname', filter: filter.query});
				dataList = formatUsers(response);
				break
			}
			default:{
				break
			}
		}
		//console.log()
		//if its button click
		if(type==='btnClick'){
			for(let i=0; i< data?.list?.length; i++){
				for(let j=0; j< dataList?.length; j++){
					if(dataList[j]?.id === data.list[i]?.id){
						console.log(dataList[j], data.list[i])
						dataList.splice(j, 1);
						console.log(dataList);
					}
					
				}
			}
		}
		// if its filter
		dispatch(actions.addDataAction({
			dataName, 
			list:[...dataList], 
			totalCount:totalCount
		}))
	})
	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			dispatch(actions.rmAllDataAction({dataName}))
			setPage(1);
			fetchData('filter');
		}, 500)
		return () => clearTimeout(delayDebounceFn)
	}, [filter])
	useEffect(() => {
		if(page > 1){
			fetchData('btnClick')
		}
	}, [limit,page])
	useEffect(()=>{
		fetchData('btnClick');
		return () => {
			dispatch(actions.rmAllDataAction({dataName}))
			//dispatch(actions.setDataNameAction(''));
		}
	},[])
	return(
		<div className={classes['dataTemplate__container']}>
			{
				
					<DataTemplateList
						dataName={dataName}
						queryParams={queryParams}
						listType={listType}
						fetchData={fetchData}
						filter={filter}
						setFilter={setFilter}
						modification={modification}
					/>
			}
			{ isLoading &&
				<div style={{display: 'flex', justifyContent: 'center',marginTop:'5rem'}}>
					<Loader/>
				</div> 
			}
			{
				<FormBtn handleClick={()=> setPage(page+1)} spec={['btn--link']} className='btn__loadMore'>Загрузить еще</FormBtn>
			}
		</div>
	)
}
export default DataTemplate;