import { useState } from "react";
import { useSelector } from "react-redux";
import canPay from "../../../../../utils/canPay";
import { ADMIN_ROLE, FORM_FIELDS, MANAGER_ROLE, STATUS_COMPLETED_MODEL, STATUS_FUNDED, STATUS_NEW, STATUS_PREPAID, USER_ROLE } from "../../../../../utils/consts"
import { ADVANCE_PAYMENT, BONUS_POINTS, COST, REST_PAYMENT, STATUS, userNames, USE_BONUS } from "../../../../../utils/consts/OBJECT_FIELDS"
import { STATUS_CANCELED } from "../../../../../utils/consts/STATUS";
import FormField from "../../../FormField/FormField";
import HoverAlert from "../../../HoverAlert/HoverAlert";
/**
 * 1 проверка на принадлежность заказа
 * 
 */
const OrderLoyality = ({orderItem, role}) => {
	const {user} = useSelector(state => state.userReducer);
	const [onHover, setOnHover] = useState(false);
	const canUseBonus = () => {
		if(orderItem.getValue(BONUS_POINTS) >= (orderItem.getValue(COST)/2)){
			return true;
		}
		return false;
	}
	const getCheckbox = () => {
		if(
			role !== USER_ROLE
			||
			!canPay(orderItem[STATUS])// check for orderStatus
			||
			!orderItem.getValue('dateSending')
			){
			return <></>
		}else{
			if(canUseBonus())
			{
				return <FormField {...FORM_FIELDS[USE_BONUS]} /> //checkbox to pay by Bonus
			}else{
				return (<div
					onMouseOver={() => setOnHover(true)}
					onMouseOut={() => setOnHover(false)}
					// className='alert__container'
				>
					<HoverAlert onHover={onHover} msg='недостаточно баллов' styleClass='remove'/>
					<FormField 
						{...FORM_FIELDS[USE_BONUS]} 
						disabled={true}  
							classNames={['--disabled']}
						/>
				</div>)
			}
		}
	}
	// cant pay bonus if order.userId !== currentUser.id
	//console.log(orderItem.getValue('userInfo')?.id,user?.id)
	if(orderItem.getValue('userInfo')?.id !== user?.id){
		return <></>
	}
	if(
		role !== ADMIN_ROLE
		&& 
		role !== USER_ROLE
		&& 
		role !== MANAGER_ROLE
	){
		return <></>
	}
	return(
		<div className='orderItem__loyality-container'>
			{orderItem.getFieldText(BONUS_POINTS)}
			<FormField {...FORM_FIELDS[USE_BONUS]} /> 
			{/* { // старая логика оплаты
				getCheckbox()
			} */}
			{
				role === USER_ROLE
				?<a href='/loyality' className="project_ref">Как получить баллы?</a>
				:<></>
			}
		</div>
	)
}
export default OrderLoyality;