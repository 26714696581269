import FileLoader from "../../FormField/FormFieldSpecs/FileLoader/FileLoader";
import FormBtn from "../../formBtn/FormBtn";
import Header from "../../Header/Header";
import FormField from "../../FormField/FormField";
import classes from './UserProfile.module.css'
import UserDataForm from "../../Forms/UserDataForm/UserDataForm";
import { useSelector } from "react-redux";
import { useState } from "react";
import UserProfileInfo from "./UserProfileInfo/UserProfileInfo";
import { USER_ROLE } from "../../../../utils/consts";
const UserProfile = ({modification}) => {
	const [isOnEdit, setIsOnEdit] = useState(false);
	const getAddedFields = () =>{
		return(
			<FileLoader 
				fileType='file' 
				inclDrop={false} 
				fileAccept="image/*"
				innerText = 'Загрузить Аватар'
			/>
		)
	}
	return(
		<div className={classes['user-profile__container']}>
			{
				isOnEdit
				?(
					<UserDataForm
						role={USER_ROLE}
						//avatar Loader -> beforeFields={getAddedFields()}
						setIsOnEdit={setIsOnEdit}
						modification={modification}
					/>
				)
				:(
					<>
						<UserProfileInfo modification={modification}/>
						<FormBtn handleClick={()=> setIsOnEdit(true)}>изменить информацию</FormBtn>
					</>
				)
			}
		</div>
	)
}
export default UserProfile;