import FormBtn from "../formBtn/FormBtn";
import classes from './Header.module.css'
import { useEffect, useState } from "react";
import ModalWindow from "../modalWindow/ModalWindow";
import CreateDealForm from "../Forms/CreateDealForm/CreateDealForm";
import { ADMIN_ROLE, MANAGER_DIGITAL_ROLE, MANAGER_MODELLING_ROLE, MANAGER_ROLE, MANAGER_SECONDARY_ROLE, USER_ROLE } from "../../../utils/consts";
import BtnContainer from "../BtnContainer/BtnContainer";
import { BTN_CREATE_DEAL } from "../../../utils/consts/BTNS";
import ModalBtn from "../formBtn/ModalBtn/ModalBtn";
import NavBarController from "../Navbar/NavBarController";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { BONUS_POINTS } from "../../../utils/consts/OBJECT_FIELDS";
import phone from './img/iconsphone.png';
// import Phone from "../Phone/Phone";

const Header = (props) => {
	const {title, buttons, role='', personalName='', children, classNames=[]} = props
	const rootClasses =[classes['header'], classes[`header--${role}`], ...classNames]
	const {width, height} = useSelector(state => state.windowReducer);
	const {user} = useSelector(state => state.userReducer);
	return(
		<header className={rootClasses.join(' ')} >
			<div className={classes["container"]} >
				<NavBarController/>
				{
					width<769 ?
						( <>
							<div className="title-container" >
								<h2 className={classes['title']}>{title}</h2>
							</div>
							{/*<div className="title-container" >*/}
							{/*	{*/}
							{/*		role === USER_ROLE*/}
							{/*			?<h2 style={{margin:'0', paddingTop:'0.2rem', color: 'black'}} className={classes['title']} >Текущие баллы: {user?.getValue(BONUS_POINTS)}</h2>*/}
							{/*			:<></>*/}
							{/*	}*/}
							{/*</div>*/}
						</>) :
					( <>
							<h2 className={classes['title']}>{title}</h2>
							{
								role === USER_ROLE
									?<h2 className={classes['bonus-points']} style={{ color: 'black'}} >Текущие баллы: {user?.getValue(BONUS_POINTS)}</h2>
									:<></>
							}
					</>	)
				}


				<div className={classes['header__added-container']}>
					<BtnContainer btns={buttons} classes={['header__btn-container']}/>
					{
						(role !== USER_ROLE)
						? (
							<div className={classes['name']}>
								{personalName}
							</div>
						)
						:(<></>)
					}
					{
						width>1023
						?(<Link to={'/api/deals'} className='app__link'>
							<img className='logo' src='https://veso.pro/img/veso-logo.svg' alt='Логотип коспании ВЕСО'/>
						</Link>)
						:''
					}
				</div>
			</div>
		</header>
	)
}
export default Header;
