import FormTemplate from "../../FormTemplate";
import { useEffect, useState } from "react";
import FormBtn from "../formBtn/FormBtn";
import { Link } from "react-router-dom";
import { ADMIN_ROLE, DATA_NAMES, DEALS_ROUTE, DEAL_ROUTE, FORM_FIELDS, LOGIN_ROUTE, MANAGER_DIGITAL_ROLE, MANAGER_MODELLING_ROLE, MANAGER_ROLE, MANAGER_SECONDARY_ROLE, REGISTER_ADMIN_ROUTE, REGISTER_CLIENT_DEAL_ROUTE, REGISTER_CLIENT_ROUTE, REGISTER_MANAGER_ROUTE, REGISTER_ROUTE, STATUS_ID_NAME, SUCCESS_CODE, USER_ROLE } from "../../../utils/consts";
import { sendData } from "../../../utils/sendData";
import { sendRegisterData } from "../../../http/userAPI";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "../../../store/reducers/actions";
import classes from './RegisterForm/RegisterForm.module.css'
import tempAlert from "../../../utils/tempAlert";
import { pathLastItem } from "../../../utils/pathUtils/pathLastItem";
import { getSortedList } from "../../../utils/getSortedList";
import { createUserModel } from "../../../utils/creators/createUserModel";
import Form, { BLOCK_TYPE, RAW_FIELDS_TYPE, REGISTER_FORM, REGISTER_FORM_LEGAL, REGISTER_FORM_MODEL } from "../../../utils/classes/Form";
import {
	BIK,
	BRAND,
	EMAIL,
	FIRSTNAME,
	INN,
	IS_LEGAL,
	LASTNAME,
	LEGAL_PERSON,
	PHONE,
	PRIVACY_POLICY, PRIVACY_POLICY_IS_LEGAL,
	RS
} from "../../../utils/consts/OBJECT_FIELDS";
import FormComponent from "../../FormComponent";
import getFields from "../../../utils/formUtils/getFields";
import { FORM_BLOCKS } from "../../../utils/consts/FORM_FIELDS";
import User from "../../../utils/classes/User";
import {Checkbox} from "@mui/material";
import {Label} from "@material-ui/icons";
const RegisterForm = ({formPath = REGISTER_ROUTE.path, isModal=false, modalVisible, setModalVisible}) =>{
	const fieldValue = useSelector(state => state.fieldValueReducer.fields)
	const user = useSelector(state => state.userReducer.user)
	const form = useSelector(state => state.formReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let formClass = 'form__register--default'
	if(formPath  !== REGISTER_ROUTE.path){
		const formType = pathLastItem(formPath);
		formClass =`form__register--${formType}` // ex: form__register--client
	}
	const register = async () => {
		let response = null;
		const fieldsArr = getFields({FORM_TYPE:BLOCK_TYPE, FORM_FIELDS:form.blocks});
		try{
			let createdUserRole = '';
			switch(formPath){
				case REGISTER_ADMIN_ROUTE.path:
					createdUserRole = ADMIN_ROLE
					break
				case REGISTER_CLIENT_ROUTE.path:
					createdUserRole = USER_ROLE
					break
				case REGISTER_MANAGER_ROUTE.path:{
					if(fieldValue['position'] === 'Менеджер (Сервиса)'){
						createdUserRole = MANAGER_ROLE
					} else if(fieldValue['position'] === 'Менеджер (Оцифровка)'){
						createdUserRole = MANAGER_DIGITAL_ROLE
					} else if(fieldValue['position'] === 'Менеджер (Моделирования)'){
						createdUserRole = MANAGER_MODELLING_ROLE
					}
					break
				}
				default:
					createdUserRole = USER_ROLE
					break
			}
			response = await sendData(sendRegisterData, {
				formFields: [...fieldsArr],
				fieldValue:{...fieldValue, role: createdUserRole}
			})
		} catch(e){
			tempAlert(response.message, 3000,['--invalid']);
			console.log(e)
		}
		if(response?.success === SUCCESS_CODE){

			if(!user.role){
				dispatch(actions.setUserAction({
					phone: fieldValue[PHONE]
				}))
				navigate('../../' + LOGIN_ROUTE.path, {replace:true})
			} else {
				const {data} = response;
				//add userClass
				const userModel = new User({...data});
				dispatch(actions.addDataItemAction(
					{
						dataName:DATA_NAMES.users.id,
						dataItem:userModel
					}
				))

				if(isModal){
					setModalVisible(false)
				}
				//navigate('../../' + DEALS_ROUTE.path, {replace:true})
			}
			tempAlert('регистрация прошла успешно', 3000,['--valid']);
		} else{
			tempAlert( response.message, 3000,['--invalid']);
		}
	}
	useEffect(()=>{
		if(fieldValue[IS_LEGAL]){
			dispatch(actions.updFormFieldsAction({
				fields:[
					{
						...FORM_FIELDS[LASTNAME],
						required:true,
						innerText:'Фамилия*'
					},
					{
						...FORM_FIELDS[EMAIL],
						required:true,
						innerText:'Email*'
					}
				]
			}))
			dispatch(actions.addFormBlocksAction([
				{content:FORM_BLOCKS[LEGAL_PERSON]},
				{content:FORM_BLOCKS[INN]},
				{content:FORM_BLOCKS[BIK]},
				{content:FORM_BLOCKS[RS]},
				{content:FORM_BLOCKS[BRAND]},
				{content:FORM_BLOCKS[PRIVACY_POLICY_IS_LEGAL]},
			]))
			dispatch(actions.rmFormBlocksAction([
				{id: PRIVACY_POLICY}
			]))
			dispatch(actions.rmFieldAction(PRIVACY_POLICY))

			// dispatch(actions.rmFormBlocksAction([
			// 	{id: PRIVACY_POLICY}
			// ]))
		} else{
			dispatch(actions.updFormFieldsAction({
				fields:[
					{
						...FORM_FIELDS[LASTNAME],
						required:false
					},
					{
						...FORM_FIELDS[EMAIL],
						required:false
					}
				]
			}))
			dispatch(actions.rmFormBlocksAction([
				{id:BRAND},
				{id:LEGAL_PERSON},
				{id:INN},
				{id:BIK},
				{id:RS},
				{id: PRIVACY_POLICY_IS_LEGAL},
				{id: PRIVACY_POLICY},
			]))
			dispatch(actions.addFormBlocksAction([
				{content:FORM_BLOCKS[PRIVACY_POLICY]},
			]))
			dispatch(actions.rmFieldAction(BRAND))
			dispatch(actions.rmFieldAction(LEGAL_PERSON))
			dispatch(actions.rmFieldAction(INN))
			dispatch(actions.rmFieldAction(BIK))
			dispatch(actions.rmFieldAction(RS))
			// dispatch(actions.rmFieldAction(PRIVACY_POLICY))
			dispatch(actions.rmFieldAction(PRIVACY_POLICY_IS_LEGAL))
			// dispatch(actions.addFormBlocksAction([
			// 	{content:FORM_BLOCKS[PRIVACY_POLICY]}
			// ]))
		}
	},[fieldValue[IS_LEGAL]])
	return (
		<FormComponent
			formClassNames ={[formClass]}
			formType={REGISTER_FORM}
			formPath={formPath}
		>
			<FormBtn
				handleClick={() => register()}
				id='btn--register'
				type='submit'
			>
				Зарегистрироватся
			</FormBtn>
			{
				!user.role
				?(
					<div className='form__link-container'>
						<Link to={'../../'+ LOGIN_ROUTE.path} className='form__link'>есть аккаунт? Вход!</Link>
					</div>
				)
				:''
			}


		</FormComponent>

	)
}
export default RegisterForm;
