import { $api, $host } from './index';
import { ADMIN_ROLE, MANAGER_ROLE, REACT_APP_API_URL, SERVER_ROUTES, USER_ROUTE } from '../utils/consts';
import axios from 'axios';
const userPath = 'user'
export const sendRegisterData = async (props) => {
	const {
		email=null,
		firstname=null,
		lastname=null,
		brand=null,
		kpp=null,
		inn=null,
		rs=null,
		bik=null,
		role=null,
		phone=null,
		sendLink=null,
		legalPerson=null
	} = props;
	//SERVER_ROUTES.register
	const {data} = await $api.post(SERVER_ROUTES.register,{
		email,
		firstname,
		lastname,
		brand,
		kpp,
		inn,
		rs,
		bik,
		role,
		phone,
		sendLink,
		legalPerson
	});
	return data;
}
export const updateUserData = async (props) => {
	const {
		email=null,
		firstname=null,
		lastname=null,
		brand=null,
		kpp=null,
		inn=null,
		rs=null,
		bik=null,
		role=null,
		avatar=null,
		legalPerson=null,
		phone=null,
		userId=null
	} = props;
	//return({data:{...props, id:userId}, success:0})
	const {data} = await $api.put(SERVER_ROUTES.userUpdate ,{
		params:{
			id:userId
		},
		data:{
			phone,
			email,
			firstname,
			lastname,
			brand,
			kpp,
			inn,
			rs,
			bik,
			role,
			avatar,
			legalPerson
		}
	});
	return data;
}
export const rmUserData = async ({id}) =>{
	// return{
	// 	success:0,
	// 	data:null,
	// 	message:null
	// }
	//console.log(SERVER_ROUTES.userDelete + `/${id}`)
	const {data} = await $api.delete(SERVER_ROUTES.userDelete + `/${id}`);
	return data;
}
export const sendLoginData = async ({phone}) => {
	const {data} = await $api.post(SERVER_ROUTES.login, {phone});
	return data;
}
export const logoutUser = async () => {
	await $api.post(SERVER_ROUTES.logout,);// everytime when user reloading -> check jwt on valid
	localStorage.removeItem('token');
}
export const checkUser = async () => {
	const {data} = await axios.get(`${REACT_APP_API_URL}/refresh`, {withCredentials:true });// everytime when user reloading -> check jwt on valid
	localStorage.setItem('token', data.data.accessToken)
	return data.data;
}
export const checkCode = async (props) => {
	const {phone, sms__password} = props;
	const {data} = await $api.post(SERVER_ROUTES.checkCode,{
		phone: phone,
		code: sms__password,
	});// everytime when user reloading -> check jwt on valid 
	localStorage.setItem('token', data.data.accessToken)
	return data;
}