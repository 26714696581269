import { useDispatch, useSelector } from 'react-redux'
import FormBtn from '../../formBtn/FormBtn';
import classes from './UserCurrentOrder.module.css'
import { FORM_FIELDS, ORDER_CREATE_ROUTE, STATUS_FUNDED, STATUS_NAMES, STATUS_NEW, STATUS_PAIRS, STATUS_PREPAID, USER_ROLE } from '../../../../utils/consts';
import { Link, useNavigate} from "react-router-dom";
import DataItemPage from '../../../../pages/DataItemPage';
import OrderItem from '../../OrderItem/OrderItem';
import { getById } from '../../../../utils/getById';
import OrderItemPage from '../../OrderItem/OrderItemPage';
import {useEffect, useState} from 'react';
import { useFetching } from '../../../../hooks/useFetching';
import { formatOrders } from '../../../../utils/formatOrders';
import actions from '../../../../store/reducers/actions';
import { fetchDeals } from '../../../../http/dealAPI';
import UserGreetings from './UserGreetings';
import { sortOrders } from '../../../../utils/dispatchUtils/sortOrders';
import User from '../../../../utils/classes/User';
import { PAYMENT_LINK, SENDING_DATE } from '../../../../utils/consts/OBJECT_FIELDS';
import Loader from "../../loader/Loader";
const UserCurrentOrder = (props) => {
	//const {id} = props
	const dispatch = useDispatch();
	const user = useSelector(state => state.userReducer.user);
	const [isLoading, setIsLoading] = useState(true)
	const order = getById(user.orders, `${user.getValue('currentOrder')}`)
	const [fetchOrders, isOrdersLoading, error] = useFetching(async () => {
		const response = await fetchDeals({});
		const orders = [...formatOrders(response)];
		const sortedOrders = sortOrders(orders, USER_ROLE)
		for(let i = 0; i<sortedOrders.length; i++){
			dispatch(actions.addOrderAction({...sortedOrders[i]}))
		}

		const lastOrderId = orders[0]?.id;
		const userObj = new User(
			{
				...user.convertedServer,
				orders:[...orders],
				currentOrder:lastOrderId
			}
		)
		dispatch(actions.setUserAction(userObj))
		setIsLoading(false)
	})
	useEffect(()=>{
		fetchOrders()
		return () => {
			dispatch(actions.clearOrdersAction())
			const userObj = new User({...user.convertedServer, orders:[]})
			dispatch(actions.setUserAction(userObj))
		}
	},[])
	return(
		<>

			{
				order
				?(
					<>
						<OrderItemPage
							key ={order.id}
							role={user.role}
							orderItem={order}
							//children={getPayBtn()} payBtn already there
						/>
					</>
					//<DataItemPage dataName='orders' itemId={currentOrder}/>
				)
				:(
					isLoading ? <div style={{position:'fixed', display: 'flex', justifyContent: 'center',height:'100%', width:'100%'}}><Loader/></div> :
				<UserGreetings/>
				)
			}
		</>
	)
}
export default UserCurrentOrder;
