import { useLocation } from "react-router-dom";
import Failure from "./Failure/Failure";
import Success from "./Success/Success";

const PaymentStatusPage = () => {
	const {pathname} = useLocation();
	//const text = 'Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне.'
	return(
		<>
			{
				pathname === '/success'
				?<Success title='Оплата прошла успешно' text=''/>
				:<Failure title='Ошибка оплаты' text='Что-то пошло не так, обратитесь в тех.поддержку'/>
			}
		</>
	)
}
export default PaymentStatusPage;