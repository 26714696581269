import {
	ADMIN_ROLE,
	MANAGER_DIGITAL_ROLE,
	MANAGER_MODELLING_ROLE,
	MANAGER_ROLE,
	REGISTER_ADMIN_ROUTE,
	REGISTER_CLIENT_ROUTE,
	REGISTER_MANAGER_ROUTE,
	CLIENTS_ROUTE,
	REGISTER_ROUTE,
	STATUS_AT_WORK_DIGITIZATION,
	STATUS_AT_WORK_MODEL,
	STATUS_COMPLETED_DIGITIZATION,
	STATUS_COMPLETED_MODEL,
	STATUS_PREPAID,
	STATUS_NEW,
	USER_ROLE,
	USER_ROUTE,
	STATUS_AWAITING_PAYMENT
} from "../consts";
import { FORM_BLOCKS, FORM_FIELDS } from "../consts/FORM_FIELDS";
import { ADDRESS, BIK, BRAND, DEMO_LINK, EMAIL, FIRSTNAME, INN, IS_LEGAL, LASTNAME, LEGAL_PERSON, PACKAGE_DEAL, PHONE, POSITION,PROJECT_DIGITALIZATION_LINK, PROJECT_LINK, RS, SEND_DEAL_INFO, SEND_LINK, SMS_PASSWORD, SQUARE, STATUS, PRIVACY_POLICY_IS_LEGAL} from "../consts/OBJECT_FIELDS";
import { MANAGERS_ROUTE } from "../consts/ROUTES";
export const BLOCK_TYPE = 'blocks';
export const RAW_FIELDS_TYPE = 'raw_fields'
export const DEAL_INFO_FORM = 'DEAL_INFO_FORM';
export const CREATE_DEAL_FORM ='CREATE_DEAL_FORM'
export const CREATE_DEAL_FORM_VISIBLE ='CREATE_DEAL_FORM_VISIBLE'
export const REGISTER_FORM ='REGISTER_FORM'
export const REGISTER_FORM_LEGAL ='REGISTER_FORM_LEGAL'
export const LOGIN_FORM ='LOGIN_FORM'
export const USER_DATA_FORM = 'USER_DATA_FORM';

let desiredDate = ''

export default class Form {
	constructor({role,modification,type,addedFields}){
		this._role = role;
		this._modification=modification;
		this._type = type;
		const form = FORM_TYPES[this._modification];
		form.setFieldsByRole(this._role);
		this._title=form.title;
		// type blocks or fields
		this._fields=form[this._type];
		this.addNewFields(addedFields)
	}
	set fields(fields) {
		this._fields = [...fields]
	}
	set titleSetter(title){
		this._title = title;
	}
	get fields(){
		return this._fields
	}
	get title(){
		return this._title
	}
	get type(){
		return this._type
	}
	addNewFields(fields=[]){
		fields.forEach(
			field => {
				if(field?.replace){
					this._fields.splice(field.pos, 1, field.item)
				}else{
					this._fields.splice(field.pos, 0, field.item)
				}
			}
		)
	}
	updateEachField(updatedValues=[]){
		for(let i=0; i < this._fields.length;i++){
			this._fields[i] = {
				...this._fields[i],
				...updatedValues
			}
		}
	}
	updateManyFields({fieldIds,updateValues={}}){
		fieldIds.forEach(fieldId=>{
			this.updateField(fieldId, {...updateValues})
		})
	}
	updateField (fieldId, newField){
		if(!this._fields){
			console.log('cant update field')
			return;
		}
		if(this._type === BLOCK_TYPE){
			for(let i = 0; i < this._fields.length;i++){
				for(let j = 0; j < this._fields[i].fields.length;j++){
					if(this._fields[i].fields[j].id === fieldId){
						this._fields[i].fields[j] = {
							...this._fields[i].fields[j],
							...newField
						}
						//console.log(this._fields[i].fields[j])
					}

				}
			}
		} else{
			for(let i = 0; i < this._fields.length;i++){
				if(this._fields[i].id === fieldId){
					this._fields[i] = {
						...this._fields[i],
						...newField
					}

				}

			}
		}
	}
}
export class FormModel {
	constructor(props){
		const {
			title={},
			blocks=[]
		} = props;
		this._title = {...title};
		this._blocks = [...blocks];
	}
	get blocks(){
		return this._blocks
	}
	get title(){
		return this._title
	}
}
export const CREATE_DEAL_FORM_MODEL = {
	title:{
		name:'Создание сделки',
		info:'Заполните информацию о сделке:'
	},
	setFieldsByModificator(role){
		switch(role){
			case ADMIN_ROLE:
			case MANAGER_ROLE:{
				this[BLOCK_TYPE] = [
					{...FORM_BLOCKS.address,
					fields:[...FORM_BLOCKS[ADDRESS].fields]},
					{...FORM_BLOCKS.objectData,
					fields:[...FORM_BLOCKS['objectData'].fields]},
					{...FORM_BLOCKS.dateSending,
					fields:[...FORM_BLOCKS['dateSending'].fields]},
					{...FORM_BLOCKS.representativeContacts,
						fields:[...FORM_BLOCKS['representativeContacts'].fields]},
					{...FORM_BLOCKS.iWillBe,
						fields:[...FORM_BLOCKS['iWillBe'].fields]
					},
					{...FORM_BLOCKS.packageDeal,
						fields:[...FORM_BLOCKS[PACKAGE_DEAL].fields]},
					{...FORM_BLOCKS.finalCost,
					fields:[...FORM_BLOCKS['finalCost'].fields]},
					{...FORM_BLOCKS.status,
					fields:[...FORM_BLOCKS['status'].fields]},
					{...FORM_BLOCKS.software,
					fields:[...FORM_BLOCKS['software'].fields]},
					{...FORM_BLOCKS.documents,
					fields:[...FORM_BLOCKS['documents'].fields]},
					{...FORM_BLOCKS.comment,
					fields:[...FORM_BLOCKS['comment'].fields]},
					{...FORM_BLOCKS.sendLink}
					,
				]
				break;
			}
			case USER_ROLE:{
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS.address,
						fields:[...FORM_BLOCKS[ADDRESS].fields]
					},
					{
						...FORM_BLOCKS.objectData,
						fields:[...FORM_BLOCKS['objectData'].fields]
					},
					{
						...FORM_BLOCKS.representativeContacts,
						fields:[...FORM_BLOCKS['representativeContacts'].fields]
					},
					{
						...FORM_BLOCKS.iWillBe,
						fields:[...FORM_BLOCKS['iWillBe'].fields]
					},
					{
						...FORM_BLOCKS.desiredDate,
						fields:[...FORM_BLOCKS.desiredDate.fields]
					},
					{
						...FORM_BLOCKS.packageDeal,
						fields:[
							{
								...FORM_FIELDS.packageDeal,
								spec:'clickboxes',
								currValue:[FORM_FIELDS.packageDeal.options[0]],
								multiple: false,
							}
						]
					},
					{
						...FORM_BLOCKS.finalCost,
						fields:[...FORM_BLOCKS['finalCost'].fields]
					},
					{
						...FORM_BLOCKS.software,
						fields:[...FORM_BLOCKS['software'].fields]
					},
					{
						...FORM_BLOCKS.documents,
						fields:[...FORM_BLOCKS['documents'].fields]
					},
					{
						...FORM_BLOCKS.comment,
						fields:[...FORM_BLOCKS['comment'].fields]
					}
				]
				break;
			}
			default:
				break;
		}
	}
}
export const CREATE_DEAL_FORM_MODEL_TEST = {
	title:{
		name:'Создание сделки',
		info:'Заполните информацию о сделке:'
	},
	setFieldsByModificator(role){
		switch(role){
			case ADMIN_ROLE:
			case MANAGER_ROLE:{
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS.address,
						fields:[...FORM_BLOCKS[ADDRESS].fields]
					},
					{
						...FORM_BLOCKS.objectData,
						fields:[...FORM_BLOCKS['objectData'].fields]
					},
					{
						...FORM_BLOCKS.dateSending,
						fields:[...FORM_BLOCKS['dateSending'].fields]
					},
					{
						...FORM_BLOCKS.representativeContacts,
						fields:[...FORM_BLOCKS['representativeContacts'].fields]
					},
					{
						...FORM_BLOCKS.finalCost,
						fields:[...FORM_BLOCKS['finalCost'].fields]
					},
					{
						...FORM_BLOCKS.packageDeal,
						fields:[...FORM_BLOCKS[PACKAGE_DEAL].fields]
					},
					{
						...FORM_BLOCKS.status,
						fields:[...FORM_BLOCKS['status'].fields]
					},
					{
						...FORM_BLOCKS.software,
						fields:[...FORM_BLOCKS['software'].fields]
					},
					{
						...FORM_BLOCKS.documents,
						fields:[...FORM_BLOCKS['documents'].fields]
					},
					{
						...FORM_BLOCKS.comment,
						fields:[...FORM_BLOCKS['comment'].fields]
					},
					{
						...FORM_BLOCKS.sendLink,
						fields:[...FORM_BLOCKS.sendLink.fields]
					},
				]
				break;
			}
			case USER_ROLE:{
				this[BLOCK_TYPE] = [
					{
						content:FORM_BLOCKS.address,
						fields:[...FORM_BLOCKS[ADDRESS].fields],
					},
					{
						content:FORM_BLOCKS.objectData
					},
					{
						content:FORM_BLOCKS.representativeContacts
					},
					{
						content:FORM_BLOCKS.desiredDate
					},
					{
						content:FORM_BLOCKS.finalCost
					},
					{
						content:{
							...FORM_BLOCKS.packageDeal,
							fields:[...FORM_BLOCKS[PACKAGE_DEAL].fields],
							fields:[
								{
									...FORM_FIELDS.packageDeal,
									spec:'clickboxes',
									currValue:[FORM_FIELDS.packageDeal.options[2]],
									multiple: false,
								}
							]
						},
					},
					{
						content:FORM_BLOCKS.software
					},
					{
						content:FORM_BLOCKS.documents
					},
					{
						content:FORM_BLOCKS.comment
					}
				]
				break;
			}
			default:
				break;
		}
	}
}
export const CREATE_DEAL_FORM_MODEL_VISIBLE ={
	title:{
		name:'Создание сделки',
		info:'Заполните информацию о сделке:'
	},
	fieldType:BLOCK_TYPE,
	setFieldsByModificator(role){
		switch(role){
			case ADMIN_ROLE:
			case MANAGER_ROLE:{
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS.address,
						fields:[...FORM_BLOCKS[ADDRESS].fields]
					},
					{
						...FORM_BLOCKS.objectDataVisible,
						fields:[...FORM_BLOCKS['objectDataVisible'].fields]
					},
					{
						...FORM_BLOCKS.dateSending,
						fields:[...FORM_BLOCKS['dateSending'].fields]
					},
					{
						...FORM_BLOCKS.representativeContacts,
						fields:[...FORM_BLOCKS['representativeContacts'].fields]
					},
					{
						...FORM_BLOCKS.finalCost,
						fields:[...FORM_BLOCKS['finalCost'].fields]
					},
					{
						...FORM_BLOCKS.packageDeal,
						fields:[...FORM_BLOCKS[PACKAGE_DEAL].fields]
					},
					{
						...FORM_BLOCKS.status,
						fields:[...FORM_BLOCKS['status'].fields]
					},
					{
						...FORM_BLOCKS.software,
						fields:[...FORM_BLOCKS['software'].fields]
					},
					{
						...FORM_BLOCKS.comment,
						fields:[...FORM_BLOCKS['comment'].fields]
					},
					{
						...FORM_BLOCKS.documents,
						fields:[...FORM_BLOCKS['documents'].fields]
					},
					{
						...FORM_BLOCKS.sendLink,
						fields:[...FORM_BLOCKS['sendLink'].fields]
					},
				]
				break;
			}
			case USER_ROLE:{
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS.address,
						fields:[...FORM_BLOCKS[ADDRESS].fields],
					},
					{
						...FORM_BLOCKS.objectDataVisible,
						fields:[...FORM_BLOCKS.objectDataVisible.fields]
					},
					{
						...FORM_BLOCKS.representativeContacts,
						fields:[...FORM_BLOCKS.representativeContacts.fields]
					},
					{
						...FORM_BLOCKS.iWillBe,
						fields:[...FORM_BLOCKS.iWillBe.fields]
					},
					{
						...FORM_BLOCKS.desiredDate,
						fields:[...FORM_BLOCKS.desiredDate.fields]
					},
					{
						...FORM_BLOCKS.packageDeal,
						fields:[
							{
								...FORM_FIELDS.packageDeal,
								spec:'clickboxes',
								currValue:[FORM_FIELDS.packageDeal.options[2]],
								multiple: false,
							}
						]
					},
					{
						...FORM_BLOCKS.finalCost,
						fields:[...FORM_BLOCKS.finalCost.fields]
					},
					{
						...FORM_BLOCKS.software,
						fields:[...FORM_BLOCKS.software.fields]
					},
					{
						...FORM_BLOCKS.documents,
						fields:[...FORM_BLOCKS.documents.fields]
					},
					{
						...FORM_BLOCKS.comment,
						fields:[...FORM_BLOCKS.comment.fields]
					}
				]
				break;
			}
			default:
				break;
		}
	},
	// adding fields to specific position
	addFields(fields=[]){
		fields.forEach(
			field => {
				this[BLOCK_TYPE].splice(field.pos, 0, field.item)
			}
		)
	}
}
export const REGISTER_FORM_MODEL = {
	title:{
		name:'Форма регистрации',
		info:'Заполните информацию о себе:'
	},
	setFieldsByModificator(role){
		//console.log(role)
		switch(role){
			case REGISTER_ADMIN_ROUTE.path:{
				this.title.info='Заполните информацию об администраторе'
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS[FIRSTNAME],
						fields:[...FORM_BLOCKS[FIRSTNAME].fields]
					},
					{
						...FORM_BLOCKS[LASTNAME],
						fields:[...FORM_BLOCKS[LASTNAME].fields]
					},
					{
						...FORM_BLOCKS[PHONE],
						fields:[...FORM_BLOCKS[PHONE].fields]
					}
				]
				//console.log(this.blocks)
				break;
			}
			case REGISTER_MANAGER_ROUTE.path:{
				this.title.info='Заполните информацию о менеджере'
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS[POSITION],
						fields:[...FORM_BLOCKS[POSITION].fields]},
					{
						...FORM_BLOCKS[FIRSTNAME],
						fields:[...FORM_BLOCKS[FIRSTNAME].fields]
					},
					{
						...FORM_BLOCKS[LASTNAME],
						fields:[...FORM_BLOCKS[LASTNAME].fields]
					},
					{
						...FORM_BLOCKS[PHONE],
						fields:[...FORM_BLOCKS[PHONE].fields]
					},
				]
				break;
			}
			case REGISTER_CLIENT_ROUTE.path:{
				this.title.info='Заполните информацию о клиенте'
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS[IS_LEGAL],
						fields:[...FORM_BLOCKS[IS_LEGAL].fields]
					},
					{
						...FORM_BLOCKS[FIRSTNAME],
						fields:[...FORM_BLOCKS[FIRSTNAME].fields]
					},
					{
						...FORM_BLOCKS[LASTNAME],
						fields:[...FORM_BLOCKS[LASTNAME].fields]
					},
					{
						...FORM_BLOCKS[PHONE],
						fields:[...FORM_BLOCKS[PHONE].fields]
					},
					{
						...FORM_BLOCKS[EMAIL],
						fields:[...FORM_BLOCKS[EMAIL].fields]
					},
					{
						...FORM_BLOCKS[SEND_LINK],
						blockTitle:'Отправить ссылку для входа клиенту?',
						fields:[
							...FORM_BLOCKS[SEND_LINK].fields,
						]
					}
				]
				break;
			}
			case REGISTER_ROUTE.path:{
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS[IS_LEGAL],
						fields:[...FORM_BLOCKS[IS_LEGAL].fields]
					},
					{
						...FORM_BLOCKS[FIRSTNAME],
						fields:[...FORM_BLOCKS[FIRSTNAME].fields]
					},
					{
						...FORM_BLOCKS[LASTNAME],
						fields:[...FORM_BLOCKS[LASTNAME].fields]
					},
					{
						...FORM_BLOCKS[PHONE],
						fields:[...FORM_BLOCKS[PHONE].fields]
					},
					{
						...FORM_BLOCKS[EMAIL],
						fields:[...FORM_BLOCKS[EMAIL].fields]
					},
				]
				break;
			}
			default:
				break;
		}
	},
	updateEveryBlock(){
		for(let i = 0; i < this.blocks.length;i++){
			let block = this.blocks[i];
			block= {
				...FORM_BLOCKS[block.id]
			}
		}

	}
}
export const REGISTER_FORM_LEGAL_MODEL = {
	title:{
		name:'Форма регистрации',
		info:'Заполните информацию о себе:'
	},
	setFieldsByModificator(role){
		this[BLOCK_TYPE] = [
			{
				...FORM_BLOCKS[FIRSTNAME],
				fields:[...FORM_BLOCKS[FIRSTNAME].fields]
			},
			{
				...FORM_BLOCKS[LASTNAME],
				fields:[...FORM_BLOCKS[LASTNAME].fields]
			},
			{
				...FORM_BLOCKS[PHONE],
				fields:[...FORM_BLOCKS[PHONE].fields]
			},
			{
				...FORM_BLOCKS[EMAIL],
				fields:[...FORM_BLOCKS[EMAIL].fields]
			},
			{
				...FORM_BLOCKS[BRAND],
				fields:[...FORM_BLOCKS[BRAND].fields]
			},
			{
				...FORM_BLOCKS[LEGAL_PERSON],
				fields:[...FORM_BLOCKS[LEGAL_PERSON].fields]
			},
			{
				...FORM_BLOCKS[INN],
				fields:[...FORM_BLOCKS[INN].fields]
			},
			{
				...FORM_BLOCKS[BIK],
				fields:[...FORM_BLOCKS[BIK].fields]
			},
			{
				...FORM_BLOCKS[RS],
				fields:[...FORM_BLOCKS[RS].fields]
			},
		]
	}
}
export const LOGIN_FORM_MODEL = {
	title:{
		name:'ВХОД В ЛИЧНЫЙ КАБИНЕТ',
		// info:'ВВЕДИТЕ НОМЕР ТЕЛЕФОНА'
	},
	setFieldsByModificator(modificator){
		//console.log(role)
		this[BLOCK_TYPE] = [
			{
				...FORM_BLOCKS[PHONE],
				fields:[...FORM_BLOCKS[PHONE].fields]
			}
		]
	}
}
export const DEAL_INFO_FORM_MODEL = {
	title:{
		name:'Изменение данных о сделке',
		info:'Заполните все доступные поля: '
	},
	setFieldsByModificator(role){
		switch(role){
			case ADMIN_ROLE:
			case MANAGER_ROLE:{
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS.address,
						fields:[...FORM_BLOCKS[ADDRESS].fields]
					},
					{
						id:'objectDataVisible',
						blockTitle:'Введите данные объекта',
						classNames:[],
						fields:[
							{...FORM_FIELDS.cost},
							{...FORM_FIELDS.square},
							{...FORM_FIELDS.floors}
						]
					},
					{...FORM_BLOCKS.dateSending,
					fields:[...FORM_BLOCKS['dateSending'].fields]},
					{...FORM_BLOCKS.representativeContacts,
					fields:[...FORM_BLOCKS['representativeContacts'].fields]},
					//FORM_BLOCKS.finalCost,
					{...FORM_BLOCKS.links,
					fields:[...FORM_BLOCKS['links'].fields]},
					{...FORM_BLOCKS.packageDeal,
					fields:[...FORM_BLOCKS[PACKAGE_DEAL].fields]},
					{...FORM_BLOCKS.status,
					fields:[...FORM_BLOCKS['status'].fields]},
					{...FORM_BLOCKS.software,
					fields:[...FORM_BLOCKS['software'].fields]},
					{...FORM_BLOCKS.documents,
					fields:[...FORM_BLOCKS['documents'].fields]},
					{...FORM_BLOCKS.sendLink,
					fields:[...FORM_BLOCKS['sendLink'].fields]},
					{...FORM_BLOCKS[SEND_DEAL_INFO],
					fields:[...FORM_BLOCKS[SEND_DEAL_INFO].fields]},
				]
				break;
			}
			case MANAGER_DIGITAL_ROLE:{
				//this.title.info='Заполните информацию о менеджере'
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS[STATUS],
						blockTitle:null,
						fields:[
							{
								...FORM_FIELDS[STATUS],
								options: [
									//STATUS_NEW.description,
									STATUS_NEW.description,
									STATUS_AWAITING_PAYMENT.description,
									STATUS_PREPAID.description,
									STATUS_AT_WORK_DIGITIZATION.description,
									STATUS_COMPLETED_DIGITIZATION.description,
								],
								currValue:STATUS_PREPAID.description,
								defaultValue:STATUS_PREPAID.description,
							}
						]
					},
					{
						...FORM_BLOCKS[PROJECT_DIGITALIZATION_LINK],
						fields:[...FORM_BLOCKS[PROJECT_DIGITALIZATION_LINK].fields]
					}
				]
				break;
			}
			case MANAGER_MODELLING_ROLE:{
				//this.title.info='Заполните информацию о клиенте'
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS[STATUS],
						blockTitle:null,
						fields:[
							{
								...FORM_FIELDS[STATUS],
								options: [
									//STATUS_NEW.description,
									STATUS_NEW.description,
									STATUS_AWAITING_PAYMENT.description,
									STATUS_PREPAID.description,
									STATUS_COMPLETED_DIGITIZATION.description,
									STATUS_AT_WORK_MODEL.description,
									STATUS_COMPLETED_MODEL.description,
								],
								currValue:STATUS_PREPAID.description,
								defaultValue:STATUS_PREPAID.description,
							}
						]
					},
					{
						...FORM_BLOCKS[SQUARE],
						fields:[...FORM_BLOCKS[SQUARE].fields]
					},
					{
						...FORM_BLOCKS[DEMO_LINK],
						fields:[...FORM_BLOCKS[DEMO_LINK].fields]
					},
					{
						...FORM_BLOCKS[PROJECT_LINK],
						fields:[...FORM_BLOCKS[PROJECT_LINK].fields]
					},
				]
				break;
			}
			default:
				break;
		}
	},
	updateField(fieldId, newField){
		if(fieldId === FORM_BLOCKS.dateSending.id && newField.currValue){
			newField.currValue = newField.currValue.split('-').reverse().join('-')
		}
		for(let i = 0; i < this.blocks.length; i++){
			for(let j = 0; j < this.blocks[i].fields.length;j++){
				if(this.blocks[i].fields[j].id === fieldId){
					this.blocks[i].fields[j] = {
						...this.blocks[i].fields[j],
						...newField,
						defaultValue: newField.currValue

					}
					//console.log(this._fields[i].fields[j])
				}

			}
		}
	}
}
export const  USER_DATA_FORM_MODEL ={
	title:{
		name:'Изменение данных о пользователе',
		info:'Заполните все доступные поля: '
	},
	setFieldsByModificator(path){
		// const block = {...FORM_BLOCKS[PHONE]}
		// console.log('formField',block)
		const block = {
			...FORM_BLOCKS[PHONE],
			fields:[...FORM_BLOCKS[PHONE].fields]
		}
		console.log('formField',block)
		switch(path){
			case USER_ROUTE.path:{
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS[FIRSTNAME],
						fields:[...FORM_BLOCKS[FIRSTNAME].fields]
					},
					{
						...FORM_BLOCKS[LASTNAME],
						fields:[...FORM_BLOCKS[LASTNAME].fields]
					},
					{
						...FORM_BLOCKS[EMAIL],
						fields:[...FORM_BLOCKS[EMAIL].fields]
					},
					{
						...FORM_BLOCKS[BRAND],
						fields:[
							{
							...FORM_FIELDS[BRAND],
							required:false,
							innerText:'Брэнд'
						}],
					},
					{
						...FORM_BLOCKS[LEGAL_PERSON],
						fields:[
							{
								...FORM_FIELDS[LEGAL_PERSON],
								required:false,
								innerText:'Юр.лицо'
							},
						]
					},
					{
						...FORM_BLOCKS[INN],
						fields:[
							{
								...FORM_FIELDS[INN],
								required:false,
								innerText:'ИНН'
							}
						]
					},
					{
						...FORM_BLOCKS[BIK],
						fields:[
							{
								...FORM_FIELDS[BIK],
								required:false,
								innerText:'БИК'
							}
						]
					},
					{
						...FORM_BLOCKS[RS],
						fields:[
							{
								...FORM_FIELDS[RS],
								required:false,
								innerText:'Р/С'
							}
						]
					}
				]
				break;
			}
			case CLIENTS_ROUTE.path:{
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS[FIRSTNAME],
						fields:[...FORM_BLOCKS[FIRSTNAME].fields]
					},
					{
						...FORM_BLOCKS[LASTNAME],
						fields:[...FORM_BLOCKS[LASTNAME].fields]
					},
					{
						...FORM_BLOCKS[PHONE],
						fields:[...FORM_BLOCKS[PHONE].fields]
					},
					{
						...FORM_BLOCKS[EMAIL],
						fields:[...FORM_BLOCKS[EMAIL].fields]
					},
					{
						...FORM_BLOCKS[BRAND],
						fields:[
							{
							...FORM_FIELDS[BRAND],
							required:false,
							innerText:'Брэнд'
						}],
					},
					{
						...FORM_BLOCKS[LEGAL_PERSON],
						fields:[
							{
								...FORM_FIELDS[LEGAL_PERSON],
								required:false,
								innerText:'Юр.лицо'
							},
						]
					},
					{
						...FORM_BLOCKS[INN],
						fields:[
							{
								...FORM_FIELDS[INN],
								required:false,
								innerText:'ИНН'
							}
						]
					},
					{
						...FORM_BLOCKS[BIK],
						fields:[
							{
								...FORM_FIELDS[BIK],
								required:false,
								innerText:'БИК'
							}
						]
					},
					{
						...FORM_BLOCKS[RS],
						fields:[
							{
								...FORM_FIELDS[RS],
								required:false,
								innerText:'Р/С'
							}
						]
					}
				]
				break;
			}
			case MANAGERS_ROUTE.path:{
				this[BLOCK_TYPE] = [
					{
						...FORM_BLOCKS[FIRSTNAME],
						fields:[...FORM_BLOCKS[FIRSTNAME].fields]
					},
					{
						...FORM_BLOCKS[LASTNAME],
						fields:[...FORM_BLOCKS[LASTNAME].fields]
					},
					{
						...FORM_BLOCKS[PHONE],
						fields:[...FORM_BLOCKS[PHONE].fields]
					},
					{
						...FORM_BLOCKS[EMAIL],
						fields:[...FORM_BLOCKS[EMAIL].fields]
					},
				]
				break;
			}
			default:
				break;
		}
	},
	updateField(fieldId, newField){
		//can be fault
		// изменяет значение поля внутри const блока
		// const block = {...FORM_BLOCKS[fieldId]}
		// console.log('formField',block)
		for(let i = 0; i < this.blocks.length;i++){
			for(let j = 0; j < this.blocks[i].fields.length;j++){
				if(this.blocks[i].fields[j].id === fieldId){
					this.blocks[i].fields[j] = {
						...this.blocks[i].fields[j],
						...newField
					}
				}

			}
		}
	},
	updateEveryBlock(){
		for(let i = 0; i < this.blocks.length;i++){
			this.blocks[i]= {
				...FORM_BLOCKS[this.blocks[i].id]
			}
		}

	}
}
export const FORM_TYPES = {
	[CREATE_DEAL_FORM]:CREATE_DEAL_FORM_MODEL,
	[CREATE_DEAL_FORM_VISIBLE]: CREATE_DEAL_FORM_MODEL_VISIBLE,
	[REGISTER_FORM]:REGISTER_FORM_MODEL,
	[REGISTER_FORM_LEGAL]:REGISTER_FORM_LEGAL_MODEL,
	[DEAL_INFO_FORM] : DEAL_INFO_FORM_MODEL,
	[USER_DATA_FORM]:USER_DATA_FORM_MODEL,
	[LOGIN_FORM]: LOGIN_FORM_MODEL
}
