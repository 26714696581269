import {put, takeEvery, call} from 'redux-saga/effects'
import { 
	fetchDeals, 
	addDealItem, 
	updateDealItem,
	rmDealItem 
} from '../http/dealAPI';

import { 
	addOrderAction,
	addOrderItemAction,
	rmOrderItemAction,
	updateOrderItemAction,
	ASYNC_ADD_ORDERS,
	ASYNC_ADD_ORDER_ITEM,
	ASYNC_RM_ORDER_ITEM,
	ASYNC_UPDATE_ORDER_ITEM,
} from '../store/reducers/ordersReducer';

function* fetchOrderWorker(payload){
	//{id, role}
	try{
		const data = yield call(() => fetchDeals(payload));
		const dataPayload = yield call(()=> new Promise( res => res(data)))
		// typeName ordersList
		//yield put(addOrderAction(dataPayload))
		console.log(data)
	} catch(e){
		yield console.log(e);
	}
}
function* addOrderItemWorker({payload}){
	try{
		const data = yield call(() => addDealItem(payload));
		//const dataPayload = yield call(()=> new Promise( res => res({list: data.list, totalCount: data.totalCount, dataName})))
		//yield put(addOrderAction(payload))
		yield alert(data.message)
	} catch(e){
		yield console.log(e);
	}
}
function* rmOrderItemWorker({payload}){
	try{
		const data = yield call(() => rmDealItem(payload));
		yield alert(data.message)
	} catch(e){
		yield console.log(e);
	}
}
function* updateOrderItemWorker({payload}){
	try{
		const data = yield call(() => updateDealItem(payload));
		yield alert(data.message)
	} catch(e){
		yield console.log(e);
	}
}
export function* orderWatcher() {
	yield takeEvery(ASYNC_ADD_ORDERS, fetchOrderWorker);
	yield takeEvery(ASYNC_ADD_ORDER_ITEM, addOrderItemWorker);
	yield takeEvery(ASYNC_RM_ORDER_ITEM, rmOrderItemWorker);
	yield takeEvery(ASYNC_UPDATE_ORDER_ITEM, updateOrderItemWorker);
}