//for order
export const ID = 'id';
export const STATUS ='status';
export const USER_INFO = 'userInfo';
export const USER_FIRSTNAME = 'userFirstname';
export const PRIVACY_POLICY = 'privacyPolicy'
export const PRIVACY_POLICY_IS_LEGAL = 'privacyPolicyIsLegal'
export const USER_NAME = 'userName';
export const COST = 'cost';
export const ADVANCE_PAYMENT = 'advancePayment';
export const REST_PAYMENT = 'restPayment';
export const PROJECT_LINK='projectLink';
export const DEMO_LINK='demoLink';
export const HOUSE='house';
export const CITY='city';
export const SENDING_TIME = 'timeSending';
export const FLAT = 'flat';
export const STREET ='street';
export const DESIRED_DATE='desiredDate';
export const DESIRED_TIME='desiredTime';
export const SENDING_DATE='dateSending';
export const REPRESENTATIVE_NAME = 'representativeName';
export const REPRESENTATIVE_PHONE = 'representativePhone';
export const PACKAGE_DEAL='packageDeal';
export const SQUARE='square';
export const SQUARE_SELECT = 'squareSelect'
export const FLOORS='floors';
export const FLOORS_SELECT = 'floorsSelect';
export const SOFTWARE='software';
export const ADDRESS = 'address';
export const SEND_LINK ='sendLink';
export const I_WILL_BE ='iWillBe';
export const SEND_DEAL_INFO ='sendDealInfo';
export const PAYMENT_LINK = 'paymentLink';
export const DEMONSTRATION_LINK='demonstrationLink';
export const PROJECT_DIGITALIZATION_LINK = 'digitalizationLink';
export const STATUS_ID = 'statusId';
export const ROLE = 'role';
export const COMMENTS ='comments';
export const COMMENT ='comment';
export const USE_BONUS ='useBonus';
export const PAY_METHOD='payMethod';
export const DOCUMENT = 'document';
export const IMAGE = 'image';
export const DOCUMENTS = 'documents';
export const LINKS = 'links';
export const POSITION = 'position';
export const SMS_PASSWORD = 'sms__password';
// for user
export const FIRSTNAME ='firstname';
export const LASTNAME = 'lastname';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const BRAND = 'brand';
export const INN='inn';
export const BIK='bik';
export const KPP='kpp';
export const RS='rs';
export const TOTAL_PAID = 'totalPaid';
export const BONUS_POINTS ='bonusPoints';
export const TG = 'tg';
export const WHATSAPP = 'whatsapp';
export const LEGAL_PERSON = 'legalPerson';
export const IS_LEGAL = 'isLegal';
export const orderNames = {
	[ID]:'Номер заказа',
	[STATUS]:'Статус',
	[USER_INFO]:null,
	[COST]:'Заказ на сумму',
	[ADVANCE_PAYMENT]:'Внесенный Аванс',
	[REST_PAYMENT]:'Осталось оплатить',
	[PROJECT_LINK]:'Cсылка на проект',
	[DEMO_LINK]:'Демо ссылка на проект',
	[PROJECT_DIGITALIZATION_LINK]:'Ссылка на проект оцифровки',
	[HOUSE]:'',
	[CITY]:'город',
	[FLAT]:'',
	[STREET]:'улица',
	[SENDING_TIME]:'Время сканирования',
	[DESIRED_DATE]:'Желаемая дата',
	[DESIRED_TIME]:'Желаемое время',
	[SENDING_DATE]: 'Дата сканирования',
	[REPRESENTATIVE_NAME]: 'Представитель',
	[REPRESENTATIVE_PHONE]:'Тел. представителя',
	[PACKAGE_DEAL]:'Пакет',
	[SQUARE]:'Площадь',
	[FLOORS]: 'Этажность',
	[SOFTWARE]:'ПО',
	[COMMENTS]:'Комментарии',
	[PAY_METHOD]:'Способ оплаты',
	[DEMONSTRATION_LINK]: 'Проект',
	[ADDRESS]:'Адрес',
	[BONUS_POINTS]:'Текущие баллы',
	[USER_INFO]:'Заказчик',
	[I_WILL_BE]: 'Буду сам/а'
}
export const userNames = {
	[FIRSTNAME]:'Имя',
	[LASTNAME]:'Фамилия',
	[EMAIL]:'Email',
	[PHONE]:'Телефон',
	[BRAND]:'Брэнд',
	[INN]:'ИНН',
	[BIK]:'БИК',
	[KPP]:'КПП',
	[RS]:'Р/С',
	[TOTAL_PAID]:'Сумма всех сделок',
	[BONUS_POINTS]:'Баллы',
	[WHATSAPP]:'whatsapp',
	[TG]: 'tg',
	[LEGAL_PERSON]:'Юр.лицо',
	[BONUS_POINTS]:'Текущие баллы'
}
