import classes from '../NavBar.module.css'
import NavbarList from "../NavbarList/NavbarList"
const MobileNavBar = ({children, setIsVisible}) => {
	return(
			<div className={classes['navbar']}>
				<NavbarList setIsVisible={setIsVisible}/>
				{children}
			</div>
				
	)
}
export default MobileNavBar;