import {
	HOME_ROUTE,
	ABOUT_ROUTE,
	USER_ROUTE,
	REGISTER_ROUTE,
	LOGIN_ROUTE,
	ORDERS_ROUTE,
	ORDER_CURRENT_ROUTE,
	ORDER_CREATE_ROUTE,
	LOYALITY_ROUTE,
	DEAL_ROUTE,
	DEALS_ROUTE,
	DEALS_DONE_ROUTE,
	DEAL_DONE_ROUTE,
	CLIENTS_ROUTE,
	CLIENT_ROUTE,
	CALENDAR_ROUTE,
	REGISTER_ADMIN_ROUTE,
	REGISTER_MANAGER_ROUTE,
	REGISTER_CLIENT_ROUTE,
	ANALYTICS_ROUTE,
	QA_ROUTE,
	OFFER_ROUTE,
	SMS_DISTRIBUTION_ROUTE,
	ALL_ROUTES,
} from './consts'
export const getRouteVar = (param) => {
	switch(param){
		case 'home':
			return HOME_ROUTE
		case 'about':
			return ABOUT_ROUTE
		case 'user':
			return USER_ROUTE
		case 'register':
			return REGISTER_ROUTE
		case 'login':
			return LOGIN_ROUTE
		case 'orders':
			return ORDERS_ROUTE
		case 'currentOrder':
			return ORDER_CURRENT_ROUTE
		case 'createOrder':
			return ORDER_CREATE_ROUTE
		case 'loyality':
			return LOYALITY_ROUTE
		case 'deal':
			return DEAL_ROUTE
		case 'deals':
			return DEALS_ROUTE
		case 'dealsDone':
			return DEALS_DONE_ROUTE
		case 'dealDone':
			return DEAL_DONE_ROUTE
		case 'clients':
			return CLIENTS_ROUTE
		case 'client':
			return CLIENT_ROUTE
		case 'calendar':
			return CALENDAR_ROUTE
		case 'registerAdmin':
			return REGISTER_ADMIN_ROUTE
		case 'registerManager':
			return REGISTER_MANAGER_ROUTE
		case 'registerClient':
			return REGISTER_CLIENT_ROUTE
		case 'analytics':
			return ANALYTICS_ROUTE
		case 'qa':
			return QA_ROUTE
		case 'offer':
			return OFFER_ROUTE
		case 'smsDistribution':
			return SMS_DISTRIBUTION_ROUTE
		case 'orders/:id':{
			return ORDER_CURRENT_ROUTE
		}
		default:
			return 'undefined route'
	}
}

export const getRouteByPath = (path) => {
	for(let i=0; i<ALL_ROUTES.length; i++){
		if(ALL_ROUTES[i].path === path){
			return ALL_ROUTES[i]
		}
	}
	return '';
}