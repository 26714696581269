import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SERVICE_TYPES } from "../../../../../utils/consts/FORM_FIELDS";
import { COST, FLOORS, FLOORS_SELECT, PACKAGE_DEAL, SQUARE, SQUARE_SELECT, userNames } from "../../../../../utils/consts/OBJECT_FIELDS";
import { ADMIN_ROLE, FORM_FIELDS, MANAGER_DIGITAL_ROLE, MANAGER_ROLE, USER_ROLE } from "../../../../../utils/consts";
import { calculateCost } from "../../../../../utils/consts/PRICE";
import actions from "../../../../../store/reducers/actions";
import { formatFields } from "../../../../../utils/formatFields";
const CreateDealCost = () => {
	const fieldValue = useSelector(state => state.fieldValueReducer.fields);
	const {user} = useSelector(state => state.userReducer);
	const dispatch = useDispatch();
	const [content, setContent] = useState({
		title:'Выберите площадь объекта',
		descr:'чтобы вам стали доступны тарифные планы'
	});
	const getText = () => {
		if(!fieldValue[PACKAGE_DEAL] || !fieldValue[SQUARE]){
			return{
				title:'Выберите площадь объекта',
				descr:'чтобы вам стали доступны тарифные планы'
			}
		}
		//console.log(fieldValue[PACKAGE_DEAL])
		let text = {
			title:'Выберите площадь объекта',
			descr:'чтобы вам стали доступны тарифные планы'
		};
		
		let price = '';
		let pDeal = fieldValue[PACKAGE_DEAL];
		if(user.role === USER_ROLE){
			pDeal = fieldValue[PACKAGE_DEAL][0];
		}
		switch(pDeal){
			case SERVICE_TYPES.scan_shooting_3d:{
				if(fieldValue[SQUARE] === '50'){
					price = '5000'
				} else if (fieldValue[SQUARE] === '100'){
					price = '7000'
				}else if (fieldValue[SQUARE] === '150'){
					price = '9000'
				}else if (fieldValue[SQUARE] === '200'){
					price = '11000'
				}
				text = {
					title:`
						за ${price}р.
						в течении одного рабочего дня
					`, 
					descr:'Облако точек и сферические фотопанорамы.'
				}
				break;
			}
			case SERVICE_TYPES.cad_bim_3d:{
				if(fieldValue[SQUARE] === '50'){
					price = '8900'
				} else if (fieldValue[SQUARE] === '100'){
					price = '11900'
				}else if (fieldValue[SQUARE] === '150'){
					price = '14900'
				}else if (fieldValue[SQUARE] === '200'){
					price = '18900'
				}
				text = {
					title:`
						за ${price}р.
						в течении двух рабочих дней
					`, 
					descr:'3D модель, 2D план, облако точек и сферические фотопанорамы'
				}
				break;
			}
			case SERVICE_TYPES.cad_2d:{
				if(fieldValue[SQUARE] === '50'){
					price = '7900'
				} else if (fieldValue[SQUARE] === '100'){
					price = '10900'
				}else if (fieldValue[SQUARE] === '150'){
					price = '13900'
				}else if (fieldValue[SQUARE] === '200'){
					price = '17900'
				}
				text = {
					title:`
						за ${price}р.
						в течении двух рабочих дней
					`, 
					descr:'2D план, где будут указаны все цепочки размеров, высоты проемов'
				
				}
				break;
			}
			default:{
				break;
			}
		}
		if(fieldValue.squareSelect === FORM_FIELDS.squareSelect.options[4]){
			text ={
				title:'Расчитывается индивидуально',
				//descr:'чтобы вам стали доступны тарифные планы'
			};
		}
		return text;
	}
	const getContentText = () => {
		if(!fieldValue[PACKAGE_DEAL] || !fieldValue[SQUARE]){
			return{
				title:'Выберите площадь объекта',
				descr:'чтобы вам стали доступны тарифные планы'
			}
		}
		let pDeal = fieldValue[PACKAGE_DEAL];
		// cause of clickboxes
		if(user.role === USER_ROLE){
			pDeal = fieldValue[PACKAGE_DEAL][0];
		}
		const costObj = calculateCost({
			square:fieldValue[SQUARE],
			name:pDeal,
			floors:fieldValue[FLOORS],
		});
		dispatch(actions.addFieldAction({id: COST, value:costObj.fullPrice}));
		if(

			fieldValue.squareSelect 
			=== 
			FORM_FIELDS.squareSelect.options[FORM_FIELDS.squareSelect.options.length-1]
			||
			fieldValue[FLOORS_SELECT] 
			=== 
			FORM_FIELDS[FLOORS_SELECT].options[FORM_FIELDS[FLOORS_SELECT].options.length-1]
		){
			costObj.title = 'Расчитывается индивидуально';
			costObj.forSquareMeter = undefined;
			dispatch(actions.addFieldAction({id: COST, value:+fieldValue[COST]}));
			if(user.role!==ADMIN_ROLE && user.role!==MANAGER_ROLE){
				dispatch(actions.addFieldAction({id: COST, value:null}));
			}
		}
		return {...costObj}
	}
	useEffect(()=>{
		setContent(
			{...getContentText()}
		)
	},[
		fieldValue[PACKAGE_DEAL], 
		fieldValue[SQUARE], 
		fieldValue[FLOORS_SELECT],
		fieldValue[FLOORS],
		fieldValue.squareSelect,
		fieldValue[COST]
	])
	return(
		<div className='form__create-deal-cost' >
			<h5 className='cost__title' style={{marginTop: '5px', color:'#0094cc'}}>
				{
					content.title
				}
			</h5>
			{
				content?.forSquareMeter && user?.role !== USER_ROLE
				?(
					<p className='cost__squareMeter' >
						Цена за кв.м <b>{content.forSquareMeter}</b>
					</p>
				)
				:''
			}
			<p className='cost__descr' >
				{
					content.descr
				}
			</p>
		</div>
	)
}
export default CreateDealCost;