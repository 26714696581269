import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import actions from "../../../../../../store/reducers/actions"
import { REGISTER_FORM } from "../../../../../../utils/classes/Form"
import { ADMIN_ROLE, DATA_NAMES, FORM_FIELDS, MANAGER_ROLE, REGISTER_CLIENT_DEAL_ROUTE, REGISTER_CLIENT_ROUTE } from "../../../../../../utils/consts"
import { BRAND, FIRSTNAME, PHONE, USER_INFO } from "../../../../../../utils/consts/OBJECT_FIELDS"
import sleep from "../../../../../../utils/sleep"
import DataTemplate from "../../../../dataTemplate/DataTemplate"
import DataTemplateItem from "../../../../dataTemplate/dataTemplateItem/DataTemplateItem"
import FormBtn from "../../../../formBtn/FormBtn"
import ModalWindow from "../../../../modalWindow/ModalWindow"
import RegisterForm from "../../../RegisterForm"

const CREATE_USER ='CREATE_USER';
const SEARCH_USER='SEARCH_USER';
const CreateDeal = ({setModalVisible, setFormPath}) => {
	const {role} = useSelector(state => state.userReducer.user)
	const fields = useSelector(state => state.fieldValueReducer.fields)
	const dispatch = useDispatch();
	const [modalWindowVisible, setModalWindowVisible] = useState(false);
	const [fieldSpec, setFieldSpec] = useState('')
	const [hasItem, setHasItem] = useState(false);
	const navigate = useNavigate();
	const beforeFieldBtnHandler = (spec) => {
		setFieldSpec(spec);
		setModalWindowVisible(true);
	}
	const getFieldBySpec = (spec) => {
		switch(spec){
			case CREATE_USER:
				return <RegisterForm formPath={REGISTER_CLIENT_DEAL_ROUTE.path}/>
			case SEARCH_USER:
				return (
					<DataTemplate
						dataName={DATA_NAMES.users.id}
						queryParams={[PHONE, FIRSTNAME, BRAND]} 
						listType='usersList--clickable'
						modification={'clients'}
					/>
				)
			default:
				return ''
		}
	}
	const getBeforeFields = () => {
		switch(role){
			case ADMIN_ROLE:
			case MANAGER_ROLE:
				if(hasItem){
					return (
						<div className='form__create-deal__user-container' >
							<DataTemplateItem 
								dataItem={fields[USER_INFO]}
								key={fields[USER_INFO].id}
								dataName='users'
							/>
							<span className='form__create-deal__user-span' onClick={
							()=>{
								setHasItem(false);
								dispatch(actions.rmFieldAction(USER_INFO))
							}}>выбрать заново</span>
						</div>
					)

				}
				return(
					<>
						{
							modalWindowVisible
							?(
								<ModalWindow
									visible={modalWindowVisible} 
									setVisible={setModalWindowVisible}
									layerModal={true}
									option='modal__user-search'
									modification='read-only'
								>
									{
										getFieldBySpec(fieldSpec)
									}
									<FormBtn handleClick={()=>setModalWindowVisible(false)}>
										Выбрать
									</FormBtn>
								</ModalWindow>
							)
							:''
						}
						<div className='form__create-deal__btn-wrap'>
							<FormBtn 
								handleClick={async ()=>{
									//await setModalVisible(false);
									console.log(setFormPath)
									await setFormPath(REGISTER_CLIENT_ROUTE.path)
									//navigate('../../' + REGISTER_CLIENT_ROUTE.path, {replace:true})
								}}
							>
								Создать клиента
							</FormBtn>
							<FormBtn 
								handleClick={()=>beforeFieldBtnHandler(SEARCH_USER)}
							>Выбрать из списка</FormBtn>
						</div>
					</>
				)
			default:
				return (<></>)
		}
	}
	useEffect(()=>{
		if(fields['userInfo'] && !modalWindowVisible){
			setHasItem(true)
		}
	},[modalWindowVisible])
	useEffect(()=>{
		return(()=>{
			console.log('added on unmount')
		})
	},[])
	return (
		<>
			{
				getBeforeFields()
			}
		</>
	)
}
export default CreateDeal;