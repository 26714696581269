import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFieldValue } from "../../../../utils/checkers/getFieldValue";
import { FORM_FIELDS, USER_ROLE } from "../../../../utils/consts";
import { ADVANCE_PAYMENT, COST, DEMONSTRATION_LINK, DEMO_LINK, DOCUMENTS, ID, PAY_METHOD, PROJECT_DIGITALIZATION_LINK, PROJECT_LINK, REST_PAYMENT, STATUS, STATUS_ID } from "../../../../utils/consts/OBJECT_FIELDS";
import { getStatusObject } from "../../../../utils/getStatusId";
import FormBtn from "../../formBtn/FormBtn";
import OrderComments from "../OrderComments/OrderComments";
import OrderItemList from "../OrderItemList/OrderItemList"
import PayBtn from "../OrderPayment/PayBtn/PayBtn";
import OrderDocuments from "./OrderDocuments/OrderDocuments";
import OrderLinks from "./OrderLinks/OrderLinks";
import OrderLoyality from "./OrderLoyality/OrderLoyality";
import OrderUserInfo from "./OrderUserInfo/OrderUserInfo";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const OrderItemInfo = ({orderItem, children}) => {
	const {user} = useSelector(state => state.userReducer);
	//const statusObj = getStatusObject({id: orderItem.statusId})
	//const demoLink = getFieldValue(orderItem[FORM_FIELDS.demoLink.id]);
	//const projectLink = getFieldValue(orderItem[FORM_FIELDS.projectLink.id]);
	const { width} = useWindowDimensions();
	const [addedVisible, setAddedVisible] = useState(false);
	const [isInfoVisible, setInfoVisible,] = useState(
		user.role !== USER_ROLE || !orderItem?.getValue(DEMO_LINK)
		?true
		:false
	);
	const handleClick = () => {
		if(isInfoVisible){
			setInfoVisible(false)
		} else{
			setInfoVisible(true)
		}
	}
	return(
		<>
			<div className="orderItem__container">
				<div className='orderItem__header'>
					<h4 className ='orderItem__header-title'>{orderItem.getFieldText(ID)}</h4>
				</div>
				<div className='orderItem__body-wrap'>
					<div className='orderItem__body'>
						<div className="orderItem__list-container">
							<div className="list__title-container">
								<h4 className ='orderItem__list-title'>Информация о сделке</h4>
								<br/>
								<br/>
								<br/>
								{
									user.role === USER_ROLE && orderItem?.getValue(DEMO_LINK)
									?<FormBtn spec={['btn--arrow']} className='btn--collapse-list' handleClick={handleClick}/>
									:<></>
								}
							</div>
							{
								isInfoVisible
								?<OrderItemList
									order={orderItem}
									listType='allContentFields'
									/>
								:<></>
							}
						</div>

						{
							isInfoVisible
							?<div className="orderItem__documents-container">
							{
								orderItem.getValue(DOCUMENTS)?.length > 0
								?(
									<>
										<h4 className="orderItem__documents-title">Документы сделки</h4>
										<OrderDocuments docs={orderItem.getValue(DOCUMENTS)}/>
									</>
								)
								:<></>
							}
							</div>
							:<></>
						}
						<div className="orderItem__links">
							<OrderLinks
								statusId={orderItem.getValue(STATUS_ID)}
								demoLink={orderItem.getValue(DEMO_LINK)}
								projectLink={orderItem.getValue(PROJECT_LINK)}
								digitalizationLink={orderItem.getValue(PROJECT_DIGITALIZATION_LINK)}
							/>
							{
								orderItem.getValue(DEMO_LINK)
								?<iframe className="orderItem__links-iframe" width="100%" height="100%" src={orderItem.getValue(DEMO_LINK)} allowFullScreen="yes" allowvr="yes" scrolling="no"/>
								:''
							}

						</div>
						<div className='orderItem__footer'>
							<span className="span__cost">{orderItem.getFieldText(COST)} </span>
							{orderItem.status === 'FUNDED' || orderItem.status === 'COMPLETED' ?
								'' : <span className="span__advance">{orderItem.getFieldText(ADVANCE_PAYMENT)}</span> }
							{orderItem.status === 'FUNDED' || orderItem.status === 'COMPLETED' ?
								'' : <span className="span__rest">{orderItem.getFieldText(REST_PAYMENT)}</span>}



							{/* <span className="span__payMethod">{orderItem.getFieldText(PAY_METHOD)}</span> */}
						</div>
						{
							addedVisible
							?(
								<>
									<OrderUserInfo orderId={orderItem.id} orderType={orderItem.status}/>
									<FormBtn handleClick={()=> setAddedVisible(false)}>скрыть</FormBtn>
								</>
							)
							:(
								user.role !== USER_ROLE
								?<FormBtn handleClick={()=> setAddedVisible(true)}>показать больше информации</FormBtn>
								:<></>
							)
						}
						{/* <OrderLoyality orderItem={orderItem} role={user.role}/> */}
						{ (width < 1023 && user.role === USER_ROLE) ?
							<PayBtn orderItem={orderItem}/>
							:<></>
						}
						{/* <PayBtn orderItem={orderItem}/> */}
						{children}
					</div>
				</div>
			</div>
			{ (width > 1023 && user.role === USER_ROLE) ?
					<PayBtn orderItem={orderItem}/>
					:<></>
			}
		</>
	)
}
export default OrderItemInfo
