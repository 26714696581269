import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DataItemPage from "../../../../pages/DataItemPage";
import { 
	ADMIN_ROLE, 
	DEALS_DONE_ROUTE, 
	DEALS_ROUTE, 
	MANAGER_DIGITAL_ROLE, 
	MANAGER_MODELLING_ROLE, 
	MANAGER_ROLE, USER_ROLE } from "../../../../utils/consts";
import { DEALS_CANCELED_ROUTE, CALENDAR_ROUTE } from "../../../../utils/consts/ROUTES";
import FormBtn from "../../formBtn/FormBtn";
import ModalWindow from "../../modalWindow/ModalWindow";
import OrderItemPage from "../../OrderItem/OrderItemPage";

const OrderControlPanel = (props) => {
	const {modalVisible, setModalVisible, order} = props
	const {role} = useSelector(state => state.userReducer.user)
	let {pathname} = useLocation();
	const reminderClickHandler = (reminderType) => {
		switch(reminderType){
			case MANAGER_MODELLING_ROLE:
				console.log('напоминалка моделирования')
				break
			case MANAGER_DIGITAL_ROLE:
				console.log('напоминалка оцифровки')
				break
			default:
				console.log('no case')
				break
		}
	}
	const getBtnsByRole = (userRole) => {
		switch(userRole){
			case ADMIN_ROLE:
			case MANAGER_ROLE:
				return(
					<>
						<FormBtn handleClick={() => reminderClickHandler(MANAGER_MODELLING_ROLE)}> Напомнить менеджеру моделирования</FormBtn>
						<FormBtn  handleClick={() => reminderClickHandler(MANAGER_DIGITAL_ROLE)}> Напомнить менеджеру оцифровки</FormBtn>
					</>
				)
			case MANAGER_MODELLING_ROLE:
			case MANAGER_DIGITAL_ROLE:
				return <></>
			default:
				return <></>
		}
	}
	const isEditable = () => {
		if(
			pathname === DEALS_ROUTE.path 
			|| 
			pathname === DEALS_CANCELED_ROUTE.path
			||
			pathname === DEALS_DONE_ROUTE.path
			||
			pathname === CALENDAR_ROUTE.path
		){
			return true
		} 
		return false
	}
	return(
		<>
		{
			modalVisible
			?(
				<ModalWindow 
					visible={modalVisible} 
					setVisible={setModalVisible} 
					option='position--right'
				>
					<OrderItemPage 
						orderItem={order} 
						role={role} 
						editable={isEditable()}
						setModalVisible ={setModalVisible}
					/>	
				</ModalWindow>
			)
			:''
		}
		</>
 	)
}
export default OrderControlPanel;