import { useSelector } from "react-redux";
import { isManager } from "../../../../../utils/checkers/isManager";
import { FORM_FIELDS, STATUS_AT_WORK_DIGITIZATION, STATUS_AT_WORK_MODEL, STATUS_COMPLETED, STATUS_COMPLETED_DIGITIZATION, STATUS_COMPLETED_MODEL, STATUS_FUNDED, STATUS_NEW, STATUS_PREPAID, USER_ROLE, STATUS_AWAITING_PAYMENT } from "../../../../../utils/consts";
import {orderNames, PAYMENT_LINK, PROJECT_DIGITALIZATION_LINK} from "../../../../../utils/consts/OBJECT_FIELDS";
import FormBtn from "../../../formBtn/FormBtn";

const OrderLinks = (props) => {
	const {statusId, projectLink, demoLink, digitalizationLink} = props
	const {role} = useSelector(state => state.userReducer.user);
	const getDigLink = () => {
		if(digitalizationLink){
			return <a href={digitalizationLink} className='btn' target='_blank'>{orderNames[PROJECT_DIGITALIZATION_LINK]}</a>
		} else{
			return <span className=' btn project_ref project_ref--disabled'>{orderNames[PROJECT_DIGITALIZATION_LINK]}</span>
		}
	}
	const getLinkModifications = (statusId, projectLink, demoLink) => {
		// prepaid -> atWork -> paid
		//ссылка на оплату доступна if(demoLink && COMPLETED_MODEL) -> btnComponent
		//if full_paid -> getAllLinks
		// if full_paid -> show project link -> else demoLink
		switch (statusId){
			case STATUS_NEW.id:
			case STATUS_AWAITING_PAYMENT.id:
				return <FormBtn className={'btn'} disabled={true}>Скачать проект</FormBtn>
			case STATUS_PREPAID.id:
			case STATUS_AT_WORK_DIGITIZATION.id:
			case STATUS_COMPLETED_DIGITIZATION.id:
			case STATUS_AT_WORK_MODEL.id:
			case STATUS_COMPLETED_MODEL.id:{
				if(
					projectLink
					&&
					isManager(role)
				){
					return <a href={projectLink} className='btn' target='_blank'>{FORM_FIELDS.projectLink.innerText}</a>
				}
				if(!demoLink){
					return  <FormBtn className={'btn'} disabled={true}>{FORM_FIELDS.demoLink.innerText}</FormBtn>
				}
				return <a href={demoLink} className='btn' target='_blank'>{FORM_FIELDS.demoLink.innerText}</a>
			}
			case STATUS_COMPLETED.id:
			case STATUS_FUNDED.id:
				if(!projectLink){
					return <FormBtn className={'btn'} disabled={true}>{FORM_FIELDS.projectLink.innerText}</FormBtn>
				}
				if(role === USER_ROLE){
					return (
						<>
							<h4 className="orderItem__links-title">Демонстрация проекта доступна в течении 30 дней</h4>
							<a href={projectLink} className='btn' target='_blank'>
								Скачать проект
							</a>

						</>)
				}
				return <a href={projectLink} className='btn' target='_blank'>{FORM_FIELDS.projectLink.innerText}</a>;
			default:
				return''
		}
	}
	return (
		<>
			{
				getLinkModifications(statusId, projectLink, demoLink)
			}
			{
				isManager(role)
				? getDigLink()
				:''
			}
		</>
	)
}
export default OrderLinks
