const OrderItemUser = (order) => {
	return(
		<ul className = 'userOrders__list'>
			<li>
				{order.status.name}: {order.status.description}
			</li>
			<li>
				От: {order.userInfo.firstname} {order.userInfo.lastname}
			</li>
			<li>
				Адрес: {order.content['address'].description.join(' ')}
			</li>
			<li>
				{order.content['representativePhone'].name}: {order.content['representativePhone'].description}
			</li>
			<li>
				{order['cost'].name}: {order['cost'].description}
			</li>
		</ul>
	)
}
export default OrderItemUser;