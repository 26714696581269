import FormTemplate from "../../FormTemplate";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FORM_FIELDS, LOGIN_ROUTE, REGISTER_ROUTE, SUCCESS_CODE, USER_ROLE, USER_ROUTE } from "../../../utils/consts";
import FormBtn from "../formBtn/FormBtn";
import { useDispatch, useSelector } from "react-redux";
import { checkCode, sendLoginData } from "../../../http/userAPI";
import { sendData } from "../../../utils/sendData";
import actions from "../../../store/reducers/actions";
import tempAlert from "../../../utils/tempAlert";
import { getSortedList } from "../../../utils/getSortedList";
import { LOGIN_FORM, RAW_FIELDS_TYPE } from "../../../utils/classes/Form";
import User from "../../../utils/classes/User";
import { PHONE, SMS_PASSWORD } from "../../../utils/consts/OBJECT_FIELDS";
import Timer from "../Timer/Timer";
import FormComponent from "../../FormComponent";
import { BLOCK_TYPE, FORM_BLOCKS } from "../../../utils/consts/FORM_FIELDS";
import { updFormFieldsAction } from "../../../store/reducers/FormReducer";
import getFields from "../../../utils/formUtils/getFields";
import { hasItemById } from "../../../utils/getById";
const DELAY = 30000;
const LoginForm = ({role}) =>{
	const fieldValue = useSelector(state => state.fieldValueReducer.fields)
	const userValues = useSelector(state => state.userReducer.user);
	// const NOW_IN_MS = new Date().getTime();
	// const [countTime, setCountTime] = useState(NOW_IN_MS);
	const [timeLeft, setTimeLeft] = useState(0);
	const [noTel, setNoTel] = useState(true);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const form = useSelector(state => state.formReducer)
	const switchFields =()=>{
		if(!hasItemById(form.blocks, PHONE)){
			dispatch(actions.addFormBlocksAction([
				{
					content:FORM_BLOCKS[PHONE]
				}
			]))
		} else{
			dispatch(actions.rmFormBlocksAction([{id:PHONE}]))
		}
		// check if already exists
		if(!hasItemById(form.blocks, SMS_PASSWORD)){
			//ADD SMS
			dispatch(actions.addFormBlocksAction([
				{
					content:FORM_BLOCKS[SMS_PASSWORD]
				}
			]))

		}else{
			dispatch(actions.rmFormBlocksAction([{id:SMS_PASSWORD}]))
		}
	}
	const checkPhone = async (checkType) => {
		let response = null;
		try{
			response = await sendData(sendLoginData, {
				formFields: [FORM_FIELDS.phone],
				fieldValue:{[PHONE]: fieldValue[PHONE]}
			})
		} catch(e){
			console.log(e);
			tempAlert(e, 3000,['--invalid']);
		}
		setTimeLeft(new Date().getTime() + DELAY)
		if(response.success === SUCCESS_CODE){
			setNoTel(false);
			if(checkType !== 'code'){
				switchFields();
			}
			//switchFields();
		} else {
			tempAlert(response.message, 3000,['--invalid']);
		}
		// console.log(timeLeft)
		setTimeLeft(new Date().getTime() + DELAY)
	}
	const checkMessage = async () => {
		let response = null;
		const fieldsArr = getFields({FORM_TYPE:BLOCK_TYPE, FORM_FIELDS:form.blocks});
		try{
			response = await sendData(checkCode, {
				formFields: [...fieldsArr],
				fieldValue:{...fieldValue, role: userValues.role}
			})
		} catch(e){
			tempAlert(response?.message, 3000,['--invalid']);
		}
		if(response.success === SUCCESS_CODE){
			const {data} = response;
			let role = '';
			if(data.role === 0){
				role = USER_ROLE;
			} else {
				role = data.role;
			}
			const userItem = new User({
				id: data.id,
				role,
				...data
			})
			//fetch orders after login
			dispatch(actions.setUserAction(userItem))
			dispatch(actions.setUserIsAuthAction(true))
			navigate('../../' + USER_ROUTE.path, {replace:true})
		} else {
			tempAlert(response.message,3000,['--invalid'])
		}
	}
	useEffect(()=>{
		//setFormFields([...fieldSetter()])
		//updating fields when state has change
		// dispatch(actions.updFormFieldsAction({
		// 	fields:[
		// 		{
		// 			...FORM_FIELDS[SMS_PASSWORD],
		// 			disabled: noTel,
		// 		},
		// 		{
		// 			...FORM_FIELDS[PHONE],
		// 			disabled:!noTel,
		// 			currValue: userValues[FORM_FIELDS.phone.id]
		// 		}
		// 	]
		// }))
	},[noTel])
	useEffect(()=>{
		if(userValues[FORM_FIELDS.phone.id]){
			//setNoTel(false)
			setTimeLeft(new Date().getTime() + DELAY)
			//function that send sms from server
		}
	},[])
	return (
		<FormComponent
			formClassNames={['form__login']}
			formType={LOGIN_FORM}
			blocks={[
				{
					...FORM_BLOCKS[PHONE],
					fields:[
						{
							...FORM_FIELDS[PHONE],
							disabled:!noTel,
							currValue: userValues[FORM_FIELDS.phone.id]
						}
					]
				}
			]}
		>
			{
				noTel
				?<FormBtn type='submit' handleClick={() => checkPhone()}>Запросить смс код</FormBtn>
				:(
					<>
						<span onClick={()=>{setNoTel(true); switchFields()}} style={{textAlign:'center', cursor:'pointer', fontWeight:'bold', textDecoration:'underline'}}>Ввести телефон заново</span>
						<FormBtn type='submit' handleClick={()=> checkMessage()}>Вход</FormBtn>
						{
							timeLeft !== 0
							?(
								<FormBtn className='--disabled'>
									<div style={{display:'flex', alignItems:'center',width:'105%',maxHeight:'100%', justifyContent:'space-between'}}>
										<p style={{margin:'0',}}>Запросить код через</p>
										<Timer timerEndCall={() => setTimeLeft(0)} targetDate={timeLeft}/>
									</div>
								</FormBtn>
							)
							:(
								<FormBtn handleClick={() =>checkPhone('code')}>
									Запросить код заново
								</FormBtn>
							)
						}
					</>
				)

			}
			<div  className='form__link-container'>
				<Link style={{fontSize:'20px'}} to={'../../'+REGISTER_ROUTE.path} className='form__link'>Нет аккаунта? Зарегистрироваться!</Link>
			</div>
		</FormComponent>
	)
}
export default LoginForm;
// const LoginForm = ({role}) =>{
// 	const fieldValue = useSelector(state => state.fieldValueReducer.fields)
// 	const userValues = useSelector(state => state.userReducer.user);
// 	// const NOW_IN_MS = new Date().getTime();
// 	// const [countTime, setCountTime] = useState(NOW_IN_MS);
// 	const [canRequire, setCanRequire] = useState(false);
// 	const [noTel, setNoTel] = useState(true);
// 	const dispatch = useDispatch();
// 	const navigate = useNavigate();
// 	const [title,setTitle] = useState({
// 		name:'Форма входа',
// 		info:'Введите данные для входа'
// 	})
// 	const fieldSetter = () => {
// 		if(role){
// 			return[
// 				{
// 					...FORM_FIELDS.phone,
// 					disabled:!noTel,
// 					currValue: userValues[FORM_FIELDS.phone.id]
// 				},
// 				{
// 					...FORM_FIELDS['sms__password'],
// 					disabled: noTel,
// 				}
// 			]
// 		}
// 	}
// 	const checkPhone = async() => {
// 		let response = null;
// 		try{
// 			response = await sendData(sendLoginData, {
// 				formFields: [FORM_FIELDS.phone],
// 				fieldValue:{[PHONE]: fieldValue[PHONE]}
// 			})
// 		} catch(e){
// 			console.log(e);
// 			tempAlert(e, 3000,['--invalid']);
// 		}
// 		if(response.success === SUCCESS_CODE){
// 			setNoTel(false)
// 			//setCountTime(new Date().getTime() + 30000)
// 		} else {
// 			tempAlert(response.message, 3000,['--invalid']);
// 		}
// 	}
// 	const checkMessage = async () => {
// 		let response = null;
// 		try{
// 			response = await sendData(checkCode, {
// 				formFields: [...formFields],
// 				fieldValue:{...fieldValue, role: userValues.role}
// 			})
// 		} catch(e){
// 			tempAlert(e, 3000,['--invalid']);
// 		}
// 		if(response.success === SUCCESS_CODE){
// 			const {data} = response;
// 			let role = '';
// 			if(data.role === 0){
// 				role = USER_ROLE;
// 			} else {
// 				role = data.role;
// 			}
// 			const userItem = new User({
// 				id: data.id,
// 				role,
// 				...data
// 			})
// 			//fetch orders after login
// 			dispatch(actions.setUserAction(userItem))
// 			dispatch(actions.setUserIsAuthAction(true))
// 			navigate('../../' + USER_ROUTE.path, {replace:true})
// 		} else {
// 			tempAlert(response.message,3000,['--invalid'])
// 		}
// 	}
// 	const [formFields, setFormFields] = useState([...fieldSetter()])
// 	useEffect(()=>{
// 		setFormFields([...fieldSetter()])
// 	},[noTel])
// 	useEffect(()=>{
// 		if(userValues[FORM_FIELDS.phone.id]){
// 			setNoTel(false)
// 			//function that send sms from server
// 		}
// 	},[])
// 	return (
// 		<FormTemplate
// 			formTemplate={
// 				{
// 					title:title,
// 					fields:formFields,
// 					type:RAW_FIELDS_TYPE
// 				}
// 			}
// 			className='form__login'
// 		>
// 			{
// 				noTel
// 				?<FormBtn handleClick={()=> checkPhone()}>Запросить смс код</FormBtn>
// 				:(
// 					<>
// 						<FormBtn handleClick={()=> checkMessage()}>Вход</FormBtn>
// 						{
// 							!canRequire
// 							?(
// 								<FormBtn className='--disabled'>
// 									<div style={{display:'flex', alignItems:'center',width:'105%',maxHeight:'100%', justifyContent:'space-between'}}>
// 										<p style={{margin:'0',}}>Запросить код через</p>
// 										<Timer setCanRequire={setCanRequire} targetDate={new Date().getTime() + 30000}/>
// 									</div>
// 								</FormBtn>
// 							)
// 							:(
// 								<FormBtn handleClick={() => checkPhone()}>
// 									Запросить код заново
// 								</FormBtn>
// 							)
// 						}
// 					</>
// 				)

// 			}
// 			<div  className='form__link-container'>
// 				<Link to={'../../'+REGISTER_ROUTE.path} className='form__link'>Нет аккаунта? Зарегистрироваться!</Link>
// 			</div>
// 		</FormTemplate>
// 	)
// }
// export default LoginForm;
