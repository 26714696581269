import {
	REGISTER_CLIENT_DEAL_ROUTE,
	ORDER_ROUTE,
	SERVER_ROUTES,
	HOME_ROUTE,
	ABOUT_ROUTE,
	USER_ROUTE,
	REGISTER_ROUTE,
	LOGIN_ROUTE,
	ORDERS_ROUTE,
	ORDER_CURRENT_ROUTE,
	ORDER_CREATE_ROUTE,
	LOYALITY_ROUTE,
	DEAL_ROUTE,
	DEALS_ROUTE,
	DEALS_DONE_ROUTE,
	DEAL_DONE_ROUTE,
	CLIENTS_ROUTE,
	CLIENT_ROUTE,
	CALENDAR_ROUTE,
	REGISTER_ADMIN_ROUTE,
	REGISTER_MANAGER_ROUTE,
	REGISTER_CLIENT_ROUTE,
	ANALYTICS_ROUTE,
	QA_ROUTE,
	OFFER_ROUTE,
	SMS_DISTRIBUTION_ROUTE,
	REACT_APP_API_URL,
	ALL_ROUTES
} from './consts/ROUTES'

import {
	USER_ROLE,
	ADMIN_ROLE,
	MANAGER_DIGITAL_ROLE,
	MANAGER_ROLE,
	MANAGER_MODELLING_ROLE
} from './consts/ROLES'

import  {
	STATUS_PAIRS,
	STATUS_ID_SERVER_NAME,
	STATUS_NEW,
	STATUS_AWAITING_PAYMENT,
	STATUS_PREPAID,
	STATUS_FUNDED,
	STATUS_AT_WORK_DIGITIZATION,
	STATUS_COMPLETED_DIGITIZATION,
	STATUS_AT_WORK_MODEL,
	STATUS_COMPLETED_MODEL,
	STATUS_COMPLETED,
	STATUS_ID_NAME
} from './consts/STATUS'

import  {
	MODELS
} from './consts/MODELS'

import {FORM_FIELDS} from './consts/FORM_FIELDS'

import {DATA_NAMES} from './consts/DATA_NAMES'


const SUCCESS_CODE = 0;
export {
	SUCCESS_CODE,
	REGISTER_CLIENT_DEAL_ROUTE,
	ORDER_ROUTE,
	SERVER_ROUTES,
	HOME_ROUTE,
	ABOUT_ROUTE,
	USER_ROUTE,
	REGISTER_ROUTE,
	LOGIN_ROUTE,
	ORDERS_ROUTE,
	ORDER_CURRENT_ROUTE,
	ORDER_CREATE_ROUTE,
	LOYALITY_ROUTE,
	DEAL_ROUTE,
	DEALS_ROUTE,
	DEALS_DONE_ROUTE,
	DEAL_DONE_ROUTE,
	CLIENTS_ROUTE,
	CLIENT_ROUTE,
	CALENDAR_ROUTE,
	REGISTER_ADMIN_ROUTE,
	REGISTER_MANAGER_ROUTE,
	REGISTER_CLIENT_ROUTE,
	ANALYTICS_ROUTE,
	QA_ROUTE,
	OFFER_ROUTE,
	SMS_DISTRIBUTION_ROUTE,
	REACT_APP_API_URL,
	ALL_ROUTES,
	USER_ROLE,
	ADMIN_ROLE,
	MANAGER_DIGITAL_ROLE,
	MANAGER_ROLE,
	MANAGER_MODELLING_ROLE,
	STATUS_PAIRS,
	STATUS_ID_SERVER_NAME,
	STATUS_AWAITING_PAYMENT,
	STATUS_NEW,
	STATUS_PREPAID,
	STATUS_FUNDED,
	STATUS_AT_WORK_DIGITIZATION,
	STATUS_COMPLETED_DIGITIZATION,
	STATUS_AT_WORK_MODEL,
	STATUS_COMPLETED_MODEL,
	STATUS_COMPLETED,
	STATUS_ID_NAME,
	MODELS,
	FORM_FIELDS,
	DATA_NAMES
}
/*export {
	REGISTER_CLIENT_DEAL_ROUTE,
	ORDER_ROUTE,
	SUCCESS_CODE,
	SERVER_ROUTES,
	SERVICE_TYPES,
	STATUS_ID_NAME,
	ADMIN_ROLE,
	USER_ROLE,
	MANAGER_ROLE,
	MANAGER_SECONDARY_ROLE,
	MANAGER_MODELLING_ROLE,
	MANAGER_DIGITAL_ROLE,
	HOME_ROUTE,
	ABOUT_ROUTE,
	USER_ROUTE,
	REGISTER_ROUTE,
	LOGIN_ROUTE,
	ORDERS_ROUTE,
	ORDER_CURRENT_ROUTE,
	ORDER_CREATE_ROUTE,
	LOYALITY_ROUTE,
	DEAL_ROUTE,
	DEALS_ROUTE,
	DEALS_DONE_ROUTE,
	DEAL_DONE_ROUTE,
	CLIENTS_ROUTE,
	CLIENT_ROUTE,
	CALENDAR_ROUTE,
	REGISTER_ADMIN_ROUTE,
	REGISTER_MANAGER_ROUTE,
	REGISTER_CLIENT_ROUTE,
	ANALYTICS_ROUTE,
	QA_ROUTE,
	OFFER_ROUTE,
	SMS_DISTRIBUTION_ROUTE,
	REACT_APP_API_URL,
}*/
