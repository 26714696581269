import { useEffect } from "react";

const SelectorInput = (props) => {
	const {
		requiredProps, 
		options,
		inputRoot
	} = props
	const {
		id, 
		value, 
		setValue, 
		disabled, 
		defaultValue,
		innerText,
		required,
		currValue,
	} = requiredProps;
	useEffect(()=>{
		setValue(defaultValue)
	},[options])
	return(
		<select 
			className={`${inputRoot.join(' ')} form__field--selector`}
			onChange={(e)=> setValue(e.target.value)}
			value={value}
			id={id}
			name={id}
			required={required}
			disabled={disabled}
			defaultValue={defaultValue}
			//{...props}
		>
			{
				//<option selected disabled hidden>{defaultValue}</option>
			}
			{

				options.map(optionItem => {
					if(optionItem === currValue){
						return <option key={optionItem} selected={true}>{optionItem}</option>	
					}
					return <option key={optionItem} >{optionItem}</option>	
				})
			}
		</select>
	)
}
export default SelectorInput