const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address"//"https://kladr-api.com/api.php";
const token = "4bfdb6dfefc72fc04edcccf76fe9095b3e81eca7";
const secret = "752234894ad9f86d00406d30fc66c2c228153db0";
export const fetchAddress = async (props) => {
	const {query, city='МСК'} = props
	let region = ''
	switch(city){
		case 'МСК':{
			region='Москва'
			break;
		}
		case 'СПБ':{
			region = 'Санкт-Петербург'
			break;
		}
		case 'ЛО':{
			region = 'Ленинградская'
			break;
		}
		case 'МО':{
			region = 'Московская'
			break;
		}
		default:
			break;
	}
	const options = {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Token " + token,
			"X-Secret": secret
		},
		body: JSON.stringify(
			{
				query,
				locations:[{region}],
				count:5
			}
		)
	}
	const response = await fetch(url, options)
	.then(response => response.text())
	.then(result => result)

	return {
		data:JSON.parse(response).suggestions
	};
}