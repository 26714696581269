import { STATUS_ID_SERVER_NAME } from "./consts";
import { ADVANCE_PAYMENT, BONUS_POINTS, PROJECT_DIGITALIZATION_LINK, REPRESENTATIVE_PHONE, REST_PAYMENT } from "./consts/OBJECT_FIELDS";
import { roleNames, ROLES } from "./consts/ROLES";
import randFieldIdGen from "./randFieldIdGen";

function randomDate(start, end) {
	return new Date(start.getTime() + Math.floor(Math.random() * (end.getTime() - start.getTime())));
  }  

export const getUsers = () => {
	//const order = 
	const list = [];
	for(let i=0; i<300; i++){
		list.push({
			email: `ex@${Math.floor(Math.random()*2000)}`,
			phone: `+7999${Math.floor(Math.random()*2000000)}`,
			firstname: `${randFieldIdGen(2)}`,
			lastname: "aaaa",
			role: ROLES[Math.floor(Math.random()*5)],
			avatar: null,
			brand:`brand-${Math.floor(Math.random()*2000)}`,
			kpp: "1",
			inn: "777777",
			rs: "1",
			bik: "444",
			// id:`${randFieldIdGen(1)}`,
			id: Math.floor(Math.random()*30),
			bonusPoints:111,
			totalPaid:111
		})
	}
	return [...list]
}
export const getByOffset = (offset,limit,sortedArray, defaultArray) => {
	const finalArray = []
	for(let i = offset; i < limit+offset && i< sortedArray.length;i++){
		finalArray.push(sortedArray[i])
	}
	return finalArray
}
export const getByLimitAndPage = ({limit, page, sort_by, filter, array}) =>{
	let offset = page * limit - limit;
	const queryParams = sort_by.split('&');
	const list = [...array];
	let sortedArray = []
		if(!filter || filter === ''){
			return getByOffset(offset,limit,list, array)
		}
		try{
			for(let i =0; i< list.length; i++){
				for(let j =0; j< queryParams.length; j++){
					if(!list[i][queryParams[j]]){
						console.log('wrong field parameter')
						return
					}
					let itemValue = list[i][queryParams[j]];
					const flag = itemValue.toLowerCase().includes(filter.toLowerCase());
					if(flag){
						sortedArray.push(list[i]);
						break;
					}
				}
			}
			//return sortedArray
		} catch(e){
			console.log(e)
		}
		const finalArray = getByOffset(offset,limit,sortedArray, array)
	return [...finalArray];
}
export const getOrders = () => {
	const order = {
		id: 0,
		userId: 1,
		status: STATUS_ID_SERVER_NAME[0],
		packageDeal: "2-D чертежи в CAD редакторах",
		city: "Москва",
		street: "1",
		house: "1",
		flat: "1",
		square: "230",
		floors: "11",
		desiredDate: '',
		desiredTime: "12:11",
		dateSending: null,
		representativeName: null,
		[REST_PAYMENT]:{
			amount:5000,
			payMethod:'баллами'
		},
		cost:10000,
		[BONUS_POINTS]:5999,
		[REPRESENTATIVE_PHONE]:+7999999999,
		[ADVANCE_PAYMENT]:{
			amount:5000,
			payMethod:'картой'
		},
		userInfo:{
			"id":1,
			"email":"bmuhtar07@gmail.com",
			"phone":"+79859644110",
			"firstname":"Первый",
			"lastname":"Пользователь",
			"role":"USER"
		},
		advancePaid: false,
		address:'Добавление сайта в вебмастеры поисковых систем ускорит индексацию вашего сайта. Для того, чтобы сайт проиндексировался максимально правильно',
		software: "Ревит 20",
		demoLink: 'https://gmail1240493.autodesk360.com/shares/public/SH919a0QTf3c32634dcfe399cf7327e4927f?mode=embed',
		projectLink: 'https://github.com',
		paymentLink:'https://github.com',
		comments:[],
		[PROJECT_DIGITALIZATION_LINK]:'https://aaaaaaaaa',
		//demonstrationLink:'https://gmail1240493.autodesk360.com/shares/public/SH919a0QTf3c32634dcfe399cf7327e4927f?mode=embed',
		documents:[
			{
				"name": "презентация продукта",
				"format":"pdf",
				"href":'https://unsplash.com/photos/I__QKQLMIKs/download?ixid=MnwxMjA3fDB8MXxzZWFyY2h8Nnx8YXJ0fGVufDB8fHx8MTY1NjU0NjkzMg&force=true',
				"id": `${Math.random()*200}`,
				size: 0.1
			},
			{
				"name": "замерки",
				"format":"pdf",
				"href":'https://api.lk.veso.pro/21_22.jpg',
				"id":`${Math.random()*200}`,
				size: 0.1
			},
			{
				"name": "что-то",
				"format":"pdf",
				"href":'https://api.lk.veso.pro/21_22.jpg',
				"id":`${Math.random()*200}`,
				size: 0.5
			},
			{
				"name": "что-то",
				"format":"jpg",
				"href":'https://api.lk.veso.pro/21_22.jpg',
				"id": `${Math.random()*200}`,
				size: 0.5
			},
			{
				"name": "что-то",
				"format":"docx",
				"href":'https://api.lk.veso.pro/21_22.jpg',
				"id": `${Math.random()*200}`,
				size: 0.5
			},
			{
				"name": "что-то",
				"format":"img",
				"href":'https://api.lk.veso.pro/21_22.jpg',
				"id": `${Math.random()*200}`,
				size: 0.5
			}
		],
		//payMethod:'Картой'
	}

	const orders = []
	for(let i=0; i<10; i++){
		let orderId = Math.floor(Math.random()*500);
		const comments = [];
		for(let i=0; i<10; i++){
			let curr = randomDate(new Date('2022/04/01'),new Date('2022/04/30'));
			const res = `${curr.getHours()}:${curr.getMinutes()}`
			if(i%2 === 0){
				comments.push({
					id:Math.floor(Math.random()*500),
					comment:i+' это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. ',
					dealId: orderId,
					createdAt:res,
					userInfo:{
						id:1,
						role:'ADMIN',
						firstname:'Иван',
						lastname:'Иванович',
						phone:'+799999999'
					}
				})
			}else{
				comments.push({
					id:Math.floor(Math.random()*500),
					comment: i + ' это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. ',
					dealId: orderId,
					createdAt:res,
					userInfo:{
						id:188,
						role:'ADMIN',
						firstname:'Иван',
						lastname:'Иванович',
						phone:'+799999999'
					}
				})
			}
		}
		orders.push({
			...order, 
			id:orderId,
			dateSending:randomDate(new Date('2022/04/01'),new Date('2022/04/30')).toISOString().substring(0,10),
			desiredDate:randomDate(new Date('2022/01/01'),new Date('2022/10/30')).toISOString().substring(0,10),
			status:STATUS_ID_SERVER_NAME[Math.floor(Math.random()*9)],
			paymentLink: orderId,
			//status:STATUS_ID_SERVER_NAME[0],
			comments
			
		})
	}
	return orders
}