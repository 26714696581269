import { useState } from "react";
import AlertPage from "../../../../pages/AlertPage/AlertPage";
import PaymentForm from "../../Forms/PaymentForm/PaymentForm";
import { useSelector } from "react-redux";
import Header from "../../Header/Header";
const OrderPayment = () => {
	const {user} = useSelector(state => state.userReducer)
	const {isAuth} = useSelector(state => state.userReducer)
	const {width} = useSelector(state => state.windowReducer);
	console.log(user)
	return(
		<>
			<AlertPage title={
				width>1023
				?'страница оплаты'
				:''
				}
				preContent={
					!isAuth
					?<Header
						title={'страница оплаты'}
						buttons={[]}
						role={user.role}
						classNames={['header--USER', 'payment']}
					/>
					:<></>
				}
			>
				<PaymentForm/>
			</AlertPage>
		</>

	)
}
export default OrderPayment;
