import NavBar from "./NavBar"
import FormBtn from "../formBtn/FormBtn"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import ModalWindow from "../modalWindow/ModalWindow"
import CreateDealForm from "../Forms/CreateDealForm/CreateDealForm"
import classes from './NavBar.module.css'
import { getRoutes} from "../../../router";
import { ORDER_CURRENT_ROUTE } from "../../../utils/consts"
import { getRouteByPath } from "../../../utils/getRouteVar"
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import MobileNavbarController from "./MobileNavbar/MobileNavbarController"
import { logoutUser } from "../../../http/userAPI"
import actions from "../../../store/reducers/actions"
import { Link } from "react-router-dom"
const NavBarController = (props) => {
	const {classNames=[]} = props
	//const {currentOrder} = useSelector(state => state.userReducer.user)
	const {user, isAuth} = useSelector(state => state.userReducer)
	const rootClasses = [classes['navbar__wrap'], classes[`navbar__wrap--${user.role}`], ...classNames, 'navbar__wrap',`navbar__wrap--${user.role}`]
	const [isVisible, setIsVisible] = useState(false)
	const {height, width} = useWindowDimensions();
	const dispatch = useDispatch();
	const logout = () => {
		try{
			logoutUser().catch(e => e)
		}catch (e) {
			console.log(e)
		}finally {
			dispatch(actions.cleanUserAction())
			dispatch(actions.rmAllFieldsAction());
		}
	}
	return(
		<>
			{
				width < 1023
				?(
					<>
						<MobileNavbarController
							isVisible={isVisible}
							setIsVisible={setIsVisible}
						>
							{
								!isAuth
								? <></>
								: <FormBtn spec={['btn--custom-red']} handleClick={logout}>
									Выход
								</FormBtn>
							}
						</MobileNavbarController>
					</>
				)
				:(
					<div className={rootClasses.join(' ')}>
						<NavBar classNames={[`navbar--${user.role}`]}>
							{
								!isAuth
								? <Link to="/api/login" className={classes['navbar__link'] + ' btn'}>Войти</Link>
								: <FormBtn spec={['btn__logout']} handleClick={logout}>
									Выход
								</FormBtn>
							}
						</NavBar>
					</div>
				)
			}
		</>
	)
}
export default NavBarController
