import { useEffect } from "react";

const DateInput = (props) => {
	const {
		requiredProps,
		//input classes
		inputRoot,
	} = props

	const {
		id,
		value,
		setValue,
		required,
		disabled,
		innerText,
		defaultValue
	} = requiredProps;
	// useEffect(()=>{
	// 	if(!value){
	// 		setValue(getDefaultValue())
	// 	}
	// },[])
	const getDefaultValue = () => {
		let curr = new Date();
		curr.setDate(curr.getDate() + 1)
		return curr.toISOString().substring(0,10)
	}
	// value = xxxx-xx-xx
	return (
		<>
			<input
				className={`${inputRoot.join(' ')} form__field--date`}
				type='date'
				value={value}
				onChange={(e) => setValue(e.target.value)}
				id={id}
				disabled={disabled}
				required={required}
				min={getDefaultValue()}
			/>

		</>
	)
}
export default DateInput;
