const defaultState ={
	info:[],
	listBackup:[]
}
export const ADD_ITEM = 'ADD_ITEM';
export const RM_ITEM = 'RM_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const RM_ALL = 'RM_ALL';
export const addedFieldsReducer = (state = defaultState,  action) => {
	switch(action.type) {
		case ADD_ITEM:
			return {
				...state,
				info: [
					...state.info,
					action.payload
				],
				listBackup:[
					...state.info,
				]
			}
		case RM_ITEM: 
			return {
				...state,
				info: [...state.info.filter(item => item.id !== action.payload)],
				listBackup:[
					...state.info,
				]
			}
		case UPDATE_ITEM: 
			let indexOfItem = state.info.map( item => item.id).indexOf(action.payload.field.id);
			let updList = [...state.info];
			updList[indexOfItem] = {...action.payload.field}
			return {
				...state,
				info:[...updList],
				listBackup:[
					...state.info,
				]
			}
		case RM_ALL:
			return {
				...state,
				info:[],
				listBackup:[]
			}
		default:
			return state
	}
}

export const addInfoItemAction = (payload) => ({type:ADD_ITEM, payload})
export const rmInfoItemAction = (payload) => ({type:RM_ITEM, payload})
export const updateInfoItemAction = (payload) => ({type:UPDATE_ITEM, payload})
export const rmAllInfoItemAction = () => ({type:RM_ALL})