import React, {useEffect, useState} from 'react';
import FormBtn from '../../formBtn/FormBtn'
import classes from './DataTemplateItem.module.css'
import { Link} from "react-router-dom";
import actions from '../../../../store/reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import ModalWindow from '../../modalWindow/ModalWindow';
import { ADMIN_ROLE, FORM_FIELDS, MANAGER_ROLE, MANAGER_SECONDARY_ROLE } from '../../../../utils/consts';
import {roleNames} from  '../../../../utils/consts/ROLES'
import DataItemPage from '../../../../pages/DataItemPage';
import { getById } from '../../../../utils/getById';
import { getFieldValueById } from '../../../../utils/checkers/getFieldValue';
import { BRAND, EMAIL, FIRSTNAME, LASTNAME, PHONE, ROLE} from '../../../../utils/consts/OBJECT_FIELDS';
import UserDataFormAdmin from '../../Forms/UserDataForm/UserDataFormAdmin';
import { isAdmin } from '../../../../utils/checkers/isManager';
const DataTemplateItem = (props) => {
	const { dataName='', canEdit, dataItem, clickable=false, itemId, onClick, modification=''} = props
	const dispatch = useDispatch();
	if(!dataItem){
		//dataItem = getById()
	}
	//const link = `/api/${dataName}/${dataItem.id}`;
	const user = useSelector(state => state.userReducer.user)
	const [modalVisible, setModalVisible] = useState(false);
	const [editItemVisible, setEditItemVisible] = useState(false);
	let rights = 'default';
	if(user.role === ADMIN_ROLE){
		rights = 'extended';
	}
	const getItem =() => {
		switch(dataName) {
			case 'users':
				return (
					<>
						{
							modalVisible
							?(
								<ModalWindow 
									visible={modalVisible} 
									setVisible={setModalVisible} 
									option='modal__userInfo'
									//layerModal={true}
									modification='read-only'
								>
									<DataItemPage 
										dataName={dataName} 
										itemId={dataItem.id} 
										dataItem={dataItem}
									/>
								</ModalWindow>
							)
							:''
						}
						<ul className='content-list--user'>
							<li className='content-list--user__item'>{dataItem.getValue(FIRSTNAME)} {dataItem.getValue(LASTNAME)}</li>
							<li className='content-list--user__item'>{dataItem.getValue(PHONE)} </li>
							<li className='content-list--user__item'>{dataItem.getValue(EMAIL)}</li>
							<li className='content-list--user__item'>{roleNames[dataItem?.getValue(ROLE)]}</li>
							<li className='content-list--user__item'>{dataItem.getValue(BRAND)}</li>
						</ul>
						<FormBtn handleClick={(e)=>{
							e.stopPropagation();
							setModalVisible(true)}}>
							{
								!clickable
								?'подробнее'
								:'добавить'
							}
						</FormBtn>
						{
							canEdit && isAdmin(user.role)
							?<FormBtn 
								classNames={['--red']}
								handleClick={(e)=>{
									e.stopPropagation();
									setEditItemVisible(true)}
							}>
								изменить
							</FormBtn>
							:<></>
						}
					</>
				)
				// take dataItems in other folders(like components)
			default:
				return <span>nothing</span>
		}
	}
	// fields depends on role
	const rmDataItem = () => {
		dispatch(actions.rmDataItemAction({dataItemId: dataItem.id, dataName}));
		//dispatch(actions.asyncRmDataItemAction({dataItemId: dataItem.id, userId, dataName}));
	}
	
	return (
		<li className={classes['dataItem']} key={dataItem.id} /*onClick={onClickHandler}*/ onClick={onClick}> 
			{
				editItemVisible 
				&& 
				isAdmin(user.role)
				?<ModalWindow 
					visible={editItemVisible} 
					setVisible={setEditItemVisible}
					option={'modal__notification'}
					modalClasses={['modal--large']}
				>
					<UserDataFormAdmin
						setIsOnEdit={setEditItemVisible}
						userId={dataItem?.id}
						modification={modification}
					/>
				</ModalWindow>
				:<></>
			}
			{
				getItem()
			}
			{/*  delete btn for ADMIN -> 
				rights === 'extended'
				?(
					<div className={classes['btn-wrap']}>
						{<FormBtn 
							spec={['btn--interactive', 'btn--rm']} 
							typename="button" 
							handleClick={rmDataItem}
						/>}
					</div>
				)
				:''
			*/}
		</li>
	)
}
export default DataTemplateItem