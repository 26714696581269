import { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classes from './ListCollapse.module.css'
import { StyledEngineProvider } from "@mui/material";
/**
 * 
 * Accordion summary === Title
 * to override mui styles <StyledEngineProvider injectFirst>
 */
// works for single || multi items opened at once
// expandedByDefault - sets expanded by default items
const ListCollapse = (props) => {
	const { 
		defaultList,
		multiple=false, // can ExpandMultiple at once
		option, //for styling
		expandedByDefault=[] // ids of items,that expanded
	} = props;
	const [openedItems, setOpenedItems] = useState([...expandedByDefault])
	const [list, setList] = useState([...defaultList])
	const multipleHandler = (id) =>{
		let hasItem = false;
		for(let i=0; i<openedItems.length;i++){		
			if(openedItems[i] === id){
				hasItem = true;
			}
		}
		if(hasItem){
			setOpenedItems([...openedItems.filter(item => item !== id)])
		} else{
			setOpenedItems([...openedItems, id])
		}
		// можно добавить list item и использовать там отдельный state, внутри компонента
	}
	const singleHandler = (id) => {
		if(openedItems[0] === id){
			setOpenedItems([])
		}else{
			setOpenedItems([id])
		}
	}
	const handleChange =(id) => {
		if(multiple){
			multipleHandler(id)
		} else{
			singleHandler(id)
		}
	}
	const isExpanded = (id) => {
		for(let i=0; i<openedItems.length;i++){		
			if(openedItems[i] === id){
				return true;
			}
		}
		return false
	}
	// const handleChange = (panel) => (event, isExpanded) => {
	// 	setExpanded(isExpanded ? panel : false);
	//   };
	return(
		<div className={`list-collapse ${classes[option]}`}>
			<StyledEngineProvider injectFirst>
				{
					list.map(item => (

					<Accordion 
						expanded={isExpanded(item.id)} 
						onChange={() => handleChange(item.id)}
						className={classes['list-collapse__item']}
						key={item.id}
						disableGutters={true}
					>
						<AccordionSummary
						expandIcon={<ExpandMoreIcon className={classes['list-collapse__item-btn']}/>}
						className={classes['list-collapse__item-header']}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
						>
							<Typography className={classes['list-collapse__item-title']}>
									{
										item.title
									}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography className={classes['list-collapse__item-content']}>
								{
									item.content
								}
							</Typography>
						</AccordionDetails>
					</Accordion>
				))
				}
			</StyledEngineProvider>
		</div>
	)
}
export default ListCollapse