import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDealItem } from "../../../http/dealAPI";
import actions from "../../../store/reducers/actions";
import { getFieldValue, getFieldValueById } from "../../../utils/checkers/getFieldValue";
import Order from "../../../utils/classes/Order";
import { ADMIN_ROLE, DEALS_DONE_ROUTE, FORM_FIELDS, MANAGER_DIGITAL_ROLE, MANAGER_MODELLING_ROLE, MANAGER_ROLE, STATUS_AT_WORK_DIGITIZATION, STATUS_AT_WORK_MODEL, STATUS_COMPLETED, STATUS_COMPLETED_DIGITIZATION, STATUS_COMPLETED_MODEL, STATUS_FUNDED, STATUS_ID_FIELD, STATUS_NEW, STATUS_PREPAID, SUCCESS_CODE, USER_ROLE } from "../../../utils/consts";
import { BONUS_POINTS, COST, PAYMENT_LINK, PAY_METHOD, STATUS, USE_BONUS } from "../../../utils/consts/OBJECT_FIELDS";
import { STATUS_CANCELED } from "../../../utils/consts/STATUS";
import { getStatusObject } from "../../../utils/getStatusId";
import tempAlert from "../../../utils/tempAlert";
import FormBtn from "../formBtn/FormBtn";
import FormField from "../FormField/FormField";
import DealInfoForm from "../Forms/DealInfoForm/DealInfoForm";
import HoverAlert from "../HoverAlert/HoverAlert";
import User from "../../../utils/classes/User";
import OrderComments from "./OrderComments/OrderComments";
import OrderItemInfo from "./OrderItemInfo/OrderItemInfo";
import PayBtn from "./OrderPayment/PayBtn/PayBtn";

const OrderItemPage = (props) => {
	let {orderItem,editable=false, children, addedContent, setModalVisible} = props;
	const [isOnEdit, setIsOnEdit] = useState(false)
	const editPossibleComponent = () => {
		return(
			isOnEdit
				? (
					<>
						<DealInfoForm 
							setIsOnEdit={setIsOnEdit} 
							orderItem={orderItem}
							setModalVisible={setModalVisible}
						/>

					</>
				)
				:(
					<>
						<OrderItemInfo orderItem={orderItem}>
							<FormBtn handleClick={()=> setIsOnEdit(true)} className='--red'>
								изменить информацию
							</FormBtn>
						</OrderItemInfo>
					</>
				)
		)
	}
	
	return(
		<div className="managerOrders__container orderItemPage__wrap">
			{
				editable
				? editPossibleComponent()
				: <OrderItemInfo orderItem={orderItem}/>
			}
			{children}
		</div>
	)
}
export default OrderItemPage;