import {useEffect, useState } from "react";
import { FORM_BLOCKS, SERVICE_TYPES } from "../../../../utils/consts/FORM_FIELDS";
import FormBtn from "../../formBtn/FormBtn";
import { addDealItem } from "../../../../http/dealAPI";
import { sendData } from "../../../../utils/sendData";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../store/reducers/actions";
import { getStatusObject } from "../../../../utils/getStatusId";
import { useNavigate } from "react-router-dom";
import tempAlert from "../../../../utils/tempAlert";
import AddedFieldsSpec from "../AddedFields/AddedFieldsSpec/AddedFieldsSpec";
import { ADMIN_ROLE, DEALS_ROUTE, FORM_FIELDS, MANAGER_ROLE, ORDER_CURRENT_ROUTE, REGISTER_CLIENT_ROUTE, STATUS_NEW, SUCCESS_CODE, USER_ROLE } from "../../../../utils/consts";
import Form, { BLOCK_TYPE, CREATE_DEAL_FORM, CREATE_DEAL_FORM_MODEL, CREATE_DEAL_FORM_MODEL_TEST, CREATE_DEAL_FORM_MODEL_VISIBLE, CREATE_DEAL_FORM_VISIBLE, RAW_FIELDS_TYPE } from "../../../../utils/classes/Form";
import getFields from "../../../../utils/formUtils/getFields";
import Order from "../../../../utils/classes/Order";
import {
	ADDRESS,
	CITY,
	COMMENT,
	COST,
	DOCUMENTS,
	FLOORS,
	FLOORS_SELECT,
	LEGAL_PERSON,
	SEND_LINK,
	SQUARE,
	SQUARE_SELECT,
	STATUS,
	USER_INFO,
	I_WILL_BE,
	REPRESENTATIVE_PHONE,
	REPRESENTATIVE_NAME,
	LASTNAME, EMAIL
} from "../../../../utils/consts/OBJECT_FIELDS";
import User from "../../../../utils/classes/User";
import { fetchAdress } from "../../../../http/daData";
import { ALL_SQUARE_OBJ } from "../../../../utils/consts/PRICE";
import Loader from "../../loader/Loader";
import FormTest from "../../../FormComponent";
import { addFormBlocksAction, rmFormBlocksAction } from "../../../../store/reducers/FormReducer";
import FormComponent from "../../../FormComponent";
import ModalWindow from "../../modalWindow/ModalWindow";
import { useFetching } from "../../../../hooks/useFetching";
import RegisterForm from "../RegisterForm";
import {Link} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core";
const CreateDealForm = ({isModal=false, setModalVisible, setFormPath}) =>{
	const dispatch = useDispatch();
	const form = useSelector(state => state.formReducer);
	const fieldValue = useSelector(state => state.fieldValueReducer.fields)
	const user = useSelector(state => state.userReducer.user);
	const ordersReducer = useSelector(state => state.ordersReducer);
	const [formType, setFormType] = useState(CREATE_DEAL_FORM);
	const [responseCame, setResponseCame] = useState(false);
	const [iWillBe, setIWillBe] = useState(false)
	const [name, setName] = useState('')
	const [phone, setPhone] = useState('')
	const [orderCreated, setOrderCreated] = useState(false);
	const [notificationVisible, setNotificationVisible] = useState(false);
	const navigate = useNavigate();
	const [createDeal, isLoading, error] = useFetching(async () => {
		// selector check
		if(fieldValue[SQUARE] <= 500 && fieldValue.squareSelect === 'Другая площадь'){
			tempAlert('Площадь должна быть больше 500м²',3000,['--invalid'])
			return
		}
		if(
			!fieldValue[SEND_LINK]
			&&
			!notificationVisible
		){
			if(user.role === ADMIN_ROLE || user.role === MANAGER_ROLE){
				setNotificationVisible(true);
				return;
			}
		}
		let response = null
		if(!user.role) return
		const fieldsArr = getFields({FORM_TYPE:BLOCK_TYPE, FORM_FIELDS:form.blocks});
		const statusObj = getStatusObject({description:fieldValue[STATUS]})
		try{
			let status = STATUS_NEW.statusName;
			if(statusObj){
				status = statusObj.statusName;
			}
			let values = {
				...fieldValue,
				status,
				role: user.role,
				comments:[fieldValue[COMMENT]]
			}
			switch(user.role){
				case ADMIN_ROLE:
				case MANAGER_ROLE:{
					if(!fieldValue[USER_INFO]){
						tempAlert('Клиент не выбран',3000,['--invalid'])
						return;
					} else {
						values[USER_INFO] = fieldValue[USER_INFO].convertedServer
					}
					values = {
						...values,
						userId:fieldValue[USER_INFO].id,
						userCreatorId: user.id
					}
					break;
				}
				case USER_ROLE:{
					let pDeal = '';
					if(fieldValue[FORM_FIELDS.packageDeal.id].length === 2){
						pDeal = SERVICE_TYPES.cad_bim_3d;
					} else {
						pDeal = fieldValue[FORM_FIELDS.packageDeal.id][0]
					}
					values = {
						...values,
						userId: user.id,
						packageDeal: pDeal
					}
					break;
				}
				default:
					break
			}
			response = await sendData(addDealItem, {
				formFields: [...fieldsArr],
				fieldValue: {...values}
			})
		}catch(e){
			setResponseCame(true);
			console.log(e)
			tempAlert(response.message, 3000,['--invalid']);
		}
		if(response.success === SUCCESS_CODE){
			//alert("success")
			//!!!add to data reducer!!!
			console.log(response)
			const order = new Order({
				...response?.data
			})
			if(isModal){
				setModalVisible(false)
			}
			dispatch(actions.addOrderItemAction({
				orderType: order?.status,
				item: {
					content:order,
					pos:0
				}
			}))
			if (user.role === USER_ROLE){
				setOrderCreated(true);
			} else{
				tempAlert('сделка создана успешно', 3000,['--valid']);
				navigate('../../' + DEALS_ROUTE.path, {replace:true})
			}
			setResponseCame(true);
			//tempAlert('сделка создана успешно', 3000,['--valid']);
		} else{
			tempAlert(response?.message, 3000,['--invalid']);
		}
	})
	const setFreeFields = (blockId) => {
		let block = {...FORM_BLOCKS.objectDataVisible};
		if(blockId === FLOORS_SELECT){
			block.fields[0] = {
				...FORM_FIELDS[SQUARE_SELECT],
				defaultValue:'Другая площадь',
				currValue:'Другая площадь'
			}
			dispatch(actions.addFieldAction({id: FLOORS, value:parseInt(fieldValue[FLOORS])}));
			dispatch(actions.addFieldAction({id: SQUARE_SELECT, value:FORM_FIELDS.squareSelect.options[FORM_FIELDS.squareSelect.options.length-1]}));
		}
		// if admin role -> can change the cost
		if(user.role === ADMIN_ROLE || user.role === MANAGER_ROLE){
			block.fields= [...block.fields, FORM_FIELDS.cost]
		}
		//changing prev fields
		//dispatch(actions.rmFormBlocksAction([{id:'objectData'}]))
		dispatch(actions.updFormBlocksAction([
			{
				...block,
				id:'objectData'
			}
		]))
	}
	useEffect(()=>{
		//if its a last elm===(another squareMeter)
		if(
			fieldValue.squareSelect
			===
			FORM_FIELDS.squareSelect.options[FORM_FIELDS.squareSelect.options.length-1]
		){
			setFreeFields(SQUARE_SELECT);
		} else{
			let square = {};
			// if name of chosen select === squareObj.name -> set square
			ALL_SQUARE_OBJ.forEach(
				obj => {
					if(obj.name === fieldValue.squareSelect){
						square = {...obj}
					}
				}
			)
			dispatch(actions.addFieldAction({id: SQUARE, value:square.value}));
			const options = [];
			for(let i = 0; i < square.maxFloors; i++){
				options.push(`${i+1}`);
			}
			options.push('Другая этажность')
			// setting to standart block
			dispatch(actions.updFormBlocksAction([
				{
					...FORM_BLOCKS.objectData,
				}
			]))
			dispatch(actions.updFormFieldsAction(
				{
					blockId:'objectData',
					fields: [
						{
							...FORM_FIELDS[FLOORS_SELECT],
							options
						}
					]
				}
			))
		}
		dispatch(actions.addFieldAction({id: FLOORS, value:'1'}));
		dispatch(actions.addFieldAction({id: FLOORS_SELECT, value:'1'}));
	},[fieldValue.squareSelect])
	useEffect(()=>{
		dispatch(actions.addFieldAction({id: FLOORS, value:fieldValue[FLOORS_SELECT]}));
		if(
			fieldValue[FLOORS_SELECT]
			===
			FORM_FIELDS[FLOORS_SELECT].options[FORM_FIELDS[FLOORS_SELECT].options.length-1]
		){

			setFreeFields(FLOORS_SELECT);
		}
	},[fieldValue[FLOORS_SELECT]])
	useEffect(()=>{
		dispatch(actions.updFormFieldsAction({
			blockId:ADDRESS,
			fields:[
				{
					...FORM_FIELDS[ADDRESS],
						queryParams:{
						query:'',
						[CITY]:fieldValue[CITY]
					},
				}
			]
		}))
	},[fieldValue[CITY]])
	useEffect(()=>{
		if(responseCame && !orderCreated){
			if (user.role === USER_ROLE){
				navigate('../../' + ORDER_CURRENT_ROUTE.path, {replace:true})
			}
		}
	},[orderCreated, responseCame])
	useEffect(() => {
		console.log(fieldValue[I_WILL_BE])
		console.log(fieldValue[REPRESENTATIVE_NAME])
		// console.log(user._firstname)
		if (fieldValue[I_WILL_BE]){
			dispatch(actions.updFormFieldsAction({
				fields:[
					{
						...FORM_FIELDS[REPRESENTATIVE_NAME],
						disabled: 'disabled'
					}
				]
			}))
			dispatch(actions.updFormFieldsAction({
				fields:[
					{
						...FORM_FIELDS[REPRESENTATIVE_PHONE],
						disabled: 'disabled'
					}
				]
			}))
			// // console.log(fieldValue[REPRESENTATIVE_NAME])
			dispatch(actions.addFieldAction({id: REPRESENTATIVE_NAME, value: user._firstname}));
			dispatch(actions.addFieldAction({id: REPRESENTATIVE_PHONE, value: user._phone}));

		} else {
			dispatch(actions.updFormFieldsAction({
				fields:[
					{
						...FORM_FIELDS[REPRESENTATIVE_NAME],
					}
				]
			}))
			dispatch(actions.updFormFieldsAction({
				fields:[
					{
						...FORM_FIELDS[REPRESENTATIVE_PHONE],
					}
				]
			}))
			// dispatch(actions.addFieldAction({id: REPRESENTATIVE_NAME, value: ''}));
			// dispatch(actions.addFieldAction({id: REPRESENTATIVE_PHONE, value: ''}));
		}
	}, [fieldValue[I_WILL_BE]])
	return (
		<FormComponent
			beforeFields = {
				<AddedFieldsSpec spec='CREATE_DEAL' setModalVisible={setModalVisible} setFormPath={setFormPath}/>
			}
			formClassNames={['form__create-deal']}
			className ='form__create-deal'
			formType ={formType}
		>
		{
				notificationVisible
				?(
					<ModalWindow
						visible={notificationVisible}
						setVisible={setNotificationVisible}
						modification={'read-only'}
						option={'modal__notification'}
						modalClasses={['modal--small']}
					>
						<h3 style={{textAlign:'center', fontWeight:500}}>
							Вы не будете отправлять ссылку клиенту
						</h3>
						<FormBtn handleClick={createDeal}>
							Продолжить
						</FormBtn>
					</ModalWindow>
				)
				:<></>
			}
			{
				orderCreated
				?<ModalWindow
					visible={orderCreated}
					setVisible={setOrderCreated}
					modification={'read-only'}
					option={'modal__notification'}
					modalClasses={['modal--small']}
				>
					<h3 style={{textAlign:'center', fontWeight:500}}>
						Спасибо! Ваш заказ создан. В ближайшее время с Вами свяжется менеджер для подтверждения заказа.
						Если вы хотите оперативно получать информацию о вашем заказе – зарегистрируйтесь в телеграмм-боте VESO.
						<br/>
						<br/>
						<a href="https://t.me/vesopro_bot" className="nav-link d-inline" target="_blank" rel="noopener noreferrer">
							<FontAwesomeIcon icon="fa-brands fa-telegram" size='4x' fixedWidth color='#08c'/>
						</a>
					</h3>

				</ModalWindow>
				:<></>
			}
			{
				!isLoading
				?(
					<FormBtn handleClick={createDeal}>
						Создать заказ
					</FormBtn>
				)
				:<span className='btn project_ref--disabled'>
					<div style={{display: 'flex', justifyContent: 'center',height:'100%', width:'100%'}}>
						<Loader width={10} height={10}/>
					</div>
				</span>
			}
		</FormComponent>

	)
}
export default CreateDealForm;
