import { fieldValueReducer} from './fieldValue'
import { userReducer } from "./userReducer";
import { dataReducer } from "./dataReducer";
import { dataItemContentReducer } from "./dataItemContentReducer";
import { fileReducer } from "./uploadedFiles";
import { dealReducer } from "./dealReducer";
import { dataNameReducer } from './dataNameReducer';
import { ordersReducer } from './ordersReducer';
import { formReducer } from './FormReducer'
import { windowReducer } from './windowReducer';
export default {
	fieldValueReducer,
	userReducer,
	dataReducer,
	dataItemContentReducer,
	fileReducer,
	ordersReducer,
	dataNameReducer,
	windowReducer,
	formReducer
}