import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "../Header/Header";
import classes from './User.module.css'
import UserCreateOrder from "./UserCreateOrder/UserCreateOrder";
import UserCurrentOrder from "./UserCurrentOrder/UserCurrentOrder";
import UserLoyality from "./UserLoyality/UserLoyality";
import UserOffer from "./UserOffer/UserOffer";
import UserOrders from "./UserOrders/UserOrders";
import UserProfile from "./UserProfile/UserProfile";
import UserQA from "./UserQA/UserQA";
import CreateDealPage from "../../../pages/CreateDealPage/CreateDealPage";
import { getRouteByPath, getRouteVar } from "../../../utils/getRouteVar";
import {
	LOGIN_ROUTE,
	LOYALITY_ROUTE,
	OFFER_ROUTE,
	ORDERS_ROUTE,
	ORDER_CREATE_ROUTE,
	ORDER_CURRENT_ROUTE,
	ORDER_ROUTE,
	QA_ROUTE,
	USER_ROUTE
} from "../../../utils/consts";
import { pathNumToId } from "../../../utils/pathUtils/pathNum.js";
import { BTNS_USER, BTNS_USER_MOBILE, BTN_CONTACT, BTN_CONTACT_MOBILE, BTN_CREATE_DEAL, BTN_CREATE_DEAL_MOBILE, BTN_CRETE_DEAL_MOBILE } from "../../../utils/consts/BTNS";
import CreateDealForm from "../Forms/CreateDealForm/CreateDealForm";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import NavBarController from "../Navbar/NavBarController";
import {
	API_ROUTE,
	ORDER_PAYMENT_ROUTE,
	PAYMENT_FAILURE_ROUTE,
	PAYMENT_SUCCESS_ROUTE
} from "../../../utils/consts/ROUTES";
import PaymentStatusPage from "../../../pages/PaymentPage/PaymentStatusPage";
import FormTest from "../../FormComponent";
import OrderPayment from "../OrderItem/OrderPayment/OrderPayment";

const User = () => {
	const navigate = useNavigate();
	const {pathname} = useLocation();
	const [title, setTitle] = useState('Страница пользователя');
	const {isAuth} = useSelector(state => state.userReducer)
	const user = useSelector(state => state.userReducer.user);
	const params = useParams();
	const currentPath = pathNumToId(params.id, pathname);
	const {height, width} = useWindowDimensions();
	const getComponent = () => {
		switch (currentPath){
			case USER_ROUTE.path:
				return <UserProfile/>
			case ORDERS_ROUTE.path:
				return <UserOrders/>
			case ORDER_CREATE_ROUTE.path:
				return <CreateDealPage/>
			case QA_ROUTE.path:
				return <UserQA/>
			case OFFER_ROUTE.path:
				return <UserOffer/>
			case LOYALITY_ROUTE.path:
				return <UserLoyality/>
			case ORDER_CURRENT_ROUTE.path:
				return <UserCurrentOrder/>
			case ORDER_ROUTE.path:
				return <h1>current route {params.id}</h1>
			case PAYMENT_FAILURE_ROUTE.path:
			case PAYMENT_SUCCESS_ROUTE.path:
				return <PaymentStatusPage/>
			case ORDER_PAYMENT_ROUTE.path:
				return <OrderPayment/>
			default:
				return false;
		}
	}
	const getHeaderBtns = () => {
		//mobile
		if(width < 1023){
			return BTNS_USER_MOBILE
		}
		return BTNS_USER
	}
	useEffect(()=>{
		setTitle(getRouteByPath(currentPath).name)
		if(!isAuth){
			navigate('../../' + LOGIN_ROUTE.path, {replace:true})
		}
	},[pathname])
	return(
		<div className={'container user__wrap'}>
			<Header
				title={title}
				buttons={getHeaderBtns()}
				role={user.role}
			/>

			<div className='user__container container'>
				{
					getComponent()
				}
			</div>
			<div className='footer'> <p><a href={`${API_ROUTE}/offer`} style={{color: '#593794'}}>Политика конфиденциальности</a></p>
			</div>

		</div>
	)
}
export default User;
