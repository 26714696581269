import { getFieldValue } from "../../../../utils/checkers/getFieldValue";
import ContentFields from "./ContentFields/ContentFields";
import OrderItemTable from "./OrderItemTable/OrderItemTable";
import OrderItemUser from "./OrderItemUser/OrderItemUser";

const OrderItemList = ({order, listType='',children}) => {
	const getByModificator = (modificator) => {
		switch(modificator){
			case 'userOrdersList':
				return(
					<OrderItemUser order={order}/>
				)
			case 'allContentFields':
				return(
					<ContentFields order={order}/>
				)
			case 'orderItem--table':
			case 'orderItem--table--user':
			case 'orderItem--table-calendar':
			default:
				return(
					<OrderItemTable 
						order={order} 
						children={children} 
						option={listType}
					
						/>
				)
		}
	}
	return(
		<>
			{
				getByModificator(listType)
			}
		</>
	)
}
export default OrderItemList;