import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import OrderControlPanel from "../../../Modals/OrderControlPanel/OrderControlPanel";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import {COST, SENDING_DATE} from "../../../../../utils/consts/OBJECT_FIELDS";
import {STATUS_COMPLETED, STATUS_NEW} from "../../../../../utils/consts";
import {CssBaseline, ScopedCssBaseline} from "@mui/material";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        boxShadow: "none",

    },
    row: {
        fontFamily: 'Calibri, sans-serif'
    },
    cell:{
        fontFamily: 'Calibri, sans-serif'
    }

});
const statusColor = {
    STATUS_NEW:'#FE8F8F',
    AWAITING_PAYMENT: '#deb329',
    PREPAID:'#fdff8a',
    AT_WORK_DIGITIZATION: 'rgba(179, 138, 255, 0.7882352941)',
    COMPLETED_DIGITIZATION:'rgba(160, 110, 252, 0.7960784314)',
    AT_WORK_MODEL: 'rgba(148, 92, 252, 0.8156862745)',
    COMPLETED_MODEL:'rgba(126, 58, 253, 0.8156862745)',
    FUNDED:'#a0f898',
    COMPLETED:'#14ff00',
    CANCELED:'#ff0000',
}

const completedStatusStyle=(status) => {
     return (status === STATUS_COMPLETED.statusName ? {
        cell: {
            backgroundColor: '#008800',
            color : '#fff',
            fontFamily: 'Calibri, sans-serif'
        },
        row:{
            color: '#008800',
            fontFamily: 'Calibri, sans-serif'
        }
    } : {
         cell: {
             fontFamily: 'Calibri, sans-serif'
         },
         row:{
             fontFamily: 'Calibri, sans-serif'
         }
     })
}



export default function DenseTable({orders}) {
    const {width} = useWindowDimensions();
    const classes = useStyles();
    const [order, setOrder] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);

    const onOrderItemClick = (order) => {
        setModalVisible(true);
        setOrder(order)
    }

    return (
        <>
        <OrderControlPanel
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            order={order}
        />
            {width > 1023 ? (
                <TableContainer className={classes.table} component={Paper}>
                    <Table  className={classes.table} size="medium" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Номер заказа</TableCell>
                                <TableCell align="left">Адрес</TableCell>
                                <TableCell align="left">Площадь объекта</TableCell>
                                <TableCell align="left">Дата сканирования</TableCell>
                                <TableCell align="center">Статус Заказа</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map((row) => (
                                <TableRow
                                    hover={true}
                                    key={row._id}
                                    onClick={() => onOrderItemClick(row)}
                                    classes={{
                                        root: classes.row
                                    }}

                                >
                                    <TableCell
                                        classes={{
                                            root: classes.cell
                                        }}
                                        style={completedStatusStyle(row.status).row} component="th" scope="row">
                                        {row._id}
                                    </TableCell>
                                    <TableCell style={completedStatusStyle(row.status).row} align="left">{row._address}</TableCell>
                                    <TableCell style={completedStatusStyle(row.status).row} align="left">{row._square}</TableCell>
                                    <TableCell style={completedStatusStyle(row.status).row} align="left">{row._dateSending}</TableCell>
                                    <TableCell  style={completedStatusStyle(row.status).cell}
                                               align="center">{row._statusDescription}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (<>
                    {orders.map((row) => (
                        <div onClick={() => onOrderItemClick(row)} className='orderItem--user'
                             style={{minHeight: 0}}>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: '100%',
                                marginBottom: '20px',
                                alignItems: 'center'
                            }}>
                                <h4 className='content-list--table__title'>Заказ No: {row.id}</h4>
                                <div className='content-list--table__item' style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    border: '1px solid #e8e8e8',
                                    padding: '5px 10px',
                                    borderRadius: '5px'
                                }}>
                                    <div style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '100%',
                                        background: statusColor[row.status],
                                        marginRight: '7px'
                                    }}></div>
                                    {row.getStatusName()}
                                </div>
                            </div>
                            <ul className='content-list--table'>
                                <li className='content-list--table__item'>
                                    {row.getFieldText(SENDING_DATE)}
                                </li>
                                <li className='content-list--table__item'>
                                    {row.getFieldText(COST)}
                                </li>
                                <li className='content-list--table__item'>
                                    {row.address}
                                </li>
                            </ul>
                        </div>

                    ))
                    }
                </>

            )}
            </>);
}
