import { getFieldValueById, getManyFieldsValueById } from "./checkers/getFieldValue";
import Order from "./classes/Order";
import { MODELS } from "./consts";
import countOrderPrice from "./countOrderPrice";
import {  getStatusObject } from "./getStatusId";

export const formatOrders = (orders=[]) => {
	const finalArr = []
	for(let i = 0; i<orders.length; i++){
		try{
			let userOrder = new Order({...orders[i]}) //formatOrder({...orders[i]})
			finalArr.push(userOrder)
		}catch(e){
			console.log(e)
		}
	}
	return [...finalArr];
} 
export const formatOrder = (order = {}) => {
	const statusObj = getStatusObject({statusName:order.status});
		try{
			let userOrder = {
				id:order.id,
				statusName: statusObj.statusName,
				statusId:statusObj.id,
				status: {
					name:'Статус',
					description:statusObj.description
				},
				cost:{
					name:'Заказ на сумму',
					description:order.cost
				},
				advancePayment:{
					name:'Внесенный Аванс',
					description:order.advancePayment
				},
				restPayment:{// cost - advancePayment
					name:'Остаток',
					description:order.restPayment
				},
				projectLink:{
					name:'Cсылка на проект',
					description:order.projectLink
				},
				demoLink:{
					name:'Демо ссылка на проект',
					description:order.demoLink
				},
				content: {
					desiredDate:{
						name:'Желаемая дата сканирования',
						description:order.desiredDate
					},
					representativePhone:{
						name:'Контакты представителя',
						description:order.representativePhone
					},
					representativeName:{
						name:'Имя представителя',
						description:order.representativeName
					},
					packageDeal: {
						name:'Пакет',
						description:order.packageDeal
					},
					dateSending:{
						name:'Дата сканирования',
						description:order.dateSending
					},
					/*address:{
						name:'Адрес',
						description:[order.city,order.street,order.house,order.flat]
					},*/
					city:{
						name:'Город',
						description:order.city
					},
					street:{
						name:'улица',
						description:order.street
					},
					house:{
						name:'дом',
						description:order.house
					},
					flat: {
						name:'кв',
						description:order.house
					},
					square:{
						name: 'Площадь',
						description:order.square
					},
					floors:{
						name: 'Этажность',
						description:order.floors
					},
					software:{
						name:'ПО',
						description:order.software,
					}
				}
			}
			if(userOrder.cost.description)
			userOrder.cost.description = countOrderPrice({
				square:order.square,
				serviceType:order.packageDeal,
			})
			if(statusObj.id === 0){
				userOrder.advancePayment.description = 0;
			} else {
				userOrder.advancePayment.description = (userOrder.cost.description/2);
			}
			userOrder.restPayment.description = (
				userOrder.cost.description - userOrder.advancePayment.description
			)
			return userOrder;
		}catch(e){
			console.log(e)
		}
}
export const formatOrderToSend = (order = {}) => {
	const orderModel = Object.entries(MODELS.orderModel);
	const fieldIds = []
	for(let i=0; i<orderModel.length; i++){
		fieldIds.push(orderModel[i][0])  
	}
	const fields = getManyFieldsValueById({fieldIds,fieldList:order})
	return {...fields}
}
