import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetching } from '../../../../hooks/useFetching';
import { fetchDeals } from '../../../../http/dealAPI';
import actions from '../../../../store/reducers/actions';
import { ADMIN_ROLE, MANAGER_ROLE } from '../../../../utils/consts';
import { BTN_CREATE_DEAL, BTN_DEALS_LIST } from '../../../../utils/consts/BTNS';
import { SENDING_DATE, SEND_LINK } from '../../../../utils/consts/OBJECT_FIELDS';
import { sortOrders } from '../../../../utils/dispatchUtils/sortOrders';
import { formatOrders } from '../../../../utils/formatOrders';
import { getSortedList } from '../../../../utils/getSortedList';
import { getAllOrders } from '../../../../utils/ordersUtils/getOrdersList';
import sleep from '../../../../utils/sleep';
import BtnContainer from '../../BtnContainer/BtnContainer';
import FormBtn from '../../formBtn/FormBtn';
import Loader from '../../loader/Loader';
import ModalWindow from '../../modalWindow/ModalWindow';
import OrderItem from '../../OrderItem/OrderItem';
import OrderItemList from '../../OrderItem/OrderItemList/OrderItemList';
import classes from '../Calendar.module.css'
import moment from "moment";
const CalendarItem = ({item, setActiveElement, isActive,id}) => {
	// isBtnsVisibleCheck
	/**
	 * has 2 states
	 * 1 outer - content(max 3 elm)
	 * 2 inner - ordersList(max inf elm)
	 */

	const dispatch = useDispatch();
	const user = useSelector(state => state.userReducer.user)
	const orders = useSelector(state => state.ordersReducer)

	let currTime = moment(item.toLocaleDateString('pt-br').split( '/' ).reverse( ).join( '-' )).format('DD-MM-YYYY')
	const [ordersList, setOrdersList] = useState([]);
	const [content, setContent] = useState([]);
	const [limit, setLimit] = useState(20);
	const getItemByDay = (allOrders, count=0) => {
		//let currTime = day.toLocaleDateString('pt-br').split( '/' ).reverse( ).join( '-' );
		const sortedByDay = getSortedList(currTime, SENDING_DATE, allOrders);
		const finalArr = []
		const length = sortedByDay.length-1;
		if(length > count){
			for(let i =0; i<count; i++){
				finalArr.push(sortedByDay[length-i]);
			}
		} else{
			return [...sortedByDay]
		}
		return [...finalArr]
	}
	//const [btnsVisible, setBtnsVisible]= useState(false); // for innerUse
	const itemBtns = () => {
		/// inner btn ordersList
		const btns = [
			{
				...BTN_DEALS_LIST,
				item:<>
					{
						ordersList.map(listItem => {
						return <OrderItem key ={listItem.id} order={listItem} listType='orderItem--table' className='calendar__item-wrap'/>
						})
					}
				</>
			}
		];
		// if day < today -> cant createDeal
		const currDay = new Date();
		if((currDay - item) < 86400000) {
			if( user.role === ADMIN_ROLE || user.role ===MANAGER_ROLE){
				btns.push(BTN_CREATE_DEAL);
			}
		}
		return btns
	}
	const [fetchOrders, isLoading, error] = useFetching(async () => {
		//remove 'page'
		const response = await fetchDeals({page:1, limit, sort_by: SENDING_DATE, filter:currTime,userId:user.id});
		const fetchedOrders = formatOrders(response);
		//setContent(orders)
		//alreadySorted on server
		//setOrdersList(getItemByDay(fetchedOrders, limit));
		setOrdersList(fetchedOrders)
		// const sortedOrders = sortOrders(orders, user.role);
		// for(let i = 0; i<sortedOrders.length; i++){
		// 	dispatch(actions.addOrderAction({...sortedOrders[i]}))
		// }
		//setContent(orders);
	})
	const clickHandler = () => {
		if(!isActive){
			setActiveElement(id);
		} else{
			setActiveElement('')
		}
		// if(activeElement.id !== id)
		// {
		// 	setActiveElement({id, isActive:true})
		// 	dispatch(actions.addFieldAction({id:SENDING_DATE, value:currTime}))
		// } else{
		// 	if(isActive){
		// 		setActiveElement({id, isActive:false})
		// 	} else{
		// 		setActiveElement({id, isActive:true})
		// 		//dispatch(actions.addFieldAction({id:SENDING_DATE, value:currTime}))
		// 	}
		// }
	}
	useEffect(()=>{
		if(isActive){
			//dispatch(actions.clearOrdersAction())
			fetchOrders();
			dispatch(actions.addFieldAction({id:SENDING_DATE, value:currTime}));
		}
	},[isActive])
	useEffect(()=>{
		//console.log('orders has changed',  orders)
		// when orders changed -> taking 3(outer) and 20(inner) from reducer
		const sorted = getAllOrders(orders);// format to all orders
		setContent(getItemByDay([...sorted], 3));// getting by day and limit
		setOrdersList(getItemByDay([...sorted], limit));
	},[orders])
	if(isLoading){
		return <div style={{position:'fixed', display: 'flex', justifyContent: 'center',height:'100%', width:'100%'}}><Loader/></div>
	}
	return(
		<div className={classes['calendar__item']}>
			<div className={classes['calendar__item-header']}>
				<span className={classes['calendar__item-day']}>
					{
						item.getDate()
					}
				</span>
				<FormBtn
					handleClick={clickHandler}
					spec={['btn--dotted']}
				/>
			</div>
			{
				isActive
				?(
					<BtnContainer
						btns={itemBtns()}
						classes={[classes['btn-container']]}
						onBtnClick={async () => {
							await sleep(3000)
							dispatch(actions.addFieldAction({id:SENDING_DATE, value:currTime}))
						}}
					/>
				)
				:(
					content.map(order => {
						return <OrderItem key ={order.id} order={order} listType='orderItem--table--calendar' className={`calendar__item-wrap`}/>
					})
				)
			}
		</div>
		)
}
export default CalendarItem;
