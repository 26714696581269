import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DEALS_ROUTE, LOGIN_ROUTE, ORDER_CURRENT_ROUTE, USER_ROLE, USER_ROUTE } from "../utils/consts";
export default function NotFound({msg}) {
	const navigate = useNavigate()
	const isAuth = useSelector(state => state.userReducer.isAuth)
	const user = useSelector(state => state.userReducer.user)
	let message = msg;
	if(!msg){
		message = 'page not found';
	}
	useEffect(()=>{
		if(!isAuth){
			navigate('../../' + LOGIN_ROUTE.path, {replace:true})
		} else if(user.role === USER_ROLE){
			navigate('../../' + ORDER_CURRENT_ROUTE.path, {replace:true})
		}else if(user.role){
			navigate('../../' + DEALS_ROUTE.path, {replace:true})
		}
	},[])
	return (
		<div className="container not-found__container">
			<h1 className="title">{message}</h1>
		</div>
	);
}
