//import NotFound from '../pages/NotFound.jsx';
//import About from '../pages/About.jsx';
//import TasksPage from '../pages/TasksPage/TasksPage';
//import ContactsPage from '../pages/ContactsPage/ContactsPage.jsx';
import RegisterPage from '../pages/RegisterPage.jsx';
import LoginPage from '../pages/LoginPage.jsx'
import NotFound from '../pages/NotFound.jsx';
import ManagerPage from '../pages/ManagerPage/ManagerPage.jsx';
import User from '../components/UI/User/User.jsx';
import UserProfile from '../components/UI/User/UserProfile/UserProfile.jsx';
import {
	HOME_ROUTE,
	ABOUT_ROUTE,
	USER_ROUTE,
	REGISTER_ROUTE,
	LOGIN_ROUTE,
	ORDERS_ROUTE,
	ORDER_ROUTE,
	ORDER_CREATE_ROUTE,
	ORDER_CURRENT_ROUTE,
	LOYALITY_ROUTE,
	DEAL_ROUTE,
	DEALS_ROUTE,
	DEALS_DONE_ROUTE,
	DEAL_DONE_ROUTE,
	CLIENTS_ROUTE,
	CLIENT_ROUTE,
	CALENDAR_ROUTE,
	REGISTER_ADMIN_ROUTE,
	REGISTER_MANAGER_ROUTE,
	REGISTER_CLIENT_ROUTE,
	ANALYTICS_ROUTE,
	QA_ROUTE,
	OFFER_ROUTE,
	SMS_DISTRIBUTION_ROUTE,
	REACT_APP_API_URL,
	ADMIN_ROLE,
	USER_ROLE,
	MANAGER_ROLE,
	//MANAGER_SECONDARY_ROLE,
	MANAGER_DIGITAL_ROLE,
	MANAGER_MODELLING_ROLE,
} from '../utils/consts.js';
import Manager from '../components/UI/Manager/Manager.jsx';
import { DEALS_CANCELED_ROUTE, DEAL_PAYMENT_ROUTE, MANAGERS_ROUTE, ORDER_PAYMENT_ROUTE, PAYMENT_FAILURE_ROUTE, PAYMENT_SUCCESS_ROUTE } from '../utils/consts/ROUTES.js';
import PaymentPage from '../pages/PaymentPage/PaymentStatusPage.jsx';
import PaymentStatusPage from '../pages/PaymentPage/PaymentStatusPage.jsx';
import OrderPayment from '../components/UI/OrderItem/OrderPayment/OrderPayment.jsx';

//import ContactPage from '../pages/ContactsPage/SingleContactPage/ContactPage.jsx';
const privateRoutes = [
	/*{ path: ABOUT_ROUTE, component: About, exact: true },
	{ path: TASKS_ROUTE, component: TasksPage, exact: true },
	{ path: CONTACTS_ROUTE, component: ContactsPage, exact: true },
	{ path: SINGLE_CONTACT_ROUTE, component: ContactPage, exact: true },*/
	{ path: LOGIN_ROUTE, component: LoginPage, exact: true },
	{ path: '*', component: NotFound, exact: true },
];
export const publicRoutes = [
	{ path: REGISTER_ROUTE.path, name: REGISTER_ROUTE.name, component: RegisterPage, exact: true },
	{ path: LOGIN_ROUTE.path, name: LOGIN_ROUTE.name, component: LoginPage, exact: true },
	{ path: PAYMENT_SUCCESS_ROUTE.path, name: PAYMENT_SUCCESS_ROUTE.name, component: PaymentStatusPage, exact: true },
	{ path: PAYMENT_FAILURE_ROUTE.path, name: PAYMENT_FAILURE_ROUTE.name, component: PaymentStatusPage, exact: true },
	{ path: ORDER_PAYMENT_ROUTE.path, name:  ORDER_PAYMENT_ROUTE.name, component: OrderPayment, exact: true },
	{ path: '*', component: NotFound, exact: true },
];
const userRoutes = [
	{ path: ORDER_PAYMENT_ROUTE.path, name:  ORDER_PAYMENT_ROUTE.name, component: User, exact: true },
	{ path: ORDER_CURRENT_ROUTE.path,  name: ORDER_CURRENT_ROUTE.name, component: User, exact: true },
	{ path: ORDER_CREATE_ROUTE.path, name: ORDER_CREATE_ROUTE.name, component: User, exact: true },
	{ path: ORDERS_ROUTE.path, name: ORDERS_ROUTE.name, component: User, exact: true },
	{ path: ORDER_ROUTE.path, name: ORDER_ROUTE.name, component: User, exact: true },
	{ path: LOYALITY_ROUTE.path, name: LOYALITY_ROUTE.name, component: User, exact: true },
	{ path: USER_ROUTE.path, name: USER_ROUTE.name, component: User, exact: true },
	{ path: OFFER_ROUTE.path, name:OFFER_ROUTE.name, component: User, exact: true },
	{ path: QA_ROUTE.path, name: QA_ROUTE.name, component: User, exact: true },
	{ path: PAYMENT_SUCCESS_ROUTE.path, name: PAYMENT_SUCCESS_ROUTE.name, component: User, exact: true },
	{ path: PAYMENT_FAILURE_ROUTE.path, name: PAYMENT_FAILURE_ROUTE.name, component: User, exact: true },
	{ path: '*', component: NotFound, exact: true },
]
const adminRoutes =[
	{ path: DEALS_ROUTE.path,name: DEALS_ROUTE.name, component: Manager, exact: true },
	{ path: CALENDAR_ROUTE.path, name:CALENDAR_ROUTE.name, component: Manager, exact: true },
	{ path: CLIENTS_ROUTE.path, name: CLIENTS_ROUTE.name, component: Manager, exact: true },
	{ path: MANAGERS_ROUTE.path, name: MANAGERS_ROUTE.name, component: Manager, exact: true },
	{ path: REGISTER_CLIENT_ROUTE.path, name: REGISTER_CLIENT_ROUTE.name, component: Manager, exact: true },
	{ path: REGISTER_MANAGER_ROUTE.path, name:REGISTER_MANAGER_ROUTE.name, component: Manager, exact: true },
	//{ path: ANALYTICS_ROUTE.path, name: ANALYTICS_ROUTE.name, component: Manager, exact: true },
	//{ path: SMS_DISTRIBUTION_ROUTE.path, name: SMS_DISTRIBUTION_ROUTE.name, component: Manager, exact: true },
	{ path: REGISTER_ADMIN_ROUTE.path, name: REGISTER_ADMIN_ROUTE.name, component: Manager, exact: true },
	{ path: DEALS_DONE_ROUTE.path, name:DEALS_DONE_ROUTE.name, component: Manager, exact: true },
	{ path: DEALS_CANCELED_ROUTE.path, name:DEALS_CANCELED_ROUTE.name, component: Manager, exact: true },
	//{ path: USER_ROUTE.path, name: USER_ROUTE.name, component: Manager, exact: true },
	{ path: '*', component: NotFound, exact: true },
]
const managerRoutes =[
	{ path: DEALS_ROUTE.path,name: DEALS_ROUTE.name, component: Manager, exact: true },
	{ path: CALENDAR_ROUTE.path, name:CALENDAR_ROUTE.name, component: Manager, exact: true },
	{ path: CLIENTS_ROUTE.path, name: CLIENTS_ROUTE.name, component: Manager, exact: true },
	{ path: MANAGERS_ROUTE.path, name: MANAGERS_ROUTE.name, component: Manager, exact: true },
	{ path: REGISTER_MANAGER_ROUTE.path, name:REGISTER_ADMIN_ROUTE.name, component: Manager, exact: true },
	{ path: REGISTER_CLIENT_ROUTE.path, name: REGISTER_CLIENT_ROUTE.name, component: Manager, exact: true },
	{ path: DEALS_DONE_ROUTE.path, name:DEALS_DONE_ROUTE.name, component: Manager, exact: true },
	{ path: DEALS_CANCELED_ROUTE.path, name:DEALS_CANCELED_ROUTE.name, component: Manager, exact: true },
	//{ path: USER_ROUTE.path, name: USER_ROUTE.name, component: Manager, exact: true },
	{ path: '*', component: NotFound, exact: true },
]
const managerSecondaryRoutes =[
	{ path: DEALS_ROUTE.path,name: DEALS_ROUTE.name, component: Manager, exact: true },
	{ path: CALENDAR_ROUTE.path, name:CALENDAR_ROUTE.name, component: Manager, exact: true },
	{ path: DEALS_DONE_ROUTE.path, name:DEALS_DONE_ROUTE.name, component: Manager, exact: true },
	{ path: USER_ROUTE.path, name: USER_ROUTE.name, component: Manager, exact: true },
	{ path: '*', component: NotFound, exact: true },
]

export const getRoutes = (routeName) => {
	switch(routeName){
		case ADMIN_ROLE:
			return adminRoutes;
		case MANAGER_ROLE:
			return managerRoutes;
		case MANAGER_DIGITAL_ROLE:
			return managerSecondaryRoutes;
		case MANAGER_MODELLING_ROLE:
			return managerSecondaryRoutes;
		case USER_ROLE:
			return userRoutes;
		default:
			return publicRoutes;
	}
}
