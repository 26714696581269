import classes from '../DataTemplate.module.css'
import DataTemplateItem from '../dataTemplateItem/DataTemplateItem'
import { useState, useMemo, useEffect } from 'react'
import DataFilter from '../../DataFilter/DataFilter'
import { useSelector } from 'react-redux'
import { getById } from '../../../../utils/getById'
import FormBtn from '../../formBtn/FormBtn'
import { ADMIN_ROLE, CLIENTS_ROUTE, DATA_NAMES, MANAGER_ROLE, USER_ROLE } from '../../../../utils/consts'
import { getFieldValueById } from '../../../../utils/checkers/getFieldValue'
import ListSearchable from './ListSearchable/ListSearchable'
import Loader from '../../loader/Loader'
import { USER_INFO } from '../../../../utils/consts/OBJECT_FIELDS'
import { useLocation } from 'react-router-dom'
import { pathLastItem } from '../../../../utils/pathUtils/pathLastItem'
const DataTemplateList = (props) => {
	let {
		dataName ='', 
		queryParams=[], 
		listType='', 
		filter, 
		setFilter,
		modification=''
	} = props
	// if !queryParams -> no Query
	const {list} = useSelector(state => state.dataReducer[dataName])// obj
	const rootListClasses = [classes['dataTemplate__list']];
	//const location = useLocation();
	const [isItemChosen, setIsItemChosen] = useState(false);
	const isClickable = () => {
		if(listType === 'usersList--clickable'){
			return true
		}
		return false
	}
	if(listType === 'usersList') {
		rootListClasses.push(classes['dataTemplate__list--usersList'])
	}
	// if listType -> ListItem onclick( () => addUserInfo )
	const getListByType = () => {
		let finalList = []
		if(modification !== 'clients'){
			finalList = list.filter(item => item?.role !== USER_ROLE);
		} else{
			finalList = list.filter(item => item?.role === USER_ROLE);
		}
		//console.log(finalList)
		switch(listType){
			case 'usersList--clickable':{
				return <ListSearchable dataName={dataName} list={finalList} fieldId={USER_INFO}/>
			}
			default:
				return(
					<ul className={rootListClasses.join(' ')}>
						{
							finalList.length > 0
							?finalList.map((dataItem) => {
								return(
									<DataTemplateItem 
										dataItem ={dataItem} 
										dataName ={dataName} 
										key={dataItem.id} 
										itemId ={dataItem.id}
										canEdit={true}
										setIsItemChosen={setIsItemChosen}
										modification={modification}
									/>
								)
							})
							:''
						}
					</ul>
				)
		}
	}
	const [jsxList, setJsxList] = useState(getListByType())
	useEffect(()=>{
		setJsxList(getListByType())
		//console.log(list)
	},[list])
	return(
		<>
			<h4 className="dataTemplate__title">
				Найдите пользователя по номеру телефона
			</h4>
			{
				queryParams.length && !isItemChosen
				?<DataFilter filter={filter} setFilter={setFilter}/>
				:''
			}
			{jsxList}

		</>
	)
}
export default DataTemplateList