//import { useEffect } from "react";
import { DOC_LINK, DOC_PDF_LINK, IMAGE_LINK, TG_LINK } from "../../../../../utils/consts/LINKS";
import ImageLinks from "../../../ImageLinks/ImageLinks";

const OrderDocuments = ({docs}) => {
	const sortDocs = (items) => {
		const sortedDocs = [];
		// sorting docs for ImageLinks component
		for(let i = 0; i < items.length; i++){
			let docLink = {};			
			if(items[i].format === 'pdf'){
				docLink= {...DOC_PDF_LINK};
			} else if (items[i].format === 'img'){
				docLink ={...IMAGE_LINK}
			} else{
				docLink = {...DOC_LINK}
			}
			sortedDocs.push({
				...docLink,
				ref:items[i].href,
				download:true,
				content:(
						<p className="orderItem__documents-item__name">
							{items[i].name}.{items[i].format}
						</p>
				)
			})
		}
		return sortedDocs;
	}
	return(
		<div className="orderItem__documents">
			<ImageLinks imageItems ={[...sortDocs(docs)]}/>
		</div>

	)
}
export default OrderDocuments;