import { ORDERS_ROUTE, STATUS_FUNDED, STATUS_NEW } from "../consts";
import {
	ADDRESS,
	ADVANCE_PAYMENT,
	CITY,
	COST,
	DEMO_LINK,
	DESIRED_DATE,
	FLAT,
	FLOORS,
	HOUSE,
	ID,
	PACKAGE_DEAL,
	PROJECT_LINK,
	REPRESENTATIVE_NAME,
	REPRESENTATIVE_PHONE,
	REST_PAYMENT,
	SENDING_DATE,
	SOFTWARE,
	SQUARE,
	STATUS,
	STREET,
	orderNames,
	DESIRED_TIME,
	COMMENTS,
	PHONE,
	BONUS_POINTS,
	PAY_METHOD,
	DOCUMENTS,
	DEMONSTRATION_LINK,
	PROJECT_DIGITALIZATION_LINK,
	USER_NAME,
	USER_INFO,
	SENDING_TIME
} from "../consts/OBJECT_FIELDS";
import { getStatusObject } from "../getStatusId";

export default class Order{
	constructor(props){
		const {
			id = null,
			status = null,
			userInfo=null,
			users=null,
			cost=null,
			advancePayment=null,
			restPayment=null,
			projectLink=null,
			demoLink=null,
			house=null,
			city=null,
			flat=null,
			street=null,
			desiredDate=null,
			representativeName=null,
			representativePhone=null,
			packageDeal=null,
			dateSending=null,
			square = null,
			floors = null,
			software = null,
			paymentLink = null,
			desiredTime=null,
			bonusPoints,
			payMethod,
			comments =[],
			documents=[],
			digitalizationLink = null,
			address = null
		} = props

		const statusObj = getStatusObject({statusName:status});
		this._id = id;
		this._statusName = statusObj?.statusName;
		this._statusId = statusObj?.id;
		this._statusDescription = statusObj?.description;
		this._cost = cost;
		this._userInfo = userInfo;
		this._users = users;
		this._advancePayment = advancePayment;
		this._restPayment = restPayment;
		this._projectLink = projectLink;
		this._demoLink = demoLink;
		this._desiredTime = desiredTime;
		this._desiredDate = desiredDate;
		this._representativePhone=representativePhone;
		this._representativeName=representativeName;
		this._packageDeal=packageDeal;
		this._dateSending=dateSending;
		this._city=city;
		this._street=street;
		this._house=house;
		this._flat=flat;
		this._square=square;
		this._floors = floors;
		this._address = address;
		this._software = software;
		this._paymentLink = ORDERS_ROUTE.path + '/payment/'+ paymentLink;
		this._bonusPoints = bonusPoints;
		//this._payMethod = payMethod;
		this._comments = [...comments];
		this._documents = [...documents];
		this._digitalizationLink = digitalizationLink;
		this._timeSending = desiredTime;

	}
	get id(){
		return this._id;
	}
	get status(){
		return this._statusName
	}
	// подробная инфо в popup
	get content(){
		const order = {
			[STATUS]: this._statusName,
			[ADDRESS]:this._address,
			[DESIRED_DATE]:this._desiredDate,
			[DESIRED_TIME]:this._desiredTime,
			[SENDING_TIME]:this._timeSending,
			[SENDING_DATE]: this._dateSending,
			[REPRESENTATIVE_PHONE]:this._representativePhone,
			[REPRESENTATIVE_NAME]:this._representativeName,
			[PACKAGE_DEAL]:this._packageDeal,
			[SQUARE]:this._square,
			[FLOORS]:this._floors,
			[SOFTWARE]:this._software,
		}


		if (this._statusName === "AWAITING_PAYMENT" || this._statusName === "STATUS_NEW"){
			delete order[SENDING_DATE]
			delete order[SENDING_TIME]
		} else {
			delete order[DESIRED_DATE]
			delete order[DESIRED_TIME]
		}




		return order
	}
	get convertedClient(){
		return{
			[ID]:{
				name:'Номер заказа',
				description: this._id
			},
			[COST]:{
				name:'Заказ на сумму',
				description: this._cost
			},
			[ADVANCE_PAYMENT]:{
				name:'Внесенный Аванс',
				description:this._advancePayment
			},
			[REST_PAYMENT]:{
				name:'Остаток',
				description:this._restPayment
			},
			[PROJECT_LINK]:{
				name:'Cсылка на проект',
				description:this._projectLink
			},
			[DEMO_LINK]:{
				name:'Демо ссылка на проект',
				description:this._demoLink
			},
			statusId:this._statusId,
			status:{
				description:this._statusDescription,
				statusName:this._statusName,
				name:'Статус'
			},
			content:{
				...this.content
			}
		}
	}
	get convertedServer(){
		return(
			{
				[ID]: this._id,
				[STATUS]: this._statusName,
				[COST]:this._cost,
				[ADVANCE_PAYMENT]:this._advancePayment,
				[REST_PAYMENT]:this._restPayment,
				[PROJECT_LINK]:this._projectLink,
				[DEMO_LINK]:this._demoLink,
				[DESIRED_DATE]:this._desiredDate,
				[DESIRED_TIME]:this._desiredTime,
				[REPRESENTATIVE_PHONE]:this._representativePhone,
				[REPRESENTATIVE_NAME]:this._representativeName,
				[PACKAGE_DEAL]:this._packageDeal,
				[SENDING_DATE]:this._dateSending,
				[COST]:this._cost,
				[ADDRESS]: this._address,
				[CITY]:this._city,
				[STREET]:this._street,
				[HOUSE]:this._house,
				[FLAT]:this._flat,
				[SQUARE]:this._square,
				[FLOORS]:this._floors,
				[SOFTWARE]:this._software,
				[COMMENTS]:this._comments,
				[BONUS_POINTS]: this._bonusPoints,
				//[PAY_METHOD]:this._payMethod,
				[DOCUMENTS]:this._documents,
				[PROJECT_DIGITALIZATION_LINK]:this._digitalizationLink,
				[USER_INFO]: this._userInfo,
				users:this._users,
			}
		)
	}
	get comments(){
		return this._comments;
	}
	get convertedForm(){
		return({
			...this.convertedServer,
			[STATUS]:this._statusDescription
		})
	}
	setAnyField(valId, val){
		this[`_${valId}`] = val
	}
	getFieldText(fieldId){
		if(fieldId === STATUS){
			return (<><span className='item__span'>{orderNames[fieldId]}:</span>{this._statusDescription}</>);
		}
		// if(fieldId === ADDRESS){
		// 	return this.address
		// }
		if(!this[`_${fieldId}`]){
			return (<><span className='item__span'>{orderNames[fieldId]}:</span> неизвестно</>);
		}
		if(fieldId === BONUS_POINTS){
			return (<span className='item__span'>{orderNames[fieldId]}: {this[`_${fieldId}`]}</span> );
		}
		if(fieldId === REPRESENTATIVE_PHONE){
			return (<><span className='item__span'>{orderNames[fieldId]}:</span><a className='project_ref' href={`tel:${this._representativePhone}`}>{this._representativePhone}</a></>);
		}
		if(fieldId === ADVANCE_PAYMENT ){
			let string = this[`_${fieldId}`]?.amount;
			// if(this._statusName !== STATUS_NEW.statusName){
			// 	string = `${string} ${this[`_${fieldId}`]?.payMethod}`
			// }
			return <><span className='item__span'>{orderNames[fieldId]}:</span>{string}</>
		}
		if(fieldId === REST_PAYMENT){
			let string = this[`_${fieldId}`]?.amount;
			// if(this._statusName === STATUS_FUNDED.statusName){
			// 	string = `${string} ${this[`_${fieldId}`]?.payMethod}`
			// 	return <><span className='item__span'>Внесенный остаток:</span>{string}</>
			// }
			return <><span className='item__span'>{orderNames[fieldId]}:</span>{string}</>
		}
		if(fieldId === USER_INFO){
			return (<><span className='item__span'>{orderNames[fieldId]}:</span>{this[`_userInfo`]?.firstname +' '+ this[`_userInfo`]?.lastname}</>);
		}
		return (<><span className='item__span'>{orderNames[fieldId]}:</span>{this[`_${fieldId}`]}</>);
	}
	getValue(fieldId){
		return this[`_${fieldId}`];
	}
	get dateSending(){
		return this._dateSending;
	}
	get address (){
		return (<><span className='item__span'>Адрес:</span>{this._address}</>)
	}
	get statusObj(){
		const statusObj = getStatusObject({statusName:this._status});
		return {
			...statusObj
		}
	}
	getConvertedField(fieldId){
		const client = this.convertedClient;
		return client[fieldId]
	}

	getStatusName(){
			return this._statusDescription;
	}
}
