export const formatFields = ({formType, formFields}) => {
	const fieldsObj = {}
	const fieldsArr = [...formFields]
	if(formType === 'blocks'){
		for(let i=0; i < fieldsArr.length; i++){
			// for inner array of fields
			for(let j=0; j < fieldsArr[i].fields.length; j++){
				// if field already has value -> adding field {'id': 'value'}
				if(fieldsArr[i].fields[j].currValue){
					//console.log(currValue)
					fieldsObj[fieldsArr[i].fields[j].id] = fieldsArr[i].fields[j].currValue;
					//if field has not got a value, but its required
				} else if(
					!fieldsArr[i].fields[j].currValue 
					&& fieldsArr[i].fields[j].required
				){
					fieldsObj[fieldsArr[i].fields[j].id] = '';
				} else{
					//console.log('field', fieldsArr[i].fields[j], 'is not required')
				}
			}
		}
	} else if( formType === 'raw_fields'){
		for(let i=0; i<fieldsArr.length; i++){
			if(fieldsArr[i].currValue){
				fieldsObj[fieldsArr[i].id] = fieldsArr[i].currValue;
			} else if (!fieldsArr[i].currValue && fieldsArr[i].required){
				//fieldsObj[fieldsArr[i].id] = '';
			} else{
				//console.log('field', fieldsArr[i], 'is not required')
			}
		}
	}
	return {...fieldsObj}
}