import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import imgOne from './img/img1.png'
import imgTwo from  './img/img2.png'
import imgThree from  './img/img3.png'
import imgFour from  './img/img4.png'
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
    {
        label: 'Создайте заказ',
        imgPath: imgOne,
        footer: 'Выберите один из тарифов, и укажите базовую информацию о Вашем объекте.'
    },
    {
        label: 'Сканирование',
        imgPath:imgTwo,
        footer: 'Сканирование происходит в удобное для Вас время. В списке заказов Вы сможете отслеживать всю информацию и текущий статус заявки.'
    },
    {
        label: 'Предпросмотр',
        imgPath: imgThree,
        footer: 'Просмотр готовой 3-D модели будет доступен по ссылке сразу после окончания работ.'
    },
    {
        label: 'Получите результат!',
        imgPath: imgFour,
        footer: 'Вся информация в необходимом электронном формате будет доступна для скачивания прямо из Личного кабинета!'
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,

    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: '50px',
        fontSize: '20px',

    },
    img: {
        height: 255,
        display: 'block',
        maxWidth: 400,
        overflow: 'hidden',
        width: '100%',
    },
    stepper: {
        background: "none",
        display:"flex",
        justifyContent: "center"
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '100px',
    },
}));




function SwipeableTextMobileStepper() {

    const classes = useStyles();
    const theme = createTheme();



    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Paper square elevation={0} className={classes.header}>
                <Typography style={{fontFamily:'dinpro-bold', fontSize:'20px'}}  variant={'h5'}>{tutorialSteps[activeStep].label}</Typography>
            </Paper>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {tutorialSteps.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <img className={classes.img} src={step.imgPath} alt={step.label} />
                        ) : null}
                    </div>

                ))}

            </AutoPlaySwipeableViews>
            <Paper square elevation={0} className={classes.footer}>
                <Typography style={{fontFamily:'Calibri, sans-serif', fontSize:'18px'}}>{tutorialSteps[activeStep].footer}</Typography>
            </Paper>
            <MobileStepper
                steps={maxSteps}
                variant='dots'
                position="static"
                activeStep={activeStep}
                className={classes.stepper}
            />
        </div>
    );
}

export default SwipeableTextMobileStepper;