import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { PHONE_LINK, TG_LINK, WHATSAPP_LINK } from "../../../../utils/consts/LINKS";
import FormBtn from "../../formBtn/FormBtn";
import ModalWindow from "../../modalWindow/ModalWindow";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {fas} from '@fortawesome/free-solid-svg-icons'
import classes from '../NavBar.module.css'
import MobileNavBar from "./MobileNavbar";
import ImageLinks from "../../ImageLinks/ImageLinks";

library.add(fab, fas)
const MobileNavbarController = ({isVisible, setIsVisible,children}) => {
		return(
			<>
				{
					isVisible
					?(
						<ModalWindow visible={isVisible} setVisible={setIsVisible} option='modal__navbar--mobile'>
							<div className={classes['navbar__wrap']}>
								<MobileNavBar setIsVisible={setIsVisible}>
									<ImageLinks imageItems={[TG_LINK,WHATSAPP_LINK,PHONE_LINK]}/>
									{children}
								</MobileNavBar>
							</div>
						</ModalWindow>
					)
					:<FormBtn spec={['btn--menu', 'btn--interactive']} handleClick={()=> setIsVisible(true)}/>
				}
			</>
		)
}
export default MobileNavbarController;