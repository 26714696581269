import { addZero } from "../../../../utils/addZero";

const DateTimeDisplay = ({ value, type, isDanger }) => {
	return (
	  <div className={isDanger ? 'countdown danger' : 'countdown'}>
		<span>{addZero(value)}</span>
		{/* <span>{type}</span> */}
	  </div>
	);
  };
  export default DateTimeDisplay;