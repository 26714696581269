import { useSelector } from "react-redux";
import { COST, REPRESENTATIVE_PHONE, SENDING_DATE, STATUS } from "../../../../../utils/consts/OBJECT_FIELDS";
import { ADMIN_ROLE, MANAGER_DIGITAL_ROLE, MANAGER_MODELLING_ROLE, MANAGER_ROLE, USER_ROLE } from "../../../../../utils/consts";
import OrderItemTableManager from "./Manager/OrderItemTableManager";
import OrderItemTableUser from "./User/OrderItemTableUser";
import OrderItemTableCalendar from "./Calendar/OrderItemTableCalendar";

const OrderItemTable = ({order,children, option}) => {
	//const {role} = useSelector(state => state.userReducer.user)
	const getItem = () => {
		switch(option){
			case 'orderItem--table--user':
				return (
				<OrderItemTableUser order={order} classes={[`--${order.status}`]}>
					{children}
				</OrderItemTableUser>)
			case 'orderItem--table--calendar':
				return <OrderItemTableCalendar order={order} classes={[`--${order.status}`]}/>
			case 'orderItem--table':
			default:
				return <OrderItemTableManager order={order} classes={[`--${order.status}`]}/>
		}
	}
	return(
		<>
			{
				getItem()
			}
		</>
	)
}
export default OrderItemTable;