import { useEffect } from "react"

const CheckBox = (props) => {
	const {
		requiredProps,
		pattern
	} = props
	const {
		id,
		inputType,
		value,
		setValue,
		disabled,
		innerText,
		classNames,
		// pattern,
	} = requiredProps;
	const onClickHandler = () => {
		if(!value){
			setValue(true)
		} else{
			setValue(false)
		}
	}
	useEffect(()=>{
		setValue(false)
	},[])
	return(
		<>
			<input
				className={'form__field--checkbox ' + classNames.join(' ')}
				type='checkbox'
				id={id}
				onClick={onClickHandler}
				disabled={disabled}
			/>
			<label htmlFor={id}>{innerText}</label>
		</>
	)
}
export default CheckBox
