import {useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../store/reducers/actions';
import DataTemplate from '../../dataTemplate/DataTemplate';
import OrderItem from '../../OrderItem/OrderItem';
import {useFetching} from '../../../../hooks/useFetching'
import { fetchDeals } from '../../../../http/dealAPI';
import classes from './UserOrders.module.css'
import { ORDER_CREATE_ROUTE, STATUS_ID_NAME, STATUS_PAIRS, USER_ROLE } from '../../../../utils/consts';
import countOrderPrice from '../../../../utils/countOrderPrice';
import Loader from '../../loader/Loader';
import { formatOrders } from '../../../../utils/formatOrders';
import UserGreetings from '../UserCurrentOrder/UserGreetings';
import { sortOrders } from '../../../../utils/dispatchUtils/sortOrders';
import { getAllOrders } from '../../../../utils/ordersUtils/getOrdersList';
import User from '../../../../utils/classes/User';
import SearchIcon from "@material-ui/icons/Search";
import {alpha, Input} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {debounce} from "lodash";
import DenseTable from "./TableUser/TableUse";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
	search: {
		position: 'relative',
		// borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		// marginRight: theme.spacing(2),
		// marginLeft: '5px',
		// width: '100%',
		// [theme.breakpoints.up('sm')]: {
		// 	marginLeft: theme.spacing(3),
		// 	width: 'auto',
		// },
		padding:' 0 10px'
	},
	searchIcon: {
		// padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color:'#717171',
		padding:' 0 20px',
		// marginLeft:'-17px'
	},
	inputRoot: {
		color: 'inherit',
		boxShadow: '0px 0px 15px -1px rgba(34, 60, 80, 0.26)',
		borderRadius: '5px',
		padding: '0 45px',
		// border: 'none'
	},
	inputInput: {
		// padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		// paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		// transition: theme.transitions.create('width'),
		// width: '100%',
		// [theme.breakpoints.up('md')]: {
		// 	width: '20ch',
		// },

		height: '45px',
		fontSize:'16px',
		fontFamily:'Calibri, sans-serif',

	},
	sectionDesktop: {
		display: 'none',
		// [theme.breakpoints.up('md')]: {
		// 	display: 'flex',
		// },
		border: '0'
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
}));

const UserOrders = () => {
	const queryParams = ['id', 'address'];// параметры поиска
	const user=  useSelector(state => state.userReducer.user);
	const [value, setValue] = useState('');
	const [filter, setFilter] = useState('');
	const dispatch = useDispatch();
	const classes = useStyles();
	const {width} = useWindowDimensions();

	const debounceFn = useCallback(debounce((str) => {
		setFilter(str)
	}, 1000), [])

	const onChangeInput = (event) => {
		setValue(event.target.value);
		debounceFn(event.target.value);
	}


	const getUserOrders = () => {
		const orders = user.orders
		if(!orders.length){
			return <></>
		}
		if(orders){
			if(orders.length === 0){
				return(
					<UserGreetings/>
				)
			}
		}
		return(
			<DenseTable orders = {orders} />
			// 	orders.map(listItem => {
			// 		return <OrderItem key={listItem.id} order={listItem} listType='orderItem--table--user'
			// 						  className='userOrders__item-container'/>
			// 	})
		)
	}
	const [fetchOrders, isOrdersLoading, error] = useFetching(async () => {
		const response = await fetchDeals({sort_by:queryParams, filter});
		const orders = [...formatOrders(response)];
		const sortedOrders = sortOrders(orders, USER_ROLE)
		for(let i = 0; i<sortedOrders.length; i++){
			dispatch(actions.addOrderAction({...sortedOrders[i]}))
		}
		const userObj = new User({...user.convertedServer, orders:[...orders],currentOrder:(orders[orders.length-1].id)})
		dispatch(actions.setUserAction(userObj))
	})
	useEffect(()=>{
		fetchOrders()
		return () => {
			dispatch(actions.clearOrdersAction())
			const userObj = new User({...user.convertedServer, orders:[]})
			dispatch(actions.setUserAction(userObj))
		}
	},[])

	useEffect(()=>{
		fetchOrders()
		return () => {
			dispatch(actions.clearOrdersAction())
			const userObj = new User({...user.convertedServer, orders:[]})
			dispatch(actions.setUserAction(userObj))
		}
	},[filter])

	// можно добавить строку поиска!!!
	return(
		<div className='userOrders__container'>
			{width < 1023 ? <div className={classes.search}>
				<div className={classes.searchIcon}>
					<SearchIcon style={{fontSize: 20}}/>
				</div>
				<Input
					placeholder="Номер заказа или адрес"
					fullWidth={true}
					disableUnderline={true}
					classes={{
						root: classes.inputRoot,
						input: classes.inputInput,
					}}
					inputProps={{'aria-label': 'search'}}
					onChange={(e) => onChangeInput(e)}
				/>
			</div> : <></>}
			{//<DataTemplate dataName='orders'/>
				isOrdersLoading
				?<div style={{position:'fixed', display: 'flex', justifyContent: 'center',height:'100%', width:'100%'}}><Loader/></div>
				:getUserOrders()
			}
		</div>
	)
}
export default UserOrders;
