const defaultState ={
	currentDataName:'',
}
const SET_DATANAME = 'SET_DATANAME';

export const dataNameReducer = (state = defaultState,  action) => {
	switch(action.type) {
		case SET_DATANAME:{
			console.log(action.payload)
			return { 
				currentDataName: action.payload,
			}
		}
		default:
			return state
	}
}
export const setDataNameAction = (payload) => ({type: SET_DATANAME, payload});