import { useNavigate } from 'react-router-dom';
import { ORDER_CREATE_ROUTE } from '../../../../utils/consts';
import FormBtn from '../../formBtn/FormBtn';
import InstructionCreateOrder from './InstructionCreateOrder/InstructionCreateOrder'
import classes from './UserCurrentOrder.module.css'
import InstructionCreateOrderMobile from './InstructionCreateOrder/InstructionCreateOrderMobile'
import {useSelector} from "react-redux";



const UserGreetings = () => {
	const navigate = useNavigate();
	const {width} = useSelector(state => state.windowReducer);
	return(
		<div className={classes['currentOrder__container']}>
						<div className={classes['currentOrder__header']}>
							<h4 className={classes['currentOrder__title']}>
								Приветствуем!
								<br/>
								Теперь у Вас есть личный кабинет VESO
							</h4>
							<br/>
							<p className={classes['currentOrder__text']}>
								Здесь Вы сможете не только сделать on-line заказ 3D обмера, но и в реальном времени следить за ходом выполнения работ,<br/>
								просматривать и скачивать все материалы сразу, по мере их готовности.

							</p>
							<p className={classes['currentOrder__text']}>
								История заказов, on-line оплата, телеграмм бот,
								<br/>
								прямая связь  с менеджером и конечно - Система лояльности :)
							</p>
						</div>
						{
							width < 1023 ?
								<InstructionCreateOrderMobile/> :
								<InstructionCreateOrder />
						}



						<div className={classes['footer']} >
							<p>Мы видим, что у Вас ещё нет заказов :(</p>
							<FormBtn  handleClick={()=>navigate('../../' +ORDER_CREATE_ROUTE.path)}>Создать заказ</FormBtn>
						</div>



					</div>
	)
}
export default UserGreetings;