import * as React from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function BasicTooltip({hint, fontSize}) {
    return (
        <Tooltip  title={hint} style={{position:'absolute', right: '0px', color:'#454545'}} >
            <IconButton>
                <ErrorOutlineOutlinedIcon fontSize={fontSize}/>
            </IconButton>
        </Tooltip>
    );
}
