import { STATUS } from "../classes/Order"
import { ADMIN_ROLE, MANAGER_DIGITAL_ROLE, MANAGER_MODELLING_ROLE, MANAGER_ROLE, STATUS_NAMES, STATUS_PAIRS } from "../consts"
import { getSortedList } from "../getSortedList"
import { getStatusObjByRole } from "../statusUtils/getStatusByRole"

const sortListByObj = (statusObjects, list) => {
	const finalObj = {}
	for(let i =0; i< statusObjects.length; i++){
		finalObj[statusObjects[i].statusName] = [...getSortedList(statusObjects[i].statusName, 'status' , list)];
	}
	return finalObj;
}
export const getSortedOrdersList = (orders, role) => {
	let objects = getStatusObjByRole(role);
	return sortListByObj(objects, orders)
}
export const getAllOrders = (ordersReducer) => {
	let orders = [];
	for(let i=0 ; i<STATUS_PAIRS.length; i++){
		if(ordersReducer[STATUS_PAIRS[i].statusName]){
			orders =[...orders,...ordersReducer[STATUS_PAIRS[i].statusName].list]
		}
	}
	return orders
}
export const mapOrders = (orders) => {
		let finalArr = []
		const ordersKeys = Object.keys(orders);
		for(let i=0; i<ordersKeys.length; i++){
			finalArr = [...finalArr,...orders[ordersKeys[i]].list]
		}
		return finalArr;
}
/*const getPossibleModificators = (userRole) => {
		let modificatorsArr = []
		switch(userRole){
			case ADMIN_ROLE:
			case MANAGER_ROLE:
				for(let i=0; i< STATUS_ID_NAME.length; i++){
					modificatorsArr.push(i)
				}
				return modificatorsArr
			case MANAGER_DIGITAL_ROLE:
						modificatorsArr = [0,1,3,4]
						return modificatorsArr
			case MANAGER_MODELLING_ROLE:
					modificatorsArr = [0,4,5,6]
					return modificatorsArr
			default:
				return
		}
	}*/

	/*const getOrdersListByStatusId = (Modificator) => {
		try{
			let list=[]
			// if we use pair [orderType, orderArray]
			//list = ordersLists[Modificator][1]// [id] pair [name, array]
			switch(Modificator){
				case 0:
					list = ordersReducer['newOrder'].list
					break
				case 1:
					list = ordersReducer['advancePaid'].list
					break
				case 2:
					list = ordersReducer['fullPaid'].list
					break
				case 3:
					list = ordersReducer['digitalManagement'].list
					break
				case 4:
					list = ordersReducer['digitalManagementDone'].list
					break
				case 5:
					list = ordersReducer['modellingManagement'].list
					break
				case 6:
					list = ordersReducer['done'].list
					break
				default:
					break
			}
			return [...list]
		} catch(e){
			console.log(e)
		}
	}*/