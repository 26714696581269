import Order from "../../../utils/classes/Order";
import { 
	STATUS_AT_WORK_DIGITIZATION,
	STATUS_AWAITING_PAYMENT,
	STATUS_AT_WORK_MODEL, 
	STATUS_COMPLETED, 
	STATUS_COMPLETED_DIGITIZATION, 
	STATUS_COMPLETED_MODEL,
	STATUS_FUNDED, 
	STATUS_NEW, 
	STATUS_PREPAID 
} from "../../../utils/consts";
import { STATUS_CANCELED } from "../../../utils/consts/STATUS";
import { addManyObjToArr } from "../../../utils/reducerMethods";

const defaultState ={
	[STATUS_NEW.statusName]: {
		list:[],
		totalCount:0
	},
	[STATUS_PREPAID.statusName]: {
		list:[],
		totalCount:0
	},
	[STATUS_FUNDED.statusName]:{
		list:[],
		totalCount:0
	},
	[STATUS_AT_WORK_DIGITIZATION.statusName]: {
		list:[],
		totalCount:0
	},
	[STATUS_AWAITING_PAYMENT.statusName]: {
		list:[],
		totalCount:0
	},
	[STATUS_COMPLETED_DIGITIZATION.statusName]:{
		list:[],
		totalCount:0
	},
	[STATUS_AT_WORK_MODEL.statusName]:{
		list:[],
		totalCount:0
	},
	[STATUS_COMPLETED_MODEL.statusName]:{
		list:[],
		totalCount:0
	},
	[STATUS_COMPLETED.statusName]:{
		list:[],
		totalCount:0
	},
	[STATUS_CANCELED.statusName]:{
		list:[],
		totalCount:0
	}
}
const ADD_ORDERS = 'ADD_ORDERS';
const ADD_ORDER_ITEM = 'ADD_ORDER_ITEM';
const RM_ORDER_ITEM = 'RM_ORDER_ITEM';
const UPDATE_ORDER_ITEM='UPDATE_ORDER_ITEM';
const RM_ALL_ORDER = 'RM_ALL_ORDER';
const  CLEAR_ORDERS = 'CLEAR_ORDERS';

export const ASYNC_ADD_ORDERS = 'ASYNC_ADD_ORDERS';
export const ASYNC_ADD_ORDER_ITEM = 'ASYNC_ADD_ORDER_ITEM';
export const ASYNC_RM_ORDER_ITEM = 'ASYNC_RM_ORDER_ITEM';
export const ASYNC_UPDATE_ORDER_ITEM = 'ASYNC_UPDATE_ORDER_ITEM';

export const ordersReducer = (state = defaultState,  action) => {
	let orderType= '';
	if(action.payload) orderType=action.payload.orderType;// string
	switch(action.type) {
		case ADD_ORDERS:
			//console.log('addOrder',state[orderType])
			return {
				...state, 
				[orderType]: {
					list:[...state[orderType].list, ...action.payload.list],
					totalCount: action.payload.totalCount,
				}
			}
		case ADD_ORDER_ITEM: 
			return {
				...state, 
				[orderType]: {
					list:[...addManyObjToArr([...state[orderType].list], [action.payload.item])],
					totalCount: state[orderType].totalCount + 1,
				}
			}
		case RM_ORDER_ITEM: 
			return {
				...state, 
				[orderType]: {
					list: state[orderType].list.filter((c) => c.id !== action.payload.itemId),
					totalCount: state[orderType].totalCount - 1,
				}
			}
			//fix_update
		case UPDATE_ORDER_ITEM: 
		/* takes ({
			orderType:{

			}
			item:{
				id: num,
				props: {
					updatedProps
				}
			}
		})

		*/
			let indexOfItem = 0;
			if(state[orderType].list.length > 0){
				indexOfItem =state[orderType].list.map( item =>item.id).indexOf(action.payload.item.id);
			}
			let updList = [...state[orderType].list];
			//console.log()
			//const item = action.payload.item
			//console.log('indexOfItem', indexOfItem)
			//console.log('prevState', updList[indexOfItem])
			//console.log('item', item , 'list', updList)
			//const id = action.payload.item.id;
			updList[indexOfItem] = action.payload.item;
			//console.log('changedState', updList[indexOfItem])
			//console.log('item', item , 'list', updList)
			return {
				...state, 
				[orderType]: {
					...state[orderType],
					list:  [...updList]
				}
			}
		case RM_ALL_ORDER:
				return {
					...state,
					[orderType]: {
						list:[],
						totalCount:0,
					}
				}
		case CLEAR_ORDERS:
			return {
				...defaultState
			}
		default:
			return state
	}
}

export const addOrderAction = (payload) => ({type: ADD_ORDERS, payload});
export const addOrderItemAction = (payload) => ({type:ADD_ORDER_ITEM, payload});
export const rmOrderItemAction = (payload) => ({type:RM_ORDER_ITEM, payload});
export const updateOrderItemAction = (payload) => ({type:UPDATE_ORDER_ITEM, payload}); 
export const rmAllOrderAction = (payload) => ({type: RM_ALL_ORDER, payload}) 
export const clearOrdersAction = () => ({type: CLEAR_ORDERS}) 

export const asyncAddOrderAction = (payload) => ({type: ASYNC_ADD_ORDERS, payload});
export const asyncAddOrderItemAction = (payload) => ({type:ASYNC_ADD_ORDER_ITEM, payload});
export const asyncRmOrderItemAction = (payload) => ({type:ASYNC_RM_ORDER_ITEM, payload});
export const asyncUpdateOrderItemAction = (payload) => ({type:ASYNC_UPDATE_ORDER_ITEM, payload}); 