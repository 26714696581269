import { COST, SENDING_DATE, STATUS } from "../../../../../../utils/consts/OBJECT_FIELDS";
import useWindowDimensions from "../../../../../../hooks/useWindowDimensions";
import {
	STATUS_AT_WORK_DIGITIZATION, STATUS_AT_WORK_MODEL,
	STATUS_AWAITING_PAYMENT, STATUS_COMPLETED, STATUS_COMPLETED_DIGITIZATION, STATUS_COMPLETED_MODEL, STATUS_FUNDED,
	STATUS_NEW,
	STATUS_PREPAID
} from "../../../../../../utils/consts";
import {STATUS_CANCELED} from "../../../../../../utils/consts/STATUS";
const statusColor = {
	STATUS_NEW:'#FE8F8F',
	AWAITING_PAYMENT: '#deb329',
	PREPAID:'#fdff8a',
	AT_WORK_DIGITIZATION: 'rgba(179, 138, 255, 0.7882352941)',
	COMPLETED_DIGITIZATION:'rgba(160, 110, 252, 0.7960784314)',
	AT_WORK_MODEL: 'rgba(148, 92, 252, 0.8156862745)',
	COMPLETED_MODEL:'rgba(126, 58, 253, 0.8156862745)',
	FUNDED:'#a0f898',
	COMPLETED:'#14ff00',
	CANCELED:'#ff0000',
}

const OrderItemTableUser = ({order, children})=>{
	const {width} = useWindowDimensions();


	return(
			width > 1023 ? (
			<div className='orderItem--user'>
				<h4 className='content-list--table__title'>Заказ No: {order.id}</h4>
				<ul className = 'content-list--table'>
					<li className = 'content-list--table__item'>
						{order.getFieldText(COST)}
					</li>
					<li className = 'content-list--table__item'>
						{order.getFieldText(SENDING_DATE)}
					</li>
					<li className = 'content-list--table__item'>
						{order.address}
					</li>
				</ul>
				<div className='orderItem--user__btn-wrap'>
				<span className = 'content-list--table__item'>
						{order.getFieldText(STATUS)}
				</span>
					{children}
				</div>
			</div>
		) : (
			<div className='orderItem--user' style={{minHeight: 0}}>
				<div style={{display:"flex", justifyContent: "space-between", width: '100%', marginBottom: '20px', alignItems:'center'}}>
					<h4 className='content-list--table__title'>Заказ No: {order.id}</h4>
					<div className = 'content-list--table__item' style={{display: "flex", alignItems: 'center', border: '1px solid #e8e8e8', padding: '5px 10px', borderRadius: '5px'}}>
						<div style={{width: '10px', height: '10px', borderRadius: '100%', background: statusColor[order.status], marginRight: '7px'}}></div>
						{order.getStatusName()}
					</div>
				</div>
				<ul className = 'content-list--table'>
					<li className = 'content-list--table__item'>
						{order.getFieldText(SENDING_DATE)}
					</li>
					<li className = 'content-list--table__item'>
						{order.getFieldText(COST)}
					</li>
					<li className = 'content-list--table__item'>
						{order.address}
					</li>
				</ul>
			</div>
		)
	)
}
export default OrderItemTableUser;