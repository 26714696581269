export const calculateCost = ({square, floors, name}) => {
	let cost = {};
	switch(name){
		case SCAN_SHOOTING_3D.name:{
			cost = {...SCAN_SHOOTING_3D};
			break;
		}
		case CAD_BIM_3D.name:{
			cost = {...CAD_BIM_3D};
			break;
		}
		case CAD_2D.name:{
			cost = {...CAD_2D};
			break;
		}
		default:{
			return {};
		}
	}
	//console.log(cost)
	cost.fullPrice = cost.calculateCost({square, floors})
	cost.title = cost.getTitle(cost.fullPrice);
	cost.forSquareMeter = Math.ceil((cost.fullPrice / square));
	//console.log(cost)
	return cost;
}
export const SQUARE_50 = {
	value:50,
	name:'до 50м²',
	maxFloors:2
}
export const SQUARE_100 = {
	value:100,
	name:'до 100м²',
	maxFloors:3
}
export const SQUARE_150 = {
	value:150,
	name:'до 150м²',
	maxFloors:4
}
export const SQUARE_200 = {
	value:200,
	name:'до 200м²',
	maxFloors:4
};
export const SQUARE_250 = {
	value:250,
	name:'до 250м²',
	maxFloors:4
};
export const SQUARE_300 = {
	value:300,
	name:'до 300м²',
	maxFloors:4
};
export const SQUARE_350 = {
	value:350,
	name:'до 350м²',
	maxFloors:4
};
export const SQUARE_400 = {
	value:400,
	name:'до 400м²',
	maxFloors:5
};
export const SQUARE_450 = {
	value:450,
	name:'до 450м²',
	maxFloors:6
};
export const SQUARE_500 = {
	value:500,
	name:'до 500м²',
	maxFloors:6
};
export const ALL_SQUARE_OBJ =[
	SQUARE_50,
	SQUARE_100,
	SQUARE_150,
	SQUARE_200,
	SQUARE_250,
	SQUARE_300,
	SQUARE_350,
	SQUARE_400,
	SQUARE_450,
	SQUARE_500
]
export const SCAN_SHOOTING_3D = {
	name: 'Цифровой 3-D двойник',
	calculateCost: ({square, floors}) => {
		let cost = 0;
		switch(square){
			case SQUARE_50.value: {
				cost = 5000; // calculating for 1 floor
				break;
			}
			case SQUARE_100.value: {
				cost = 7000;
				break;
			}
			case SQUARE_150.value: {
				cost = 9000;
				break;
			}
			case SQUARE_200.value: {
				cost = 11000;
				break;
			}
			case SQUARE_250.value: {
				cost = 13500;
				break;
			}
			case SQUARE_300.value: {
				cost = 15900;
				break;
			}
			case SQUARE_350.value: {
				cost = 18200;
				break;
			}
			case SQUARE_400.value: {
				cost = 20400;
				break;
			}
			case SQUARE_450.value: {
				cost = 22500;
				break;
			}
			case SQUARE_500.value: {
				cost = 24500;
				break;
			}
			default:{
				break;
			}
		}
		//depend on floor count x times + 2000
		cost = cost + ((floors-1) * 2000);
		return cost;
	},
	fullPrice:0,
	forSquareMeter:0,
	getTitle: (cost) => <>  <span style={{fontSize: '25px'}}>
		 за {cost} р.</span> <br/> <span>в течении одного рабочего дня</span></>

	,
	descr:'Облако точек и сферические фотопанорамы.'
}
export const CAD_2D = {
	name: 'Обмерный 2-D план',
	calculateCost: ({square, floors}) => {
		let cost = 0;
		switch(square){
			case SQUARE_50.value: {
				cost = 7900; // calculating for 1 floor
				break;
			}
			case SQUARE_100.value: {
				cost = 10900;
				break;
			}
			case SQUARE_150.value: {
				cost = 13900;
				break;
			}
			case SQUARE_200.value: {
				cost = 17900;
				break;
			}
			case SQUARE_250.value: {
				cost = 22000;
				break;
			}
			case SQUARE_300.value: {
				cost = 26300;
				break;
			}
			case SQUARE_350.value: {
				cost = 30300;
				break;
			}
			case SQUARE_400.value: {
				cost = 34100;
				break;
			}
			case SQUARE_450.value: {
				cost = 37800;
				break;
			}
			case SQUARE_500.value: {
				cost = 41300;
				break;
			}
			default:{
				break;
			}
		}
		//depend on floor count x times + 2000
		cost = cost + ((floors-1) * 2500);
		//exceptions
		if(square === SQUARE_450.value && floors === 6){
			cost = 52800
		}
		if(square === SQUARE_500.value && floors === 6){
			cost = 56300
		}
		return cost;
	},
	fullPrice:0,
	forSquareMeter:0,
	getTitle: (cost) => <>  <span style={{fontSize: '25px'}}>
		 за {cost} р.</span> <br/> <span>в течении двух рабочих дней</span></>,
	descr:'2D план, где будут указаны все цепочки размеров, высоты проемов'


}
export const CAD_BIM_3D = {
	name: 'Обмерная 3-D модель',
	calculateCost: ({square, floors}) => {
		let cost = 0;
		switch(square){
			case SQUARE_50.value: {
				cost = 8900; // calculating for 1 floor
				break;
			}
			case SQUARE_100.value: {
				cost = 11900;
				break;
			}
			case SQUARE_150.value: {
				cost = 14900;
				break;
			}
			case SQUARE_200.value: {
				cost = 18900;
				break;
			}
			case SQUARE_250.value: {
				cost = 23300;
				break;
			}
			case SQUARE_300.value: {
				cost = 27700;
				break;
			}
			case SQUARE_350.value: {
				cost = 32000;
				break;
			}
			case SQUARE_400.value: {
				cost = 36200;
				break;
			}
			case SQUARE_450.value: {
				cost = 40200;
				break;
			}
			case SQUARE_500.value: {
				cost = 44200;
				break;
			}
			default:{
				break;
			}
		}
		//depend on floor count x times + 2000
		cost = cost + ((floors-1) * 3000);
		//exceptions
		if(square === SQUARE_450.value && (floors === 6)){
			cost = 58200
		}
		if(square === SQUARE_500.value && (floors === 6)){
			cost = 62200
		}
		return cost;
	},
	fullPrice:0,
	forSquareMeter:0,
	getTitle:(cost) => <>  <span style={{fontSize: '25px'}}>
		за {cost} р.</span> <br/> <span>в течении двух рабочих дней</span></>,
	descr:'3D модель, 2D план, облако точек и сферические фотопанорамы'
}
