import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../../store/reducers/actions";
import { MODELS } from "../../../../../utils/consts";
import DataTemplateItem from "../../dataTemplateItem/DataTemplateItem";
import { getFieldValue, getFieldValueById } from "../../../../../utils/checkers/getFieldValue";
import { createObjByModels } from "../../../../../utils/creators/createObjByModel";
import { getById } from "../../../../../utils/getById";
import FormBtn from "../../../formBtn/FormBtn";
const ListSearchable = (props) => {
	const {dataName, list, fieldId} = props
	const dispatch = useDispatch();
	const [isItemChosen, setIsItemChosen] = useState(false);
	const [chosenItem, setChosenItem] = useState({})
	useEffect(()=>{
		console.log(chosenItem)
	},[chosenItem])
	if(isItemChosen){
		return(
			<>
				<DataTemplateItem
					dataItem ={chosenItem} 
					dataName ={dataName} 
					key={chosenItem.id} 
					itemId ={chosenItem.id}
					canEdit={false}
				/>
				<FormBtn className ='modal__user-search-btn' handleClick={()=> {
					setIsItemChosen(false);
					setChosenItem({})
					dispatch(actions.rmFieldAction(fieldId))
				}}>
					Искать заново
				</FormBtn>
			</>
			
		)
	}
	const handleItemOnClick = (item) => {
		setChosenItem(item)
		setIsItemChosen(true);
		dispatch(actions.addFieldAction({id: fieldId, value:item}))
	}
	return (
		<ul className='list__searchable'>
			{
				list.map((dataItem) => {
					return(
						<DataTemplateItem 
							dataItem ={dataItem} 
							dataName ={dataName} 
							key={dataItem.id} 
							itemId ={dataItem.id}
							canEdit={false}
							onClick ={() => handleItemOnClick(dataItem)}
						/>
					)
				})
			}
		</ul>
	)
}
export default ListSearchable;