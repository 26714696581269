import FormTemplate from "../../../FormTemplate";
import { useEffect, useState } from "react";
import FormBtn from "../../formBtn/FormBtn";
//import classes from './ChangeDealInfoForm.module.css'
import { sendData } from "../../../../utils/sendData";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../store/reducers/actions";
import { rmDealItem, updateDealItem } from "../../../../http/dealAPI";
import { ADMIN_ROLE,  MANAGER_DIGITAL_ROLE,  MANAGER_MODELLING_ROLE,  MANAGER_ROLE, STATUS_AT_WORK_DIGITIZATION, STATUS_AT_WORK_MODEL, STATUS_COMPLETED_DIGITIZATION, STATUS_COMPLETED_MODEL, STATUS_ID_FIELD, STATUS_ID_NAME, STATUS_ID_SERVER_NAME, STATUS_PREPAID, SUCCESS_CODE } from "../../../../utils/consts";
import { getStatusObject } from "../../../../utils/getStatusId";
import tempAlert from "../../../../utils/tempAlert";
import { orderChangeCheck } from "../../../../utils/checkers/orderChangeCheck";
import Form, { BLOCK_TYPE, DEAL_INFO_FORM } from "../../../../utils/classes/Form";
import {RAW_FIELDS_TYPE, FORM_FIELDS, FORM_BLOCKS } from "../../../../utils/consts/FORM_FIELDS";
import updateFieldInObjArr from "../../../../utils/objectUtils/updateFieldInObjArr";
import Order from "../../../../utils/classes/Order";
import { ADDRESS, CITY, COMMENTS, SEND_DEAL_INFO, SEND_LINK, SQUARE, STATUS } from "../../../../utils/consts/OBJECT_FIELDS";
import BtnContainer from "../../BtnContainer/BtnContainer";
import { BTN_REMOVE, BTN_RETURN, BTN_SAVE } from "../../../../utils/consts/BTNS";
import Loader from "../../loader/Loader";
import FormComponent from "../../../FormComponent";
import getFields from "../../../../utils/formUtils/getFields";
import ModalWindow from "../../modalWindow/ModalWindow";
import sleep from "../../../../utils/sleep";
const DealInfoForm = (props) =>{
	const {setIsOnEdit, orderItem, setModalVisible} = props;
	const fieldValue = useSelector(state => state.fieldValueReducer.fields)
	const user = useSelector(state => state.userReducer.user)
	const dispatch = useDispatch();
	const [notificationVisible, setNotificationVisible] = useState(false);
	const [notificationType, setNotificationType] = useState('send_info');
	const [responseCame, setResponseCame] = useState(true);
	const [isReady, setIsReady] = useState(false)//ready for closing
	const form = useSelector(state => state.formReducer);

	const sendOrderData = async () => {
		if(fieldValue[SQUARE] < 50){
			tempAlert('Минимальная площадь 50м²',3000,['--invalid'])
			return
		}
		//if sendDealInfo is false -> push new field(modalWindow)
		if(user.role === ADMIN_ROLE || user.role === MANAGER_ROLE){
			if(fieldValue[SEND_LINK] || fieldValue[SEND_DEAL_INFO]){
				if(!notificationVisible){
					setNotificationVisible(true);
				}
				if(fieldValue[SEND_LINK] && fieldValue[SEND_DEAL_INFO]){
					setNotificationType('send_info_full');
				}else if(fieldValue[SEND_LINK]){
					setNotificationType('send_link')
				} else {
					setNotificationType('send_info_success')
				}
				//await sleep(5000);

			} else if(!fieldValue[SEND_LINK] && !fieldValue[SEND_DEAL_INFO]){
				if(!notificationVisible){
					//не выбран ни один чекбокс -> показывем
					setNotificationVisible(true);
					setNotificationType('send_info');
					return;
				}
			}
		}
		// if(notificationVisible && fieldValue[SEND_LINK] && notificationType!=='send_link'){
		// 	if(user.role === ADMIN_ROLE || user.role === MANAGER_ROLE){
		// 		setNotificationVisible(true);
		// 		setNotificationType('send_link')
		// 		await sleep(3000);
		// 	}
		// }
		// if(!notificationVisible){
		// 	if(user.role === ADMIN_ROLE || user.role === MANAGER_ROLE){
		// 		if(!fieldValue[SEND_DEAL_INFO]){
		// 			setNotificationVisible(true);
		// 			setNotificationType('send_info');
		// 			return;
		// 		} else if(fieldValue[SEND_LINK]){
		// 			setNotificationVisible(true);
		// 			setNotificationType('send_link')
		// 			await sleep(5000);
		// 		}
		// 	}
		// }
		let response = null;
		const updatedStatusObj = getStatusObject({description:fieldValue[STATUS]})
		const fieldsArr = getFields({FORM_TYPE:BLOCK_TYPE, FORM_FIELDS:form.blocks});
		try{
			// check if change is possible
			if(!orderChangeCheck({role:user.role, order:{...fieldValue}})){
				return;
			}
			// start loader on btn after all checks
			setResponseCame(false);
			response = await sendData(updateDealItem,{
				formFields:[...fieldsArr],
				fieldValue: {
					...fieldValue,
					id:orderItem.id,
					status: updatedStatusObj.statusName,
					comments:[...orderItem.getValue(COMMENTS)]
				}
			})
		} catch(e){
			setResponseCame(true);
			console.log(e)
			tempAlert(e, 3000,['--invalid']);
		}
		if(!response){
			setResponseCame(true);
			return;
		}
		if(response.success === SUCCESS_CODE){
			//console.log(response)
			//if type of order is new -> deleting from prevState -> adding to the newState
			//setIsOnEdit(false);
			if(notificationType === 'send_info' && notificationVisible){
				setIsOnEdit(false)
			}
			const prevOrder = {...orderItem.convertedServer};
			const newOrder = new Order({
				...response?.data
			})
			// console.log('prev', prevOrder)
			// console.log('new', newOrder)
			if( newOrder.status === prevOrder.status){
				dispatch(actions.updateOrderItemAction({
					orderType: newOrder.status,
					item: newOrder
				}))
			} else {
				dispatch(actions.rmOrderItemAction(
					{
						orderType: prevOrder.status,
						itemId: orderItem.id
					}
				))
				dispatch(actions.addOrderItemAction(
					{
						orderType:newOrder.status,
						item:  {
							content:newOrder,
							pos:0
						}
					}
				))
			}
			tempAlert('сделка изменена успешно', 3000,['--valid']);
		} else{
			tempAlert('ошибка изменения', 3000, ['--invalid']);
		}
		// stop loader
		setIsReady(true)
		setResponseCame(true);
	}
	const rmOrderData = async () => {
		let response = null;
		try{
			response = await rmDealItem(
				{
					id: orderItem.id,
				}
			)
		} catch(e){
			tempAlert(e, 3000,['--invalid']);
		}
		if(response.success === SUCCESS_CODE){
			//console.log(response)
			const prevStatusObj = getStatusObject({statusName:orderItem.status})
			dispatch(actions.rmOrderItemAction(
				{
					orderType: prevStatusObj.statusName,
					itemId: orderItem.id
				}
			))
			tempAlert('сделка удалена успешно', 3000,['--valid']);
			setIsOnEdit(false)
		}else{
			tempAlert('ошибка удаления сделки', 3000,['--invalid']);
		}
	}
	const getFormBtns = () => {
		const btns = [
			{
				...BTN_SAVE,
				props:{
					...BTN_SAVE.props,
					handleClick:sendOrderData
				}
			},
			{
				...BTN_RETURN,
				props:{
					...BTN_RETURN.props,
					handleClick:() => {
						setIsOnEdit(false);
					}
				}
			}
		]
		if(user.role === ADMIN_ROLE || user.role ===MANAGER_ROLE){
			btns.push({
				...BTN_REMOVE,
				props:{
					...BTN_REMOVE.props,
					handleClick:rmOrderData
				}
			})
		}
		return btns;
	}
	// useEffect(()=>{
	// 	// updating fields before putting into form
	// 	const fieldsArr = Object.entries(orderItem.convertedForm);
	// 	const updArray = []
	// 	//formTemplate.updateManyFields({fieldIds, updateValues:{currValue:orderItem.covertedForm[]}})
	// 	for(let i=0; i<fieldsArr.length; i++){
	// 		let fieldId = fieldsArr[i][0];
	// 		let currValue = fieldsArr[i][1];
	// 		if(FORM_FIELDS[fieldId]){
	// 			updArray.push(
	// 				{
	// 					...FORM_FIELDS[fieldId],
	// 					currValue
	// 				}
	// 			)
	// 		}
	// 	}
	// 	dispatch(actions.updFormFieldsAction({
	// 		fields:updArray
	// 	}))
	// },[])
	const getText = (type) => {
		switch(type){
			case 'send_info':
				return 'Вы не будете отправлять информацию о заказе пользователю'
			case 'send_info_success':
				return 'Данные о сделке изменены и отправлены клиенту!'
			case 'send_info_full':
				return 'Данные в сделке изменены, ссылка на оплату и данные о сделке отправлены клиенту'
			case 'send_link':
				return 'Ссылка на оплату изменена и отправлена клиенту!'
			default:
				return ''
		}
	}
	useEffect(()=>{
		dispatch(actions.updFormFieldsAction({
			blockId:ADDRESS,
			fields:[
				{
					...FORM_FIELDS[ADDRESS],
						queryParams:{
						query:'',
						[CITY]:fieldValue[CITY]
					},
				}
			]
		}))
	},[fieldValue[CITY]])
	useEffect(()=>{
		// console.log('response',responseCame)
		// console.log('response',notificationVisible)
		if(!notificationVisible && isReady && notificationType !== 'send_info'){
			setIsOnEdit(false);
		}
	},[notificationVisible])
	useEffect(()=>{
		if(user.role === MANAGER_MODELLING_ROLE || user.role === MANAGER_DIGITAL_ROLE){

		}
	},[])
	// const editDealInfoModel = () => {
	// 	console.log(user.role)
	// 	if (user.role === MANAGER_DIGITAL_ROLE || user.role === MANAGER_MODELLING_ROLE){
	// 		console.log(orderItem._statusName)
	// 		DEAL_INFO_FORM[BLOCK_TYPE].fields[0].currValue = STATUS_PREPAID.description
	// 		DEAL_INFO_FORM[BLOCK_TYPE].fields[0].defaultValue = STATUS_PREPAID.description
	// 	}
	// 	return DEAL_INFO_FORM
	// }
	return (
		<>
			{
				notificationVisible
				?(
					<ModalWindow
						visible={notificationVisible}
						setVisible={setNotificationVisible}
						modification={'read-only'}
						option={'modal__notification'}
						modalClasses={['modal--small']}
					>
						<h3 style={{textAlign:'center', fontWeight:500}}>
							{
								getText(notificationType)
							}
						</h3>
						{
							notificationType === 'send_info'
							?(
								<FormBtn handleClick={sendOrderData}>
								Продолжить
								</FormBtn>
							)
							:''
						}
					</ModalWindow>
				)
				:<></>
			}
			<FormComponent
				formClassNames={['form__editOrder']}
				formType={DEAL_INFO_FORM}
				existingValues={Object.entries(orderItem.convertedForm)}
			>
			{
				responseCame
				?(
					<BtnContainer btns={getFormBtns()}/>
				)
				:<span className='btn project_ref--disabled'>
					<div style={{display: 'flex', justifyContent: 'center',height:'100%', width:'100%'}}>
						<Loader width={20} height={20}/>
					</div>
				</span>
			}
			</FormComponent>
		</>
	)
}
export default DealInfoForm;
