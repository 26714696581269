import classes from './UserQA.module.css'

import { useState } from 'react';
import ListCollapse from '../../List/ListCollapse/ListCollapse';
import { LIST_FAQ } from '../../../../utils/consts/LISTS';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const UserQA = () => {
	return(
		<ListCollapse 
			defaultList={LIST_FAQ} 
			option='user-faq' 
			expandedByDefault={[...LIST_FAQ.map(item => item.id)]}
			multiple={true}
		/>
	)
}
export default UserQA;