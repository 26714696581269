import { USER_ROLE } from "../consts";
import { BIK, BONUS_POINTS, BRAND, EMAIL, FIRSTNAME, ID, INN, KPP, LASTNAME, LEGAL_PERSON, PHONE, RS, TOTAL_PAID, userNames } from "../consts/OBJECT_FIELDS";
import {getOrders} from "../getMock";

export default class User {
	constructor(props) {
		const {
			id,
			role ,
			orders=[],
			firstname,
			lastname,
			email,
			phone,
			brand,
			inn,
			bik,
			kpp,
			rs,
			totalPaid,
			bonusPoints,
			currentOrder,
			tg,
			whatsapp,
			legalPerson
		} = props
		this._id = id;
		this._role = role;
		this._tg=tg;
		this._whatsapp=whatsapp;
		this._orders = orders;
		this._firstname = firstname;
		this._lastname = lastname;
		this._email = email;
		this._phone= phone;
		this._brand = brand;
		this._inn = inn;
		this._bik = bik;
		//this._kpp = kpp;
		this._rs = rs;
		this._totalPaid = totalPaid;
		this._bonusPoints = bonusPoints;
		this._currentOrder = currentOrder;
		this._legalPerson = legalPerson;
	}
	get id(){
		return this._id;
	}
	get role(){
		return this._role
	}
	get orders(){
		return this._orders
	}
	get convertedServer(){
		return{
			[ID]: this._id,
			role: this._role,
			[FIRSTNAME]:this._firstname,
			[LASTNAME]:this._lastname,
			[EMAIL]:this._email,
			[PHONE]:this._phone,
			[BRAND]:this._brand,
			[LEGAL_PERSON]:this._legalPerson,
			[INN]:this._inn,
			[BIK]:this._bik,
			//[KPP]:this._kpp,
			[RS]:this._rs,
			[TOTAL_PAID]:this._totalPaid,
			[BONUS_POINTS]:this._bonusPoints,
			orders: this._orders
		}
	}
	//returns content fields of a User
	get content(){
		if(this.role !== USER_ROLE){
			return{
				[FIRSTNAME]:this._firstname,
				[LASTNAME]:this._lastname,
				[EMAIL]:this._email,
				[PHONE]:this._phone,
			}
		}
		return{
			[FIRSTNAME]:this._firstname,
			[LASTNAME]:this._lastname,
			[EMAIL]:this._email,
			[PHONE]:this._phone,
			[BRAND]:this._brand,
			[LEGAL_PERSON]:this._legalPerson,
			[INN]:this._inn,
			[BIK]:this._bik,
			//[KPP]:this._kpp,
			[RS]:this._rs,
			[TOTAL_PAID]:this._totalPaid,
			[BONUS_POINTS]:this._bonusPoints,
		}
	}
	setAnyField(valId, val){
		this[`_${valId}`] = val
	}
	getFieldText(fieldId){
		if(fieldId === PHONE){
			return (<><span className='item__span'>{userNames[fieldId]}: </span><a className='project_ref' href={`tel:${this._phone}`}>{this._phone}</a></>);
		}
		if(!this[`_${fieldId}`]){
			return (<><span className='item__span'>{userNames[fieldId]}: </span> неизвестно</>);
		}
		return (<><span className='item__span'>{userNames[fieldId]}: </span>{this[`_${fieldId}`]}</>);
	}
	getValue(fieldId){
		return this[`_${fieldId}`];
	}
}
