import { ADDRESS, COST, USER_NAME, ID, REPRESENTATIVE_NAME, REPRESENTATIVE_PHONE, STATUS, USER_INFO } from "../../../../../../utils/consts/OBJECT_FIELDS";

const OrderItemTableManager = ({order, classes=[]}) =>{
	//console.log(order.getFieldText(STATUS))
	return(
			<ul className = {`content-list--table ${classes.join(' ')}`}>
				<li className = 'content-list--table__item'>
					{order.getFieldText(ID)}
				</li>
				<li className = 'content-list--table__item'>
					{order.getFieldText(STATUS)}
				</li>
				<li className = 'content-list--table__item'>
					{order.getFieldText(USER_INFO)}
				</li>
				<li className = 'content-list--table__item'>
					{order.getFieldText(REPRESENTATIVE_NAME)}
				</li>
				<li className = 'content-list--table__item'>
					{order.getFieldText(ADDRESS)}
				</li>
				<li className = 'content-list--table__item'>
					{order.getFieldText(REPRESENTATIVE_PHONE)}
				</li>
				<li className = 'content-list--table__item'>
					{order.getFieldText(COST)}					
				</li>
			</ul>
	)
}
export default OrderItemTableManager;