import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetching } from '../../../hooks/useFetching';
import { fetchDeals } from '../../../http/dealAPI';
import actions from '../../../store/reducers/actions';
import { sortOrders } from '../../../utils/dispatchUtils/sortOrders';
import { formatOrders } from '../../../utils/formatOrders';
import { generateDates } from '../../../utils/Time/time';
import Loader from '../loader/Loader';
import classes from './Calendar.module.css'
import CalendarHeader from './CalendarHeader/CalendarHeader';
import CalendarItem from './CalendarItem/CalendarItem'
import { getAllOrders } from '../../../utils/ordersUtils/getOrdersList';
import { getSortedList } from '../../../utils/getSortedList';
import { SENDING_DATE, SEND_LINK } from '../../../utils/consts/OBJECT_FIELDS';
const Calendar = () => {
	const dispatch = useDispatch();
	const user = useSelector(state => state.userReducer.user)
	const [date, setDate] = useState(new Date())
	const [days, setDays] = useState(generateDates(date));
	const [activeElement, setActiveElement] = useState('');
	const isActiveCheck= (id) => {
		if(id === activeElement){
			return true
		}
		return false;
	} 
	const [fetchOrders, isLoading, error] = useFetching(async () => {
		//remove 'page'
		const response = await fetchDeals(
			{
				page:1,
				limit: 3,
				sort_by: 'month', 
				filter:date.toISOString().substring(0, 7),
				userId:user.id,
			});
		const orders = formatOrders(response);
		const sortedOrders = sortOrders(orders, user.role);
		for(let i = 0; i<sortedOrders.length; i++){
			dispatch(actions.addOrderAction({...sortedOrders[i]}))
		}
	})
	useEffect(()=>{
		fetchOrders();
		return () => {
			dispatch(actions.clearOrdersAction())
		}
	},[date])
	if(isLoading){
		return <div style={{position:'fixed', display: 'flex', justifyContent: 'center',height:'100%', width:'100%'}}><Loader/></div> 
	}
	return(
		<div className={classes['calendar__container']}>
			<CalendarHeader date={date} setDate={setDate} setDays={setDays}/>
			{
				//width > 1023
				<div className={classes['calendar__dayNames-wrap']}>
					<ul className={classes['calendar__dayNames']}>
						<li>Понедельник</li>
						<li>Вторник</li>
						<li>Среда</li>
						<li>Четверг</li>
						<li>Пятница</li>
						<li>Суббота</li>
						<li>Воскресенье</li>
					</ul>
				</div>

			}
			<div className={classes['calendar__items-container']}>
				{
					days.map((item) => <CalendarItem 
						isActive={isActiveCheck(item.getTime())} 
						id={item.getTime()} 
						key={item.getTime()} 
						item={item}
						//dayItems = {getItemByDay(item)} 
						setActiveElement={setActiveElement} 
						
					/>)
				}
			</div>
		</div>
	)
}
export default Calendar