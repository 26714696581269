import React, {useState} from 'react';
import imgOne from './img/img1.png'
import imgTwo from  './img/img2.png'
import imgThree from  './img/img3.png'
import imgFour from  './img/img4.png'
import classes from "./style.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const InstructionCreateOrder = () => {
    return (
        <div className={classes['instruction__main']}>
            <div className={classes['instruction__main__raw']}>
                <div>
                    <p className={classes['instruction__main__tittle']}>Создайте заказ</p>
                    <img src={imgOne} alt="Фото 1"/>
                    <p className={classes['instruction__main__footer']}>Выберите один из тарифов,
                        и укажите базовую информацию
                        о Вашем объекте.
                    </p>
                </div>
                <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" size={'2x'} className={classes['fa-arrow-right-long']} />
            </div>
            <div className={classes['instruction__main__raw']}>
                <div>
                    <p  className={classes['instruction__main__tittle']}>Сканирование</p>
                    <img src={imgTwo} alt="Фото 1"/>
                    <p className={classes['instruction__main__footer']}>Сканирование происходит в
                        удобное для Вас время.
                        В списке заказов Вы сможете
                        отслеживать всю информацию
                        и текущий статус заявки.
                    </p>
                </div>

                <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" size={'2x'} className={classes['fa-arrow-right-long']}/>
            </div>


            <div className={classes['instruction__main__raw']}>
                <div>
                    <p  className={classes['instruction__main__tittle']}>Предпросмотр</p>
                    <img src={imgThree} alt="Фото 1"/>
                    <p className={classes['instruction__main__footer']}>Просмотр готовой 3-D модели
                        будет доступен по ссылке сразу
                        после окончания работ.
                    </p>
                </div>
                <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" size={'2x'} className={classes['fa-arrow-right-long']}/>

            </div>


            <div className={classes['instruction__main__raw']} style={{width:'30%'}}>
                <div>
                    <p  className={classes['instruction__main__tittle']}>Получите результат!</p>
                    <img src={imgFour} alt="Фото 1"/>
                    <p className={classes['instruction__main__footer']}>Вся информация в необходимом
                        электронном формате будет
                        доступна для скачивания прямо
                        из Личного кабинета!
                    </p>
                </div>
            </div>
        </div>
    )
}

export default InstructionCreateOrder;
