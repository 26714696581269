import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormBtn from '../../../formBtn/FormBtn';
import OrderItem from '../../../OrderItem/OrderItem'
import classes from '../ManagerOrders.module.css'
const ManagerOrdersList = ({list, statusObj}) => {
		const {width} = useSelector(state => state.windowReducer);
		const [isActive, setIsActive] = useState(true); 
		const handleClick = () => {
			if(isActive){
				setIsActive(false)
			} else{
				setIsActive(true)
			}
		}
		useEffect(()=>{
			if(width < 1023){
				setIsActive(false)
			} else {
				setIsActive(true)
			}
		},[width])
		return(
			<div className={classes["managerOrders__list-container"] + ' ' +classes[`managerOrders__list-container--${statusObj.statusName}`] }>
				<div className={classes['managerOrders__list-title-container']}>
					<h3 className={classes['managerOrders__list-title']} onClick={handleClick}>
						{statusObj.description}
					</h3>
					{
						width < 1023
						?(<FormBtn 
						spec={['btn--arrow']} 
						className='btn--collapse-list' 
						handleClick={handleClick}/>
						)
						:<></>
					}
				</div>	
				{isActive &&
				<div className={classes[`managerOrders__list--${statusObj.statusName}`] + ' ' + classes['managerOrders__list']}>
					{
						list.map(listItem => {
							return (
								<OrderItem
									key ={listItem.id} 
									order={listItem} 
									className={classes['orderItem']}
								/>
							)
						})
					}
				</div>
				}
			</div>
		)
}
export default ManagerOrdersList