const defaultState ={
	deal:{},
}
export const SET_DEAL = 'SET_DEAL';
export const CLEAN_DEAL = 'CLEAN_DEAL';
export const dealReducer = (state = defaultState,  action) => {
	switch(action.type) {
		case SET_DEAL : 
			return {...state, deal: {...state.deal, ...action.payload}}
		case CLEAN_DEAL:
			return {...state, deal: {}}
		default:
			return state
	}
}
export const cleanDealAction = () => ({type:CLEAN_DEAL})
export const setDealAction = (payload) => ({type:SET_DEAL, payload})