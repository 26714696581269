import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useFetching } from "../../../../hooks/useFetching";
import { fetchDeals } from "../../../../http/dealAPI";
import actions from "../../../../store/reducers/actions";
import { STATUS_CANCELED, STATUS_COMPLETED } from "../../../../utils/consts/STATUS";
import { formatOrders } from "../../../../utils/formatOrders";
import { getOrdersList, getSortedOrdersList } from "../../../../utils/ordersUtils/getOrdersList";
import Loader from "../../loader/Loader";
import OrderItem from "../../OrderItem/OrderItem";
//import classes from './ManagerOrdersCanceled.module.css'
import classes from '../ManagerOrders/ManagerOrders.module.css'
const ManagerOrdersCanceled = () => {
	//const orders = useSelector(state => state.dataReducer['orders'])
	const dispatch = useDispatch();
	const user = useSelector(state => state.userReducer.user)
	const role =user.role
	const ordersDone = useSelector(state => state.ordersReducer[STATUS_CANCELED.statusName]).list
	const dispatchOrders = (orders) => {
		const sortedOrders = getSortedOrdersList(orders, role);
		dispatch(actions.addOrderAction({
			orderType: STATUS_CANCELED.statusName,//orderType
			list: [...sortedOrders[STATUS_CANCELED.statusName]],
			totalCount: sortedOrders[STATUS_CANCELED.statusName].length
		}))
	}
	const [fetchOrders, isOrdersLoading, error] = useFetching(async () => {
		const response = await fetchDeals({});
		const orders = [...formatOrders(response)];
		dispatchOrders(orders)
	})
	useEffect(()=>{
		fetchOrders()
		return ()=> {
			dispatch(actions.clearOrdersAction())
		}
	},[])
	return(
		<div className={classes[`managerOrders__list--${STATUS_CANCELED.statusName}`] + ' ' + classes['managerOrders__list']}>
			{
				isOrdersLoading
				?<div style={{position:'fixed', display: 'flex', justifyContent: 'center',height:'100%', width:'100%'}}><Loader/></div> 
				:(
					ordersDone.length > 0
					?ordersDone.map(order => (
						<OrderItem 
							key ={order.id} 
							order={order} 
							className={classes['orderItem']}

						/>
						)
						)
					:(<h2>сделки не найдены</h2>)
				)
			}
		</div>
	)
}
export default ManagerOrdersCanceled