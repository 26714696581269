import { useSelector } from "react-redux"
import CreateDealForm from "../../components/UI/Forms/CreateDealForm/CreateDealForm"

const CreateDealPage = () => {
	const {role} = useSelector(state => state.userReducer.user)
	const formType = 'blocks'
	return(
		<CreateDealForm formType={formType}/>
	)
}
export default CreateDealPage