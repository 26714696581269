import { useState } from "react";
import DataItemPage from "../../../pages/DataItemPage"
import FormBtn from "../formBtn/FormBtn";
import OrderControlPanel from "../Modals/OrderControlPanel/OrderControlPanel";
import ModalWindow from "../modalWindow/ModalWindow"
import OrderItemList from "./OrderItemList/OrderItemList";
/**
 * orderItem:
 * 	1. OrderControlPanel
 *  2. OrderVisual
 *
 */
const OrderItem = (props) => {
	const {order, className} = props
	let {listType=''} = props
	const [orderId, setOrderId] = useState(0);
	const [modalVisible, setModalVisible] = useState(false);
	if(!listType){
		listType = 'orderItem--table';
	}
	const onOrderItemClick = (orderId) => {
		setModalVisible(true);
		setOrderId(orderId)
	}
	return(
		<>
			{
				<OrderControlPanel  
					modalVisible={modalVisible} 
					setModalVisible={setModalVisible} 
					order={order} 
				/>
			}
			<div className={className} onClick={()=> onOrderItemClick(order.id)}>
				<OrderItemList order={order} listType={listType}>
					{
						listType === 'orderItem--table--user'
						?(
							<FormBtn handleClick={()=> onOrderItemClick(order.id)}>
								подробнее
							</FormBtn>
						)
						:''
					}
				</OrderItemList>
			</div>
		</>
	)
}
export default OrderItem