import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "../Header/Header";
import ManagerClientList from "./ManagerClientList/ManagerClientList";
import ManagersList from "./ManagerManagerList/ManagersList";
import { getRouteByPath, getRouteVar } from "../../../utils/getRouteVar";
import ManagerOrdersDone from "./ManagerOrdersDone/ManagerOrdersDone";
import ManagerOrders from "./ManagerOrders/ManagerOrders";
import { ADMIN_ROLE, CALENDAR_ROUTE, CLIENTS_ROUTE, DEALS_DONE_ROUTE, DEALS_ROUTE, DEAL_ROUTE, MANAGER_ROLE, REGISTER_CLIENT_ROUTE, REGISTER_MANAGER_ROUTE, USER_ROUTE } from "../../../utils/consts";
import { pathNumToId } from "../../../utils/pathUtils/pathNum.js";
import RegisterForm from "../Forms/RegisterForm";
import { BTNS_MANAGER, BTNS_MANAGER_MOBILE } from "../../../utils/consts/BTNS";
import Calendar from "../Calendar/Calendar";
import { DEALS_CANCELED_ROUTE, MANAGERS_ROUTE } from "../../../utils/consts/ROUTES";
import ManagerOrdersCanceled from "./ManagerOrdersCanceled/ManagerOrdersCanceled";
import UserProfile from "../User/UserProfile/UserProfile";
const Manager = () => {
	const user = useSelector(state => state.userReducer.user)
	const {width} = useSelector(state => state.windowReducer);
	let {pathname} = useLocation();
	const [title, setTitle] = useState('Главная страница');
	const params = useParams();
	const currentPath = pathNumToId(params.id, pathname);
	//let routePath = pathname.split('/');
	//const route = routePath[routePath.length - 1];
	const getComponent = () => {
		switch (currentPath){
			case USER_ROUTE.path:
				return <UserProfile modification={'managers'}/>
			case CLIENTS_ROUTE.path:
				return <ManagerClientList/>
			case MANAGERS_ROUTE.path:
				return <ManagersList/>
			case CALENDAR_ROUTE.path:
					return <Calendar/>
			case DEALS_DONE_ROUTE.path:
				return <ManagerOrdersDone/>
			case DEALS_CANCELED_ROUTE.path:
				 return <ManagerOrdersCanceled/>
			case DEALS_ROUTE.path:
				return <ManagerOrders/>
			case DEAL_ROUTE.path:
				return <h1>deal {params.id}</h1>
			case REGISTER_CLIENT_ROUTE.path:
				return <RegisterForm formPath={REGISTER_CLIENT_ROUTE.path}/>
			/*
			case 'Manager':
				return //<ManagerProfile/>
			case 'registerClient':
				return <RegisterClient/>
			case 'registerAdmin':
				return <RegisterAdmin/>
			case 'registerManager':
				return <RegisterManager/>
			*/
			default:
				return;
		}
	}
	//const {height, width} = useWindowDimensions();
	const getHeaderBtns = () => {
		//mobile
		if(
			user.role !== MANAGER_ROLE 
			&& 
			user.role !== ADMIN_ROLE
		){
			return []
		}
		if(width < 1023){
			return BTNS_MANAGER_MOBILE
		}
		return BTNS_MANAGER
	}
	useEffect(()=>{
		setTitle(getRouteByPath(currentPath).name)
	},[pathname])
	
	return(
		<div className={/*classes['manager__container'] +*/ 'container manager__wrap'}>
			<Header 
				title={title}
				buttons={getHeaderBtns()}
				role={user.role}
				personalName={user.getValue('firstname') + ' ' + user.getValue('lastname')}
			/>
			<div className='manager__container container'>
				{
					getComponent()
				}
			</div>
		</div>
	)
}
export default Manager;