import sleep from "../../../utils/sleep"
import { hasItemById } from "../../../utils/getById";
import { addManyObjToArr, rmManyObjById, updManyObjById } from "../../../utils/reducerMethods";
/**
 * can dynamically add/remove/update form blocks -> where is located formFields
 * can add/remove/update formFields inside blocks
 */
const defaultState ={
	title:{
		name:'',
		info:''
	},
	type:'', // block
	//modification:'',
	blocks: [],
}
/**
 * block:{
 * 	id:'',
 * 	blockTitle,
 * 	classNames:[],
 * 	fields:[]
 * }
 *
 * 1. addBlock
 * 2. updateBlock
 * 3. rmBlock
 * 4. setForm
 * 5. updateForm
 * 6. refreshForm
 */
export const SET_FORM = 'SET_FORM';
export const REFRESH_FORM='REFRESH_FORM';

export const ADD_BLOCKS ='ADD_BLOCKS';
export const UPDATE_BLOCKS ='UPDATE_BLOCKS';
export const RM_BLOCKS ='RM_BLOCKS';

export const ADD_FORM_FIELDS ='ADD_FORM_FIELDS';
export const UPDATE_FORM_FIELDS ='UPDATE_FORM_FIELDS';
export const RM_FORM_FIELDS = 'RM_FORM_FIELDS';
// update fields inside current block
// returns updState
const findArrayElminObj = ({objects=[], arrProp='', elmId}) => {
	//triverse all objects arrays to find parent obj for elmId
	for( let i=0; i < objects?.length; i++){
		let elm = objects[i][arrProp].filter(item => item?.id === elmId);
		if(elm?.length !== 0){
			return {elm, parentId:objects[i]?.id}
		}
	}
	return {parentId:''};
}
const updFields = (prevState, payload) => {
	/**
	 * prevState - blocks
	 * payload {
	 * blockId
	 * fields
	 * }
	*/
	const updState = [...prevState];
	const fields = [...payload.fields]
	// find block by id
	for(let i=0; i<updState?.length; i++){
		// "updManyObjById" already has Parent check
		updState[i].fields = [...updManyObjById(updState[i].fields, fields)];
	}
	// updatingState
	return [...updManyObjById(prevState, updState)]
}
const addFields = (prevState, payload) => {
	/**
	 * prevState - blocks
	 * payload {
	 * blockId
	 * fields:[{pos,content}]
	 * }
	*/
	let updBlock = {};
	const fields = [...payload?.fields];
	// find block by id
	for(let i=0; i< fields?.length; i++){
		for(let j=0; j<prevState?.length; j++){
			if(prevState[j]?.id === payload?.blockId){
				updBlock = {...prevState[j]};
			}
		}
	}
	//updatingBlock
	if(updBlock?.fields){
		updBlock.fields = [...addManyObjToArr(updBlock.fields, fields)]
	}
	// updatingState (for 1 block)
	return [...updManyObjById(prevState, [updBlock])]
}
const rmFields = (prevState, payload) => {
	/**
	 * prevState - blocks
	 * payload {
	 * blockId
	 * fieldsId
	 * }
	*/
	let updBlock = {};
	//const blocks = [...prevState];
	const fields = [...payload.fields]
	// find block by id
	for(let i=0; i<prevState?.length; i++){
		if(prevState[i]?.id === payload.blockId){
			updBlock = {...prevState[i]};
		}
	}
	//updatingBlock
	if(updBlock?.fields){
		updBlock.fields = [...rmManyObjById(updBlock.fields, fields)]
	}
	// updatingState (for 1 block)
	return [...updManyObjById(prevState, [updBlock])]
}
export const formReducer = (state = defaultState,  action) => {
	switch(action.type) {
		//payload = form
		case SET_FORM:{
			return{
				title:{...action.payload.title},
				blocks:[...action.payload.blocks]
			}
		}
		case REFRESH_FORM:{
			return{
				...defaultState
			}
		}
		case ADD_BLOCKS:{

			/**
			 * payload:{
				* blocks:[
					* {
					* 	pos:0,
					* 	content:{//block}
					*}
				* ]
			 * }
			*/
			return {
				...state,
				blocks: [...addManyObjToArr(state.blocks, action.payload)]
			}
		}
		case UPDATE_BLOCKS:{
			return {
				...state,
				blocks: [...updManyObjById(state.blocks, action.payload)]
			}
		}
		case RM_BLOCKS:{
			return {
				...state,
				blocks: [...rmManyObjById(state.blocks, action.payload)]
			}
		}
		case ADD_FORM_FIELDS :{
			return {
				...state,
				blocks: [...addFields(state.blocks, action.payload)]
			}
		}
		case UPDATE_FORM_FIELDS:{
			return {
				...state,
				blocks: [...updFields(state.blocks, action.payload)]
			}
		}
		case RM_FORM_FIELDS:{
			return {
				...state,
				blocks: [...rmFields(state.blocks, action.payload)]
			}
		}
		default:
			return state
	}
}
export const setFormAction = (payload) => ({type:SET_FORM, payload})
export const refreshFormAction = () => ({type:REFRESH_FORM})
export const addFormBlocksAction = (payload) => ({type:ADD_BLOCKS, payload})
export const updFormBlocksAction = (payload) => ({type:UPDATE_BLOCKS, payload})
export const rmFormBlocksAction = (payload) => ({type:RM_BLOCKS, payload})

export const addFormFieldsAction = (payload) => ({type:ADD_FORM_FIELDS, payload})
export const updFormFieldsAction = (payload) => ({type:UPDATE_FORM_FIELDS, payload})
export const rmFormFieldsAction = (payload) => ({type:RM_FORM_FIELDS, payload})

