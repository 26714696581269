const STATUS_ID_SERVER_NAME = [
	'STATUS_NEW',
	'AWAITING_PAYMENT',
	'PREPAID',
	'AT_WORK_DIGITIZATION',
	'COMPLETED_DIGITIZATION',
	'AT_WORK_MODEL',
	'COMPLETED_MODEL',
	'FUNDED',
	'COMPLETED',
	'CANCELED'
]
const STATUS_NEW = {
	id:0,
	statusName: 'STATUS_NEW',
	description: 'Новая'
};

const STATUS_AWAITING_PAYMENT = {
	id:1,
	statusName: 'AWAITING_PAYMENT',
	description: 'Ожидает оплаты'
};
const STATUS_PREPAID = {
	id:2,
	statusName: 'PREPAID',
	description:'Внесен аванс'
};
const STATUS_AT_WORK_DIGITIZATION = {
	id:3,
	statusName:'AT_WORK_DIGITIZATION',
	description:'В работе (Оцифровка)'
};
const STATUS_COMPLETED_DIGITIZATION = {
	id:4,
	statusName:'COMPLETED_DIGITIZATION',
	description:'Оцифровка выполнена'
};
const STATUS_AT_WORK_MODEL= {
	id:5,
	statusName:'AT_WORK_MODEL',
	description:'В работе (Моделирование)'
};
const STATUS_COMPLETED_MODEL= {
	id:6,
	statusName:'COMPLETED_MODEL',
	description: 'Выполнено моделирование'
};
const STATUS_FUNDED = {
	id:7,
	statusName:'FUNDED',
	description:'Оплачена'
};
const STATUS_COMPLETED= {
	id:8,
	statusName:'COMPLETED',
	description: 'Выполнена'
};
const STATUS_CANCELED = {
	id:9,
	statusName:'CANCELED',
	description: 'Отменена'
};
const STATUS_PAIRS = [
	STATUS_NEW,
	STATUS_AWAITING_PAYMENT,
	STATUS_PREPAID,
	STATUS_AT_WORK_DIGITIZATION,
	STATUS_COMPLETED_DIGITIZATION,
	STATUS_AT_WORK_MODEL,
	STATUS_COMPLETED_MODEL,
	STATUS_FUNDED,
	STATUS_COMPLETED,
	STATUS_CANCELED
]

const getStatusNames = () => {
	return STATUS_PAIRS.map(pair => pair.description)
}
const STATUS_ID_NAME = [...getStatusNames()]


export {
	STATUS_PAIRS,
	STATUS_ID_SERVER_NAME,
	STATUS_NEW,
	STATUS_AWAITING_PAYMENT,
	STATUS_PREPAID,
	STATUS_FUNDED,
	STATUS_AT_WORK_DIGITIZATION,
	STATUS_COMPLETED_DIGITIZATION,
	STATUS_AT_WORK_MODEL,
	STATUS_COMPLETED_MODEL,
	STATUS_COMPLETED,
	STATUS_CANCELED,
	STATUS_ID_NAME
}
